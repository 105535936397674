import moment from "moment";
import React from "react";

function ListView({ processes = [] }) {
	return (
		<>
			<div class="table-responsive datatable-custom position-relative">
				<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
					<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
						<thead>
							<tr role="row">
								<th style={{width: "30%"}}>Ejecutó</th>
								<th style={{width: "15%"}}>Fecha</th>
								<th style={{width: "15%"}}>Hora</th>
								<th style={{width: "20%"}}>Periodo</th>
								<th style={{width: "20%"}}>Estado</th>
							</tr>
						</thead>
						<tbody>
							{
								processes && processes.map((process) =>
									<tr>
										<td>
											{
												process.executor ? process.executor.firstname + " " + process.executor.lastname : "Automático"
											}
										</td>
										<td>
											{
												moment(process.createdAt).format("DD/MM/YYYY")
											}
										</td>
										<td>
											{
												moment(process.createdAt).format("HH:mm:ss")
											}
										</td>
										<td>
											{
												process.process_payroll_periods && process.process_payroll_periods.length > 0 ? process.process_payroll_periods[0].payroll_period.description : "Diario"
											}
										</td>
										<td>
											<div class="progress">
												<div class="progress-bar bg-success" role="progressbar" style={{width: process.progress + "%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
													{
														process.progress + "%"
													}
												</div>
											</div>
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}

export default ListView;