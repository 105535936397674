import React from "react";
import Modal from "helpers/Modal";
import Filters from "./Filters";
import moment from "moment";
import ReactToPrint from "react-to-print";
import NoImage from 'resources/images/no-avatar.png';
import AttendanceDetails from "./AttendanceDetails";

function AttendanceView({
  user = {},
  isDetailsVisible,
  setIsDetailsVisible,
  detailsData,
  setDetailsData,
  permissionExcel,
  permissionPdf,
  permissionRead,
  setLoading,
  onChangeSearch,
  handleKeyUp,
  onDownloadCSV,
  onDownloadXlsx,
  onDownloadXlsxColumns,
  reportRef,
  filters,
  setFilters,
  hideModal,
  showModal,
  filterModal,
  attendanceLogs,
  officeDetails,
  statusDetails,
  parameters = {},
  changeStatus,
  refreshAttendanceLogs
}) {
  return (
    <>
      <div className="content container-fluid p-5">

        <div className="page-header mb-3">
          <div className="row align-items-end">
            <div className="col-sm mb-2 mb-sm-0">
              <h1 className="page-header-title">Reporte de registros</h1>
            </div>
            <div className="col-sm-auto">

              {
                permissionPdf && (
                  <ReactToPrint
                    debug={true}
                    content={() => reportRef.current}
                    trigger={() => (
                      <button className="btn bg-danger text-white mx-1">
                        <i className="bi bi-file-earmark-pdf" style={{ marginRight: "7px" }}></i>
                        Descargar PDF
                      </button>
                    )}
                  />
                )
              }

              {
                permissionExcel && (
                  <>
                    <button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
                      <i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
                      Descargar Excel
                    </button>
                    <button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsxColumns}>
                      <i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
                      Descargar Excel Columnas
                    </button>
                    <button type="button" className="btn bg-primary text-white mx-1" onClick={onDownloadCSV}>
                      <i className="bi bi-filetype-csv" style={{ marginRight: "7px" }}></i>
                      Descargar CSV
                    </button>
                  </>
                )
              }

              {
                permissionRead && (
                  <button type="button" className="btn btn-primary" onClick={showModal}>
                    <i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
                    Filtros de búsqueda
                  </button>
                )
              }

            </div>
          </div>
        </div>

        <div class="card mb-3">
          <div class="card-header card-header-content-md-between">
            <div class="mb-2 mb-md-0">
              <div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
                <div class="input-group-prepend input-group-text" >
                  <i class="bi-search"></i>
                </div>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Buscar empleado"
                  onChange={onChangeSearch}
                  onKeyUp={handleKeyUp}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 attendance-report" ref={reportRef}>

            <div className="report-header">

              <div className="d-flex justify-content-center">
                <div className="logo mt-6">
                  {
                    user?.company?.logo?.url ? (
                      <img crossorigin="anonymous" height="300" src={user.company.logo.url} alt="Logo empresa" style={{ borderRadius: "50%" }} />
                    ) : (
                      <img src={NoImage} alt="" height="300" style={{ borderRadius: "50%" }} />
                    )
                  }
                </div>
              </div>

              <div className="text-center">
                <h1 className="mb-3">{user?.company?.name}</h1>
              </div>

              <div className="text-center">
                <h2 className="mb-3">Reporte de marcajes</h2>
              </div>

              <div className="text-center">
                <h4 className="">
                  {
                    !filters.start_date && !filters.end_date
                      ? "Todos los registros"
                      : filters.start_date + " - " + filters.end_date
                  }
                </h4>
              </div>

            </div>

            <div className="pagebreak"></div>

            {
              attendanceLogs && attendanceLogs.map((report) => (
                report.attendanceLogs.length > 0 ? (
                  <>
                    <div class="table-responsive datatable-custom position-relative report-content">
                      <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3">

                        <table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                          <thead>
                            <tr role="row">
                              <th style={{ width: "5%" }}>ID</th>
                              <th style={{ width: "35%" }}>Nombre</th>
                              <th style={{ width: "20%" }}>Sucursal</th>
                              <th style={{ width: "20%" }}>Departamento</th>
                              <th style={{ width: "20%" }}>Puesto</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{report.employee.key}</td>
                              <td>{report.employee.firstname} {report.employee.dad_lastname} {report.employee.mom_lastname}</td>
                              <td>{report.employee.office.abbreviation}</td>
                              <td>{report.employee.department.abbreviation}</td>
                              <td>{report.employee.job.abbreviation}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="mx-3">
                          <table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                            <thead>
                              <tr role="row" className="bg-dark">
                                <th>Fecha</th>
                                <th>Descripción</th>
                                {parameters.show_location && <th>Ubicación</th>}
                                <th>Hora</th>
                                {parameters.show_temperature && <th>Ubicación</th>}
                                <th>Estatus</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                report.attendanceLogs.map((attendanceLog) => (
                                  <tr>
                                    <td>{attendanceLog.log.date}</td>
                                    <td>
                                      {
                                        attendanceLog.log.type === "LECTOR"
                                          ? attendanceLog.device.description
                                          : "Geolocalización"
                                      }
                                    </td>
                                    {parameters.show_location &&
                                      <td>
                                        {
                                          attendanceLog.log.type === "LECTOR"
                                            ? attendanceLog.device.location
                                            : attendanceLog.log.location ?? (parseFloat(attendanceLog.log.lat).toFixed(3) + ", " + parseFloat(attendanceLog.log.lng).toFixed(3))
                                        }
                                      </td>
                                    }
                                    <td>{attendanceLog.log.time}</td>
                                    {parameters.show_temperature && <td></td>}
                                    <td>
                                      {function () {
                                        if (attendanceLog.log.status === "CREATED") return "Sin procesar"
                                        else if (attendanceLog.log.status === "PROCESSED") return "Procesado"
                                        else if (attendanceLog.log.status === "DUPLICATED") return "Duplicado"
                                        else if (attendanceLog.log.status === "OUT_OF_RANGE") return "Fuera de rango"
                                      }()}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className={`btn btn-sm ${attendanceLog.log.active ? "btn-outline-danger" : "btn-outline-success"}`}
                                        onClick={() => changeStatus(attendanceLog.log, !attendanceLog.log.active)}
                                      >
                                        {
                                          attendanceLog.log.active ? "Desactivar" : "Activar"
                                        }
                                      </button>
                                      {
                                        attendanceLog.log.type === "LECTOR"
                                          ? ""
                                          : <button
                                            className="btn btn-sm btn-outline-secondary text-dark"
                                            onClick={() => {
                                              setDetailsData({ employee: report.employee, log: attendanceLog.log })
                                              setIsDetailsVisible(true)
                                            }}
                                          >
                                            Ver <i className="bi bi-geo-alt text-dark" ></i>
                                          </button>
                                      }
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>

                    {/* <div className="pagebreak"></div> */}

                    <hr />
                  </>
                ) : null
              ))
            }
          </div>
          <div className="col-md-4">
            <div class="card mb-3 mb-md-5">
              <div class="card-header">
                <h5 class="card-header-title">Detalles de la búsqueda</h5>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col-md-6 d-flex align-items-center">
                    <span className="mx-2 fw-bold">Fecha de búsqueda: </span>
                  </div>
                  <div class="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.start_date && filters.start_date !== "" ? moment(filters.start_date).format("DD/MM/YYYY") : " - "}</span>
                  </div>
                  <div class="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.end_date && filters.end_date !== "" ? moment(filters.end_date).format("DD/MM/YYYY") : " - "}</span>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mx-2 fw-bold">Rango de horas: </span>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.start_hour && filters.start_hour !== "" ? filters.start_hour : " - "}</span>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{filters.end_hour && filters.end_hour !== "" ? filters.end_hour : " - "}</span>
                  </div>
                </div>

                <hr />

                {
                  Object.keys(officeDetails).length > 0
                    ? <>
                      <div>
                        <span className="mx-2 fw-bold">Sucursales: </span>
                      </div>
                      {
                        Object.keys(officeDetails).map((office_id) =>
                          <div className="row" key={office_id}>
                            <div className="col-md-6 d-flex align-items-center">
                              <span className="mx-2">{officeDetails[office_id].name}</span>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                              <span className="mx-2">{officeDetails[office_id].count}</span>
                            </div>
                          </div>
                        )
                      }
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-center">
                          <span className="mx-2 fw-bold">Total: </span>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                          <span className="mx-2">
                            {
                              Object.keys(officeDetails).reduce((total, office_id) => total + officeDetails[office_id].count, 0)
                            }
                          </span>
                        </div>
                      </div>

                      <hr />

                    </>
                    : null
                }

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mx-2 fw-bold">Estatus de registros: </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mx-2 ml-3 mr-2">Sin procesar: </span>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{statusDetails.CREATED}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mx-2 ml-3">Procesados: </span>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{statusDetails.PROCESSED}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mx-2 ml-3">Duplicados: </span>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{statusDetails.DUPLICATED}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mx-2 ml-3">Fuera de rango: </span>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <span className="mx-2">{statusDetails.OUT_OF_RANGE}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal
        title={"Filtros de búsqueda"}
        isVisible={filterModal}
        setIsVisible={hideModal}
      >
        <Filters
          hideModal={hideModal}
          filters={filters}
          setFilters={setFilters}
          setLoading={setLoading}
        />
      </Modal>

      <Modal
        title={"Detalles de asistencia"}
        isVisible={isDetailsVisible}
        setIsVisible={setIsDetailsVisible}
      >
        <AttendanceDetails
          data={detailsData}
          setIsVisible={setIsDetailsVisible}
          refreshAttendanceLogs={refreshAttendanceLogs}
        />
      </Modal>

    </>
  );
}

export default AttendanceView;