import React, { useState, useEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/Alerts.view"
import { getJSON } from "utils/sessionStorage";

function Alerts({ employees, filters, refreshValue, globalLoading, setGlobalLoading }) {

	const [loading, setLoading] = useState(false)
	const [incidencesCount, setIncidencesCount] = useState(getJSON("incidencesCount", []))

	const [callDashboard, reqDashboard] = useGetCall("/statistics/incidences_count", {
		onCompleted: (response) => {
			setIncidencesCount(response?.data?.count??[])
			setLoading(false)
			setGlobalLoading({ ...globalLoading, alerts: true })
		},
		onError: (error) => {
			setLoading(false);
			setGlobalLoading({ ...globalLoading, alerts: true })
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	useEffect(() => {
		try{ 
			sessionStorage.setItem("incidencesCount", JSON.stringify(incidencesCount)) 
			sessionStorage.setItem("incidenceCountEmployees", JSON.stringify(employees))
		}
		catch(e) { console.log(e) }
	}, [incidencesCount, employees])

    useEffect(() => {
			if(!globalLoading.attendanceChart) return;
			if(globalLoading.alerts) return;
			setLoading(true)
			callDashboard({ ...filters })
    }, [refreshValue, globalLoading])

	return (
		<View
			loading={loading}
			employees={employees}
			incidencesCount={incidencesCount}
		/>
	)

}

export default Alerts