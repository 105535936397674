import { API_URL } from "settings";
import { getUrlencoded } from "utils/api";

function getSubarea(id) {

    const url = `${API_URL}/subareas/${id}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });

}

function getSubareas(query = {}) {

    const url = `${API_URL}/subareas?${getUrlencoded(query)}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/x-www-form-urlencoded",
            "x-auth-token": sessionStorage.getItem("token"),
        }
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
        
}

export { getSubarea, getSubareas };