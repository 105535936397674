import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from "react"
import { toast } from "react-toastify"
import Loading from "helpers/Loading"
import { useGetCall, fetchPost, usePost } from "utils/api"
import { showResponseMessage } from "utils/message"
import { LoadingContext } from "helpers/LoadingContext"
import View from "components/crh/employeeDevices/List.view"

const EmployeeDevicesList = forwardRef((props, ref) => {

	const {
		countRef,
		theme,
		search,
		addEmployeeDeviceModal,
		reloadEmployees,
		setReloadEmployees,
	} = props

	const { loading, setLoading } = useContext(LoadingContext);
	const [employees, setEmployees] = useState([])
	const [passwordEdit, setPasswordEdit] = useState({})

	if(countRef != null && countRef.current != null)
		countRef.current.innerHTML = employees.length

	const [callEmployees, reqEmployees] = useGetCall("/employees", {
		onCompleted: (response) => {
			setLoading(false);

			fetchPost("/device_employees/check_permission", { table: "EMPLOYEE_DEVICE", type: "read" }).then((res) => {
				if (res.data) {
					if (res.data.isAllowed === true) {
						setEmployees(response.data)
					} else {
						if (!toast.isActive("toast-list-employees-devices-unauthorized")) {
							toast.error("Error, no tienes los permisos para visualizar los empleados a lectores", { toastId: "toast-list-employees-devices-unauthorized" })
						}
					}
				}
			})

		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "employees", "empleados", "read")
		}
	});

	const [callUpdatePassword, reqUpdatePassword] = usePost("/employees/update_password", {
		onCompleted: (response) => {
			setLoading(false);
			setPasswordEdit({})
			if(!toast.isActive("toast-list-employees-devices-update-password"))
				toast.success("Contraseña actualizada", { toastId: "toast-list-employees-devices-update-password" })
		},
		onError: (error) => {
			
			setLoading(false);

			if(error.status === 400) {
				if(!toast.isActive("toast-list-employees-devices-update-password-error"))
					toast.error("Error, la contraseña debe ser de 6 dígitos", { toastId: "toast-list-employees-devices-update-password-error" })
			} 
			else if(error.status === 404) {
				if(!toast.isActive("toast-list-employees-devices-update-password-error"))
					toast.error("Error, empleado no encontrado", { toastId: "toast-list-employees-devices-update-password-error" })
			}
			else {
				if(!toast.isActive("toast-list-employees-devices-update-password-error"))
					toast.error("Ha ocurrido un error", { toastId: "toast-list-employees-devices-update-password-error" })
			}

		}
	});

	const [callChangePerm, reqChangePerm] = usePost("/employees/change_perms", {
		onCompleted: (response) => {
			setLoading(false);
			callEmployees({ search });
			if(!toast.isActive("toast-list-employees-devices-perm"))
				toast.success("Permisos actualizados", { toastId: "toast-list-employees-devices-perm" })
		},
		onError: (error) => {
			
			setLoading(false);

			if(error.status === 400) {
				if(!toast.isActive("toast-list-employees-devices-perm-error"))
					toast.error("Error, no ha sido posible actualizar los permisos", { toastId: "toast-list-employees-devices-perm-error" })
			} 
			else if(error.status === 404) {
				if(!toast.isActive("toast-list-employees-devices-perm-error"))
					toast.error("Error, empleado no encontrado", { toastId: "toast-list-employees-devices-perm-error" })
			}
			else {
				if(!toast.isActive("toast-list-employees-devices-perm-error"))
					toast.error("Ha ocurrido un error", { toastId: "toast-list-employees-devices-perm-error" })
			}

		}
	});

	const generateCells = async () => {

		const csv = [[
			"ID",
			"Nombre",
			"Sucursal",
			"Huellas",
			"Rostros",
			"FaceAI",
			"Tarjetas"
		]];

		if (employees != null) {

			employees.forEach((employee) => {

				const row = [];

				row.push(employee.key);
				row.push(employee.firstname + " " + (employee.dad_lastname ?? "") + " " + (employee.mom_lastname ?? ""));

				if (employee.office) row.push(employee.office.key);
				else row.push("");

				row.push(employee.fingerprint_count);
				row.push(employee.face_count);
				row.push(employee.faceai_count);
				row.push(employee.card_count);

				csv.push(row);

			});

		}

		return csv;

	}

	useImperativeHandle(ref, () => ({
		async generateCells() {
			return await generateCells()
		}
	}))

	useEffect(() => {
		setLoading(true);
		callEmployees({ search });
		setReloadEmployees(false)
	}, [reloadEmployees, setReloadEmployees, search])

	const onEdit = (id) => 
		addEmployeeDeviceModal(id);

	const onEditPassword = () => 
		callUpdatePassword({ employee_id: passwordEdit.id, password: passwordEdit.password });

	const onChangePerm = (employee, perm_level) => 
		callChangePerm({ employee_id: employee.id, perm_level });

	if (loading) return <Loading />

	return (
		<View
			employees={employees}
			passwordEdit={passwordEdit}
			setPasswordEdit={setPasswordEdit}
			onEdit={onEdit}
			onEditPassword={onEditPassword}
			onChangePerm={onChangePerm}
			theme={theme}
		/>
	)

})

export default EmployeeDevicesList