import React from "react"
import moment from "moment/moment"

function DevicesListView({ devices = [], onDelete, onEdit, onStatusChange, onViewBiophotos }) {
	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Estado</th>
							<th>Conexión</th>
							<th>Número de serie</th>
							<th>Sucursal</th>
							<th>Descripción</th>
							<th>Modelo</th>
							<th>Última conexión</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							devices.map((device) => (
								<tr key={device.id} role="row">
									<td>
										{device.status === "ACTIVE" ? <span className="badge bg-success">Activo</span> : <span className="badge bg-danger">Inactivo</span>}
									</td>
									<td>
										{device.connected ? <span className="badge bg-info">Conectado</span> : <span className="badge bg-warning">Desconectado</span>}
									</td>
									<td>
										{device.serial_number}
									</td>
									<td>
										{device.office ? device.office.key : ""}
									</td>
									<td>
										{device.description}
									</td>
									<td>
										{device.model}
									</td>
									<td>
                    					{device.last_connection ? moment(device.last_connection).format("DD/MM/YYYY HH:mm") : ""}
									</td>
									<td>
										<button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => onViewBiophotos(device.id)}>
											<i class="bi bi-eye" ></i>
										</button>
										<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(device.id)}>
											<i class="bi bi-pencil" ></i>
										</button>
										<button className="btn btn-danger" onClick={() => onDelete(device.id)}>
											<i class="bi bi-trash" ></i>
										</button>
										<button 
											className={device.status === "ACTIVE" ? "btn btn-info" : "btn btn-success"}
											style={{ marginLeft: "10px" }} 
											onClick={() => onStatusChange(device, device.status === "ACTIVE" ? "INACTIVE" : "ACTIVE")}
										>
											<i class={device.status === "ACTIVE" ? "bi bi-broadcast" : "bi bi-broadcast-pin"} ></i>
										</button>
									</td>

								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default DevicesListView