import React from 'react';
import { toast } from 'react-toastify';
import { usePost } from 'utils/api';
import View from "components/auth/Recover.view";

function Recover({ location }) {

  const superAdmin = location.pathname.includes("admin");

	const [callRecover, reqRecover] = usePost("/auth/recover", {
		onCompleted: ({ data }) => {

			if(!toast.isActive("recover-success"))
				toast.success("Se ha enviado un correo con las instrucciones para recuperar la contraseña.", { toastId: "recover-success" });

		},
		onError: (error) => {
			console.error(error);
			if(!toast.isActive("recover-error"))
				toast.error("Usuario no encontrado.", { toastId: "recover-error" });
		},
		includeAuth: false
	});

	const onSubmit = (values) => {

		const user = values.user;
    const client = values.client;

		if(!user || (!client && !superAdmin)) {
			toast.error("Todos los campos son obligatorios.");
			return;
		}

		callRecover({
			username: user,
      client: client,
      type: superAdmin ? "SUPERADMIN" : "CLIENT"
		})

	}

	return <View onSubmit={onSubmit} superAdmin={superAdmin} />
}

export default Recover;