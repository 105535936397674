import React from "react";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import FileField from 'helpers/FileField';
import dayjs from 'dayjs';
import { Typeahead } from "react-bootstrap-typeahead";
const format = 'HH:mm';


const showHours = (values, incidencesWithHour, incidences) => {

	if (values.incidence) {
		const incidence = incidences.find((item) => item.id === parseInt(values.incidence.id));
		if (incidencesWithHour.find(item => item === incidence.type))
			return true;
		else
			return false;
	} else {
		return false;
	}
}

const showInsertLog = (values, incidences) => {

	if (values.incidence) {
		const incidence = incidences.find((item) => item.id === parseInt(values.incidence.id));
		if(!incidence) return false;
		return incidence.type === "INSERT_MARKINGS";
	} else {
		return false;
	}

}


function IncidenceRequestsView({
	formikRef,
	incidences,
	onSubmit,
	incidencesWithHour,
	setStartHour,
	setEndHour,
	endHour,
	startHour,
}) {
	return (
        <div className="content container-fluid py-4">
            <div className="page-header mt-5 m-0 p-0 w-100">
                <div className="pt-5">
                    <Formik
                    initialValues={{}}
                    innerRef={formikRef}
                    onSubmit={onSubmit}>
                    {({ values, setFieldValue, resetForm }) =>
                        <Form>

                            {/* Incidence */}
                            <div class="mb-3">
                                <div class="form-group">
                                    <label class="input">
                                        <Field as="select" name="incidence.id" class="form-control input__field" placeholder=" " required value={values.incidence ? values.incidence.id : ''}>
                                            <option value="">Selecciona una incidencia</option>
                                            {
                                                incidences.map((incidence) =>
                                                    <option key={incidence.id} value={incidence.id}>{incidence.description}</option>
                                                )}
                                        </Field>
                                        <span class="input__label">
                                            Incidencia <span className='text-danger fw-bold'>*</span>
                                        </span>
                                    </label>
                                </div>
                            </div>

                            {/* Date range */}
                            {
                                !showInsertLog(values, incidences) ? (
                                    <div class="mb-3">
                                        <div class="d-flex w-100">
                                            <h5 style={{ padding: "5px 0" }}>Rango de fechas<span className='text-danger fw-bold'>*</span></h5>
                                        </div>
                                        <div className="d-flex">
                                            <div class="px-2 w-100">
                                                <Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
                                            </div>
                                            <div class="px-2 w-100">
                                                <Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {/* Hour range */}
                            {
                                showHours(values, incidencesWithHour, incidences) ? (
                                    <div class="mb-3">
                                        <div className="d-flex w-100">
                                            <h5 style={{ padding: "5px 0" }}>Rango de horas<span className='text-danger fw-bold'>*</span></h5>
                                        </div>
                                        <div class="d-flex">
                                            <div class="px-2 w-100">
                                                <TimePicker style={{ width: "100%" }} format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
                                            </div>
                                            <div class="px-2 w-100">
                                                <TimePicker style={{ width: "100%" }} format={format} onChange={time => setEndHour(time.format(format))} value={endHour ? dayjs(endHour, format) : ''} />
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                showInsertLog(values, incidences) ? (
                                    <>
                                        <div class="mb-3">
                                            <div className="d-flex w-100">
                                                <h5 style={{ padding: "5px 0" }}>Fecha<span className='text-danger fw-bold'>*</span></h5>
                                            </div>
                                            <div className="d-flex w-100 mb-3">
                                                <Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
                                            </div>
                                            <div className="d-flex w-100">
                                                <h5 style={{ padding: "5px 0" }}>Hora<span className='text-danger fw-bold'>*</span></h5>
                                            </div>
                                            <div className="d-flex w-100 mb-3">
                                                <TimePicker style={{ width: "100%" }} format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
                                            </div>
                                        </div>
                                    </>
                                ) : null
                            }

                            {/* File */}
                            <div class="mb-3">
                                <div class="form-group">
                                    <label class="input">
                                        <FileField className="form-control" name="file" setFieldValue={setFieldValue} />
                                        <span class="input__label" style={{ backgroundColor: "#e9ecef" }}>
                                            Archivo incidencia
                                        </span>
                                    </label>

                                </div>
                            </div>

                            {/* Observations */}
                            <div class="mb-3">
                                <div class="form-group">
                                    <label class="input">
                                        <Field type="text" name="observations" class="form-control input__field" placeholder=" " value={values.observations || ''} />
                                        <span class="input__label">
                                            Observaciones
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div class="pt-3">
                                <button type="submit" class="btn btn-primary">
                                    <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                    Guardar
                                </button>
                                <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={resetForm}>
                                    <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                    Cancelar
                                </button>
                            </div>

                        </Form>
                    }</Formik>
                </div>
            </div>
        </div>
	);
}

export default IncidenceRequestsView;