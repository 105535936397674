import React from "react";
import Modal from "helpers/Modal";
import IncidencesRequestsList from "components/crh/incidencesAuth/List";

function IncidencesAuthView({
	filters,
	isVisible,
	setIsVisible,
	modalTitle,
	modalContent,
	addModal,
	showEditModal,
	isVisibleEdit,
	setIsVisibleEdit,
	modalTitleEdit,
	modalContentEdit,
}) {

	return (
		<>
			<div className="content container-fluid p-5">
				<IncidencesRequestsList
					filters={filters}
					refresh={Math.random()}
					addModal={addModal}
					showEditModal={showEditModal}
				/>
			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

		</>
	);
}

export default IncidencesAuthView;