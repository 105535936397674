import React, { useState, useEffect, useRef } from "react";
import { usePut, useGetCall } from "utils/api";
import { getArea } from "api/areas";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/areas/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [area, setArea] = useState({});
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callArea, reqArea] = useGetCall(`/areas/${id}`, {
        onCompleted: (response) => {
            setArea(response.data)
            if (formikRef.current) {
                formikRef.current.setValues(response.data);
            }
        }, onError: (error) => {
            showResponseMessage(error.status, "areas", "areas", "read")
        }
    })

    const [callUpdate, reqUpdate] = usePut("/areas", {
        onCompleted: () => {
            if (!toast.isActive("area-updated"))
                toast.success("Area actualizada", { toastId: "area-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (error) => {
            console.error(error)
            showResponseMessage(error.status, "areas", "areas", "edit");
        }
    })

    useEffect(() => {

        callArea();
        formikRef.current.setValues({});

    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }
        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            area={area}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
