import React from 'react';
import { weekDays } from 'utils/constants';
 
const filterPlanning = (planning, filters) => {

  let filteredPlanning = planning;

  if(filters.search)
    filteredPlanning = filteredPlanning.filter(employeePlanning => 
      employeePlanning.employee.firstname.toLowerCase().includes(filters.search.toLowerCase()) ||
      employeePlanning.employee.dad_lastname.toLowerCase().includes(filters.search.toLowerCase()) ||
      employeePlanning.employee.mom_lastname.toLowerCase().includes(filters.search.toLowerCase()) ||
      employeePlanning.employee.key.toLowerCase().includes(filters.search.toLowerCase())
    );

  return filteredPlanning;

}

function FormView({ 
  planning = {},
  filters = {},
  setFilters = () => {},
  onEdit = () => {},
  onClose = () => {}
}) {
  return (
    <div className='container mt-3'>

      <div className='d-flex'>
        
        <input 
          type="text" 
          className="form-control" 
          placeholder="Buscar empleado" 
          onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        />

      </div>

      <div class="table-responsive datatable-custom position-relative" >
        <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
          <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
            <thead>
              <tr role="row">
                <th>
                  ID
                </th>
                <th style={{ width: "22%" }}>
                  Nombre
                </th>
                <th style={{ width: "10%" }}>
                  Lunes
                </th>
                <th style={{ width: "10%" }}>
                  Martes
                </th>
                <th style={{ width: "10%" }}>
                  Miércoles
                </th>
                <th style={{ width: "10%" }}>
                  Jueves
                </th>
                <th style={{ width: "10%" }}>
                  Viernes
                </th>
                <th style={{ width: "10%" }}>
                  Sábado
                </th>
                <th style={{ width: "10%" }}>
                  Domingo
                </th>
              </tr>
            </thead>
            <tbody>
              {
                filterPlanning(planning?.personal_planning??[], filters).map((employeePlanning, index) => (
                  <tr key={index} role="row">
                    <td>
                      {employeePlanning.employee.key}
                    </td>
                    <td>
                      {employeePlanning.employee.firstname} {employeePlanning.employee.dad_lastname} {employeePlanning.employee.mom_lastname}
                    </td>
                    {
                      weekDays.map(weekDay => 
                        <>
                          {
                            function(){

                              const dayPlanning = employeePlanning.day_planning.find(dayPlanning => dayPlanning.week_day === weekDay);
                              let paint = [];

                              if(dayPlanning?.schedule)
                                paint.push(
                                  <div>
                                    {dayPlanning.schedule.description}
                                  </div>
                                );
                              else
                                paint.push(
                                  <div>
                                    -
                                  </div>
                                );

                              if(dayPlanning?.incidence_request)
                                paint.push(
                                  <div>
                                    SI-{dayPlanning.incidence_request.folio}
                                  </div>
                                );

                              return (
                                <td>
                                  {paint}
                                </td>
                              )
                              
                            }()
                          }
                        </>
                      )
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <div className='d-flex'>
        <button 
          type='button' 
          className='btn btn-primary mt-3'
          onClick={onEdit}
        >
          <i className='bi bi-plus'/> Editar
        </button>
        <button 
          type='button' 
          className='btn btn-danger mt-3 ms-2'
          onClick={onClose}
        >
          <i className='bi bi-x'/> Cerrar
        </button>
      </div>

    </div>
  )
}

export default FormView;