import React, {useState, useEffect, useContext} from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/payrollPeriods/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {
	
	const { loading, setLoading } = useContext(LoadingContext);

	const [payrollPeriods, setPayrollPeriods] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callPayrollPeriods, reqPayrollPeriods] = useGetCall("/payroll_periods", {
		onCompleted: (response) => {
			setLoading(false);
			setPayrollPeriods(response.data)
		},
		onError: (error) => {
			setLoading(false);
			if (error.status === 403) {
				if (!toast.isActive("toast-read-payroll_periods-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar periodos de nómina", { toastId: "toast-read-payroll_periods-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-payroll_periods-error")) {
					toast.error("Error al obtener los puestos", { toastId: "toast-payroll_periods-error" })
				}
			}
		}
	})
	
	const [callDelete, reqDelete] = useDelete("/payroll_periods", {
		onCompleted: () => {
			if (!toast.isActive("payrollPeriod-deleted"))
				toast.success("Periodo eliminada", { toastId: "payrollPeriod-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			if (error.status === 403) {
				if (!toast.isActive("toast-delete-payroll_periods-unauthorized")) {
					toast.error("Error, no tienes los permisos para eliminar periodos de nómina", { toastId: "toast-delete-payroll_periods-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-payroll_periods-error")) {
					toast.error("Error al eliminar el periodo de nómina", { toastId: "toast-payroll_periods-error" })
				}
			}
		}
	});

	useEffect(() => {
		setLoading(true);
		callPayrollPeriods({ search });
	}, [refreshValue, refreshDelete, search]);

	const onDelete = (id) => {
		fetchPost("/payroll_periods/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el periodo?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-payroll_period-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar periodos de nómina", { toastId: "toast-delete-payroll_period-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View payrollPeriods={payrollPeriods} onDelete={onDelete} onEdit={onEdit}/>
	);
}

export default List;