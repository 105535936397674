import React, { useState, useEffect } from "react";
import { getUser } from "api/users";
import { usePost } from "utils/api"
import View from "components/nav/GeoSidebar.view";

function GeoSideBar({
	onAddTab,
	setTabs,
	theme,
	button
}) {

	const id = sessionStorage.getItem("id");
	const [user, setUser] = useState({});

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
	}, [id])


	return (
		<View
			onAddTab={onAddTab}
			setTabs={setTabs}
			theme={theme}
			user={user}
			button={button}
		/>
	)

}

export default GeoSideBar;