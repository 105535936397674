import React from "react";

class Loading extends React.Component {
    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div class="spinner-border text-primary" role="status" 
                style={{ position: "relative", width: "80px", height: "80px", left: "50%", top: "80%" }}>
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
}

Loading.propTypes = {};

export default Loading;