import React from "react";
import View from "components/landing/Resource.view";


function Resource({ setIsVisible, resource }) {

    const onClose = () => setIsVisible(false);
    return <View onClose={onClose} resource={resource} />;

}

export default Resource;