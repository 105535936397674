import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react"
import { toast } from "react-toastify"
import { useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/deviceEmployees/List.view"
import { getDeviceEmployees } from "api/deviceEmployees"

const DeviceEmployeeList = forwardRef((props, ref) => {

	const {
		countRef,
		search,
		theme,
		addDeviceEmployeeModal,
		reloadDevices,
		setReloadDevices,
	} = props

	const { loading, setLoading } = useContext(LoadingContext);
	const [devices, setDevices] = useState([])

	if(countRef != null && countRef.current != null)
		countRef.current.innerHTML = devices.length

	const [callDevices, reqDevices] = useGetCall("/devices", {
		onCompleted: (response) => {
			setLoading(false);

			fetchPost("/device_employees/check_permission", { table: "DEVICE_EMPLOYEE", type: "read" }).then((res) => {
				if (res.data) {
					if (res.data.isAllowed === true) {
						setDevices(response.data)
					} else {
						if (!toast.isActive("toast-list-devices-employees-unauthorized")) {
							toast.error("Error, no tienes los permisos para visualizar los lectores a empleados", { toastId: "toast-list-devices-employees-unauthorized" })
						}
					}
				}
			})

		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "device_employees", "dispositivos-empleados", "read")
		}
	})

	useEffect(() => {
		setLoading(true);
		callDevices({ search });
		setReloadDevices(false)

	}, [reloadDevices, setReloadDevices, search])

	const generateCells = async () => {

		const csv = [];
		const employees = {};
		const auxDevices = [];

		for (let i = 0; i < devices.length; i++) {

			const response = await getDeviceEmployees(devices[i].id, "device_to_employee");
			const deviceEmployees = response.data;

			if (deviceEmployees != null) {

				for (let j = 0; j < deviceEmployees.length; j++) {
					const employee = deviceEmployees[j].employee;
					if (!employees[employee.key]) employees[employee.key] = employee;
				}

				auxDevices.push({
					device: devices[i],
					deviceEmployees: deviceEmployees
				});

			}

		}

		const header = [""];
		const employeesIds = [null];
		for (let i in employees) {
			header.push(employees[i].key);
			employeesIds.push(employees[i].key);
		}

		csv.push(header);

		for (let i = 0; i < auxDevices.length; i++) {

			const device = auxDevices[i].device;
			const deviceEmployees = auxDevices[i].deviceEmployees;

			const row = [device.serial_number];

			for (let j = 1; j < header.length; j++) {

				let found = false;

				for (let k = 0; k < deviceEmployees.length; k++) {
					if (deviceEmployees[k].employee.key === employeesIds[j] && deviceEmployees[k].status === true) {
						row.push("X");
						found = true;
						break;
					}
				}

				if (!found) row.push("");

			}

			csv.push(row);

		}

		return csv;

	}

	useImperativeHandle(ref, () => ({
		async generateCells() {
			return await generateCells()
		}
	}))

	const onEdit = (id) => addDeviceEmployeeModal(id)

	if (loading) return <Loading />

	return (
		<View
			devices={devices}
			onEdit={onEdit}
			theme={theme}
		/>
	)

})

export default DeviceEmployeeList