import React, { useState, useRef, useEffect, useContext } from "react";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/reports/incidences/IncidencesReport.view";
import { usePost, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { getUser } from "api/users";
import { getIncidencesReports } from "api/incidencesReports";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { getNameIncidence, getWeekDayName } from "utils/constants";
import { formatISODate } from "utils/date";
import { downloadCSV, downloadExcel } from "utils/files";
import { getTotalTime, formatTime } from "utils/reports";
import moment from "moment";

const checkIncidence = (incidences, type) => {
	const formatedIncidences = JSON.parse(incidences);
	if (!formatedIncidences) return false;
	
	const index = formatedIncidences.findIndex((item) => item.incidence == type);
	return index != -1;
}

function IncidencesReport() {

	let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const tab = savedLayout.tabs.find((item) => item.content === "Reporte Incidencias");

	const { loading, setLoading } = useContext(LoadingContext);
	const [showIncidenceRequest, setShowIncidenceRequest] = useState(false);
	const [selectedIncidenceRequest, setSelectedIncidenceRequest] = useState(null);

	const reportRef = useRef(null);
	const id = sessionStorage.getItem("id");
	const [searchFilters, setSearchFilters] = useState({
		search: "",
		payroll_id: "",
		payroll_period_id: "",
		start_date: "",
		end_date: "",
		office_id: "",
		job_id: "",
		department_id: "",
		incidence: ""
	});

	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [requestModal, setRequestModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [reports, setReports] = useState([]);
	const [user, setUser] = useState({});

	const [permissionExcel, setPermissionExcel] = useState(false);
	const [permissionPdf, setPermissionPdf] = useState(false);
	const [permissionRead, setPermissionRead] = useState(false);

	const [attendanceLogsEmployee, setAttendanceLogsEmployee] = useState(null);
	const [attendanceLogsDate, setAttendanceLogsDate] = useState(null);
	const [attendanceLogsKey, setAttendanceLogsKey] = useState(null);
	const [attendanceLogsModalVisible, setAttendanceLogsModalVisible] = useState(false);

	const [callIncidenceRequest, reqIncidenceRequest] = usePost("/incidences_requests", {
		onCompleted: () => {
			if (!toast.isActive("incidence_request-created"))
				toast.success("Solicitud de incidencia creada correctamente", { toastId: "incidence_request-created" })

			setSelectedEmployee(null)
		},
		onError: (error) => {
			console.error(error);
			if (!toast.isActive("incidence_request-error")) {
				toast.error("Error al crear la solicitud de incidencia, por favor intente mas tarde", { toastId: "incidence_request-error" })
			}
		}
	})

	const [callAttendanceLogs, reqAttendanceLogs] = useGetCall("/incidences_reports", {
		onCompleted: (response) => {
			setLoading(false);
			setReports(response.data);
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "incidences_reports", "reportes de incidencias", "read");
		}
	})

	const setFilters = (values) => {
		setSearchFilters(values);
		callAttendanceLogs(values);
	}

	const onSubmitRequest = (values) => {
		values.employee = selectedEmployee;
		callIncidenceRequest(values);
	}

	const showRequestModal = (employeeId, date) => {
		const employee = reports.find((reportEmployee) => reportEmployee.employee.id === employeeId)?.employee;
		setSelectedEmployee(employee);
		setSelectedDate(date);
		setRequestModal(true);
	}

	const hideRequestModal = () => setRequestModal(false);
	const hideIncidenceModal = () => setShowIncidenceRequest(false);
	const showFilterModal = () => setFilterModal(true);
	const hideFilterModal = () => setFilterModal(false);

	const generateCells = (format = "csv") => {

		let csv = [];

		reports.forEach((report) => {

			let employee = report.employee;
			if(employee.leave && moment.utc(employee.leave).startOf('day') < moment.utc(searchFilters.start_date).startOf('day')) return;

			csv.push([
				`ID:`,
				employee.key,
				`Nombre:`,
				`${employee.firstname} ${employee.dad_lastname} ${employee.mom_lastname}`,
				`Puesto:`,
				employee.job.description,
				`Departamento:`,
				employee.department.description
			])

			csv.push([
				`Fecha`,
				`Día`,
				`Horario`,
				`Entrada`,
				`S. Descanso`,
				`E. Descanso`,
				`Salida`,
				`Horas trab.`,
				`Horas desc.`,
				`Horas trab. reales`,
				`Retardo`,
				`S. anticipada`,
				`T. extra`,
				`T. extra red.`,
				`Incidencias`
			]);

			report.reports.forEach((incidence) => {
				csv.push([
					`${formatISODate(incidence.date, true)}`,
					`${getWeekDayName(incidence.week_day)}`,
					`${function () {

						if(!incidence.schedule || incidence.schedule == "") return "";

						const schedule = JSON.parse(incidence.schedule);
						if(schedule) {
							if (schedule.has_break) return "Descanso";
							return `${schedule?.entry_start.substring(0, 5)} - ${schedule?.exit_start.substring(0, 5)}`;
						}

						return "";

					}()
					}`,
					`${incidence.entry ? incidence.entry : "-"}`,
					`${incidence.exit_break ? incidence.exit_break : "-"}`,
					`${incidence.return_break ? incidence.return_break : "-"}`,
					`${incidence.exit ? incidence.exit : "-"}`,
					`${incidence.worked_hours ? formatTime(incidence.worked_hours) : "-"}`,
					`${incidence.rest_hours ? formatTime(incidence.rest_hours) : "-"}`,
					`${incidence.real_worked_hours ? formatTime(incidence.real_worked_hours) : "-"}`,
					`${incidence.retardment ? formatTime(incidence.retardment) : "-"}`,
					`${incidence.early_exit ? formatTime(incidence.early_exit) : "-"}`,
					`${incidence.extra_time ? formatTime(incidence.extra_time) : "-"}`,
					`${incidence.extra_time_round ? formatTime(incidence.extra_time_round) : "-"}`,
					(format === "csv"
						? `${'"' + JSON.parse(incidence.incidences).map((incidenceName) => getNameIncidence(incidenceName.incidence)).join(" / ") + '"'}`
						: `${JSON.parse(incidence.incidences).map((incidenceName) => getNameIncidence(incidenceName.incidence)).join(" / ")}`
					)
				]);
			});

			csv.push([
				"",
				"",
				"",
				"",
				"",
				"",
				"Total:",
				getTotalTime(report.reports, "worked_hours"),
				getTotalTime(report.reports, "rest_hours"),
				getTotalTime(report.reports, "real_worked_hours"),
				getTotalTime(report.reports, "retardment"),
				getTotalTime(report.reports, "early_exit"),
				getTotalTime(report.reports, "extra_time"),
				getTotalTime(report.reports, "extra_time_round"),
			]);

			csv.push([]);
			csv.push([]);

		});

		return csv;

	}

	const generateColumnCells = () => {

		let csv = [], idx = 1, index = 1, idx_join = 9;
		const cells_join = [];
		const sortedReports = Object.values(reports).sort((a, b) => (a.employee.dad_lastname??"").localeCompare((b.employee.dad_lastname??"")));

		csv.push([
			{value: `No.`, color: "#0170c2"},
			{value: `Id empleado`, color: "#0170c2"},
			{value: `Nombre empleado`, color: "#0170c2"},
			{value: `Centro de costos`, color: "#0170c2"},
			{value: `Turno`, color: "#0170c2"},
			{value: `Día semana`, color: "#0170c2"},
			{value: `Fecha`, color: "#0170c2"},
			{value: `Horario`, color: "#0170c2"},
			{value: `Detalle incidencias`, color: "#0170c2"},
			{value: `Entrada`, color: "#0170c2"},
			{value: `S. Descanso`, color: "#0170c2"},
			{value: `E. Descanso`, color: "#0170c2"},
			{value: `Salida`, color: "#0170c2"},
			{value: `Horas trab.`, color: "#0170c2"},
			{value: `Horas desc.`, color: "#0170c2"},
			{value: `Horas trab. reales`, color: "#0170c2"},
			{value: `Retardo`, color: "#0170c2"},
			{value: `S. anticipada`, color: "#0170c2"},
			{value: `T. extra`, color: "#0170c2"},
			{value: `T. extra red.`, color: "#0170c2"},
			{value: `A`, color: "#0170c2"},
			{value: `F`, color: "#0170c2"},
			{value: `R`, color: "#0170c2"},
			{value: `SA`, color: "#0170c2"},
			{value: `OE`, color: "#0170c2"},
			{value: `OS`, color: "#0170c2"},
			{value: `TE`, color: "#0170c2"},
			{value: `VT`, color: "#0170c2"},
			{value: `DFT`, color: "#0170c2"},
			{value: `PD`, color: "#0170c2"},
			{value: `DD`, color: "#0170c2"},
			{value: `DDT`, color: "#0170c2"},
			{value: `DF`, color: "#0170c2"},
			{value: `L`, color: "#0170c2"},
			{value: `V`, color: "#0170c2"},
			{value: `DE`, color: "#0170c2"},
			{value: `IM`, color: "#0170c2"},
			{value: `VH`, color: "#0170c2"},
			{value: `B`, color: "#0170c2"},
			{value: `Incidencias`, color: "#0170c2"},
		]);

		sortedReports.forEach((report) => {

			let employee = report.employee;
			if(employee.leave && moment.utc(employee.leave).startOf('day') < moment.utc(searchFilters.start_date).startOf('day')) return;

			csv.push([
				{value: `${index} // ID: ${employee.key} // Nombre: ${employee.firstname} ${employee.dad_lastname} ${employee.mom_lastname} // Nómina: ${employee.payroll.description}` +
				` // Puesto: ${employee.job.description} // Departamento: ${employee.department.description}`, color: "#4b83be"}
			])

			cells_join.push(`A${idx_join}:AN${idx_join}`)
			idx_join += report.reports.length + 2;

			report.reports.forEach((incidence) => {
				csv.push([
					idx.toString(),
					employee.key,
					`${employee.dad_lastname} ${employee.mom_lastname} ${employee.firstname}`,
					employee.cost_center?.description ?? '',
					employee.shift?.description ?? '',
					`${getWeekDayName(incidence.week_day)}`,
					`${formatISODate(incidence.date, true)}`,
					`${function () {

						if(!incidence.schedule || incidence.schedule == "") return "";

						const schedule = JSON.parse(incidence.schedule);
						if(schedule) {
							if (schedule.has_break) return "Descanso";
							return `${schedule?.entry_start.substring(0, 5)} - ${schedule?.exit_start.substring(0, 5)}`;
						}

						return "";

					}()}`,
					JSON.parse(incidence.incidences).map((incidenceName) => getNameIncidence(incidenceName.incidence)).join(" / "),
					`${incidence.entry ? incidence.entry : "-"}`,
					`${incidence.exit_break ? incidence.exit_break : "-"}`,
					`${incidence.return_break ? incidence.return_break : "-"}`,
					`${incidence.exit ? incidence.exit : "-"}`,
					`${incidence.worked_hours ? formatTime(incidence.worked_hours) : "-"}`,
					`${incidence.rest_hours ? formatTime(incidence.rest_hours) : "-"}`,
					`${incidence.real_worked_hours ? formatTime(incidence.real_worked_hours) : "-"}`,
					`${incidence.retardment ? formatTime(incidence.retardment) : "-"}`,
					`${incidence.early_exit ? formatTime(incidence.early_exit) : "-"}`,
					`${incidence.extra_time ? formatTime(incidence.extra_time) : "-"}`,
					`${incidence.extra_time_round ? formatTime(incidence.extra_time_round) : "-"}`,
					checkIncidence(incidence.incidences, "ATTENDANCE") ? "✓" : "",
					checkIncidence(incidence.incidences, "FOUL") ? "✓" : "",
					checkIncidence(incidence.incidences, "RETARDMENT") ? "✓" : "",
					checkIncidence(incidence.incidences, "EARLY_DEPARTURE") ? "✓" : "",
					checkIncidence(incidence.incidences, "ENTRY_OMISSION") ? "✓" : "",
					checkIncidence(incidence.incidences, "EXIT_OMISSION") ? "✓" : "",
					checkIncidence(incidence.incidences, "EXTRA_TIME") ? "✓" : "",
					checkIncidence(incidence.incidences, "WORKED_HOLIDAYS") ? "✓" : "",
					checkIncidence(incidence.incidences, "WORKED_BANK_HOLIDAY") ? "✓" : "",
					checkIncidence(incidence.incidences, "VACATION_BONUS") ? "✓" : "",
					checkIncidence(incidence.incidences, "REST_DAY") ? "✓" : "",
					checkIncidence(incidence.incidences, "WORKED_REST_DAY") ? "✓" : "",
					checkIncidence(incidence.incidences, "BANK_HOLIDAY") ? "✓" : "",
					checkIncidence(incidence.incidences, "LICENSE") ? "✓" : "",
					checkIncidence(incidence.incidences, "HOLIDAYS") ? "✓" : "",
					checkIncidence(incidence.incidences, "ECONOMIC_DAY") ? "✓" : "",
					checkIncidence(incidence.incidences, "INSERT_MARKINGS") ? "✓" : "",
					checkIncidence(incidence.incidences, "HOURLY_VACATIONS") ? "✓" : "",
					checkIncidence(incidence.incidences, "LEAVE") ? "✓" : "",
					`${JSON.parse(incidence.incidences).map((incidenceName) => getNameIncidence(incidenceName.incidence)).join(" / ")}`,
				]);

				idx += 1;
			});

			csv.push([
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"Total:",
				getTotalTime(report.reports, "worked_hours"),
				getTotalTime(report.reports, "rest_hours"),
				getTotalTime(report.reports, "real_worked_hours"),
				getTotalTime(report.reports, "retardment"),
				getTotalTime(report.reports, "early_exit"),
				getTotalTime(report.reports, "extra_time"),
				getTotalTime(report.reports, "extra_time_round"),
			]);

			index += 1;

		});

		return [csv, cells_join];

	}

	const onDownloadCSV = () => {
		downloadCSV({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells(),
			report: "incidencias",
		});
	}

	const onDownloadXlsx = async () => {
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells("xlsx"),
			report: "incidencias",
		})
	}

	const onDownloadXlsxColumns = async () => {

		const [cells, cells_join] = generateColumnCells();
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: cells,
			report: "incidencias",
			cells_to_join: cells_join
		})
	}

	const onChangeSearch = (event) => {
		if (event.target.value === '') {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	const onShowIncidenceRequest = (id) => {
		setSelectedIncidenceRequest(id);
		setShowIncidenceRequest(true);
	}

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
	}, [id]);

	useEffect(() => {
		fetchPost("/permissions/check", { table: "INCIDENCES_REPORT", permission: "excel" }).then((response) => {
			if (response.data) setPermissionExcel(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "INCIDENCES_REPORT", permission: "pdf" }).then((response) => {
			if (response.data) setPermissionPdf(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "INCIDENCES_REPORT", permission: "read" }).then((response) => {
			if (response.data) {
				setPermissionRead(response.data.isAllowed)
				if (response.data.isAllowed) {
					setFilterModal(tab ? tab.active : false)
				} else {
					if (!toast.isActive(`toast-read-incidence_reports-unauthorized`)) {
						toast.error(`Error, no tienes los permisos para consultar reportes de incidencias`, { toastId: `toast-read-incidence_reports-unauthorized` })
					}
				}
			}
		});
	}, []);

	if (loading) return <Loading />;

	return (
		<View
			user={user}
			permissionExcel={permissionExcel}
			permissionPdf={permissionPdf}
			permissionRead={permissionRead}
			selectedDate={selectedDate}
			onChangeSearch={onChangeSearch}
			handleKeyUp={handleKeyUp}
			searchFilters={searchFilters}
			setLoading={setLoading}
			onDownloadXlsx={onDownloadXlsx}
			onDownloadXlsxColumns={onDownloadXlsxColumns}
			onDownloadCSV={onDownloadCSV}
			reportRef={reportRef}
			reports={reports}
			requestModal={requestModal}
			filterModal={filterModal}
			filters={searchFilters}
			setFilters={setFilters}
			attendanceLogsEmployee={attendanceLogsEmployee}
			setAttendanceLogsEmployee={setAttendanceLogsEmployee}
			attendanceLogsKey={attendanceLogsKey}
			setAttendanceLogsKey={setAttendanceLogsKey}
			attendanceLogsDate={attendanceLogsDate}
			setAttendanceLogsDate={setAttendanceLogsDate}
			onSubmitRequest={onSubmitRequest}
			showRequestModal={showRequestModal}
			showFilterModal={showFilterModal}
			hideRequestModal={hideRequestModal}
			hideFilterModal={hideFilterModal}
			showIncidenceRequest={showIncidenceRequest}
			hideIncidenceModal={hideIncidenceModal}
			attendanceLogsModalVisible={attendanceLogsModalVisible}
			setAttendanceLogsModalVisible={setAttendanceLogsModalVisible}
			selectedIncidenceRequest={selectedIncidenceRequest}
			onShowIncidenceRequest={onShowIncidenceRequest}
		/>
	)


}

export default IncidencesReport;