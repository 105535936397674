import React from 'react';
import EnviromentList from "components/admin/enviroments/List";
import Modal from "helpers/Modal";

function EnviromentsView({
    setTabs,
    search,
	onChangeSearch,
	handleKeyUp,
    isVisible,
    setIsVisible,
    modalTitle,
    modalContent,
    addEnviromentModal,
    addEditEnviromentModal,
    modalTitleEdit,
    isVisibleEdit,
    setIsVisibleEdit,
    modalContentEdit,
    isVisibleAttendance,
    setIsVisibleAttendance,
    modalTitleAttendance,
    modalContentAttendance,
    addAttendanceModal
}) {
    return (
        <>
            <div className="content container-fluid p-5">


                <div className="page-header mb-3">
                    <div className="row align-items-end">

                        <div className="col-sm mb-2 mb-sm-0">
                            <h1 className="page-header-title">Entornos</h1>
                        </div>

                        <div className="col-sm-auto">
                            <button type="button" className="btn btn-primary" onClick={addEnviromentModal}>
                                <i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
                                Agregar entornos
                            </button>
                            <button type="button" className="btn btn-success ms-3" onClick={addAttendanceModal}>
                                <i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
                                Cargar asistencia
                            </button>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header card-header-content-md-between">
                        <div class="mb-2 mb-md-0">
                            <div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
                                <div class="input-group-prepend input-group-text" >
                                    <i class="bi-search"></i>
                                </div>
                                <input 
                                    type="search" 
                                    class="form-control" 
                                    placeholder="Buscar entorno" 
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
                                />
                            </div>
                        </div>
                    </div>

                    <EnviromentList
                        search={search}
                        refresh={Math.random()}
                        setTabs={setTabs}
                        addEditEnviromentModal={addEditEnviromentModal}
                    />
                </div>

            </div>

            <Modal
                title={modalTitle}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            >
                {modalContent}
            </Modal>

            <Modal
                title={modalTitleEdit}
                isVisible={isVisibleEdit}
                setIsVisible={setIsVisibleEdit}
            >
                {modalContentEdit}
            </Modal>

            <Modal
                title={modalTitleAttendance}
                isVisible={isVisibleAttendance}
                setIsVisible={setIsVisibleAttendance}
            >
                {modalContentAttendance}
            </Modal>

        </>
    );
}

export default EnviromentsView;