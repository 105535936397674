import React, { useState, useEffect, useRef } from "react";
import { useGetCall, usePut } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/visits/colors/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [color, setColor] = useState({});
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callCostCenter, reqCostCenter] = useGetCall(`/colors/${id}`, {
        onCompleted: (response) => {
            if (formikRef.current) {
                formikRef.current.setValues(response.data);
            }
            setColor(response.data);
        }, onError: (error) => {
            console.error(error)
            showResponseMessage(error.status, "colors", "colores", "edit")
        }
    })

    const [callUpdate, reqUpdate] = usePut("/colors", {
        onCompleted: () => {
            if (!toast.isActive("colors-updated"))
                toast.success("Marca actualizada correctamente", { toastId: "colors-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (error) => {
            console.error(error)
            showResponseMessage(error.status, "colors", "colores", "edit")
        }
    })

    useEffect(() => {
        callCostCenter();
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            color={color}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;