import React from "react";
import NavBar from "components/nav/Navbar";
import SideBar from "components/nav/Sidebar";
import Tabs from 'components/tabs/Tabs';
import "resources/styles/Layout.scss";

function LayoutCrhView({
    activeTabId,
    setActiveTabId,
    tabs,
    onAddTab,
    button,
    setTabs,
    setButton,
    theme,
    setTheme,

}) {
    return (
        <div>
            <NavBar onAddTab={onAddTab} button={button} setButton={setButton} theme={theme} setTheme={setTheme} />
            <SideBar
                onAddTab={onAddTab}
                theme={theme}
                button={button}
                setButton={setButton}
            />

            <div className={button ? 'container_admin' : 'container_admin_active'} style={{ transition: "all 0.3s ease-in" }}>
                <Tabs 
                    theme={theme} 
                    button={button} 
                    tabs={tabs} 
                    setTabs={setTabs}
                    activeTabId={activeTabId}
                    setActiveTabId={setActiveTabId}
                />
            </div>
        </div>
    )
}

export default LayoutCrhView;