import React from "react";
import RequestsList from "components/crh/visits/visitRequests/List";
import Modal from "helpers/Modal";

function VisitRequestsView({
	requestsListRef,
	excel,
	read,
	create,
	filters,
	search,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	isVisibleEdit,
	setIsVisibleEdit,
	modalContent,
	modalContentEdit,
	modalTitle,
	modalTitleEdit,
	addCreateModal,
	addEditModal,
	isVisibleFilter,
	addFilterModal,
	modalTitleFilter,
	modalContentFilter,
	setIsVisibleFilter,
	onDownloadXlsx,
}) {

	return (
		<>

			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Solicitudes</h1>
						</div>

						<div className="col-sm-auto">
							
							{
								excel && (
									<button type="button" className="btn bg-success text-white mx-1" onClick={onDownloadXlsx}>
										<i className="bi bi-file-earmark-excel" style={{ marginRight: "7px" }}></i>
										Descargar Excel
									</button>
								)
							}

							{
								read && (
									<button type="button" className="btn btn-primary mx-1" onClick={addFilterModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Filtrar resultados
									</button>
								)
							}

							{
								create && (
									<button type="button" className="btn btn-primary" onClick={addCreateModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Agregar visita
									</button>
								)
							}

						</div>

					</div>
				</div>

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input
									type="search"
									class="form-control"
									placeholder="Buscar solicitud"
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
						</div>
					</div>

					<RequestsList
						ref={requestsListRef}
						refresh={Math.random()}
						filters={filters}
						search={search}
						addEditModal={addEditModal}
					/>

				</div>
			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

			<Modal
				title={modalTitleFilter}
				isVisible={isVisibleFilter}
				setIsVisible={setIsVisibleFilter}
			>
				{modalContentFilter}
			</Modal>

		</>
	);
}

export default VisitRequestsView;