import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { usePost, fetchPost } from "utils/api";
import View from "components/crh/processes/Processes.view";
import { getOffices } from "api/offices";
import { getPayrolls, getPayollPeriods } from "api/payrolls";

function Processes() {

  const [offices, setOffices] = useState([]);
  const [payrolls, setPayrolls] = useState([]);
  const [payrollPeriods, setPayrollPeriods] = useState([]);

  const [selectedOffices, setSelectedOffices] = useState({});
  const [selectedPayroll, setSelectedPayroll] = useState({});
  const [selectedPayrollPeriod, setSelectedPayrollPeriod] = useState({});

  const [callExecution, reqExecution] = usePost("/processes", {
    onCompleted: () => {
      // if(!toast.isActive("regime-created"))
      // toast.success("Régimen creado correctamente", {toastId : "regime-created"});
    },
    onError: (err) => {

      console.error(err)

      if (!toast.isActive("active-processes-error") && err.status === 401)
        toast.error("Ya hay procesos activos, por favor espere a que terminen para iniciar otro", { toastId: "active-processes-error" });

      if (!toast.isActive(`toast-create-processes-unauthorized`) && err.status === 403)
        toast.error(`Error, no tienes los permisos para crear procesos`, { toastId: `toast-create-processes-unauthorized` })

      if (!toast.isActive("data-process-error") && err.status === 400)
        toast.error("Error, por favor revise que haya seleccionado una nómina y un periodo de nómina", { toastId: "data-process-error" });

      if (!toast.isActive("process-error") && err.status === 500)
        toast.error("Error interno del servidor, por favor vuelva a intentarlo mas tarde", { toastId: "process-error" });
    }

  })

  useEffect(() => {

    getOffices().then((data) => {
      if (data.errors.length > 0) {
        if (!toast.isActive("toast-offices-eror"))
          toast.error("Error al obtener las sucursales", { toastId: "toast-offices-eror" })
      }
      else {
        setOffices(data.data)
      }
    })

    getPayrolls().then((data) => {
      if (data.errors.length > 0) {
        if (!toast.isActive("toast-payrolls-eror"))
          toast.error("Error al obtener los tipos de nóminas", { toastId: "toast-payrolls-eror" })
      }
      else {
        setPayrolls(data.data)
      }
    })

  }, [])

  const onChangePayroll = (payroll) => {
    setSelectedPayroll(payroll)
    getPayollPeriods(payroll.id).then((data) => {
      if (data.errors.length > 0) {
        if (!toast.isActive("toast-payrollPeriods-eror"))
          toast.error("Error al obtener los periodos de nómina", { toastId: "toast-payrollPeriods-eror" })
      }
      else {
        setPayrollPeriods(data.data)
      }
    })
  }

  const onCheckOffice = (id) => {
    setSelectedOffices({
      ...selectedOffices,
      [id]: selectedOffices[id] ? false : true
    })
  }

  const onExecute = () => {

    fetchPost("/permissions/check", { table: "INCIDENCE_PROCESS", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {

          const officesIds = Object.keys(selectedOffices).filter((id) => selectedOffices[id])
          if (selectedPayroll.id === undefined || selectedPayrollPeriod.id === undefined) {
            if (!toast.isActive("data-process-error"))
              toast.error("Seleccione una nómina y un periodo de nómina primero", { toastId: "data-process-error" });
            return;
          }

          callExecution({
            offices: officesIds,
            payrollPeriod: selectedPayrollPeriod,
            payroll: selectedPayroll
          })

        } else {
          if (!toast.isActive("toast-create-processes-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear procesos", { toastId: "toast-create-processes-unauthorized" })
          }
        }
      }
    })



  }

  return <View
    offices={offices}
    payrolls={payrolls}
    payrollPeriods={payrollPeriods}
    selectedOffices={selectedOffices}
    selectedPayroll={selectedPayroll}
    selectedPayrollPeriod={selectedPayrollPeriod}
    onChangePayroll={onChangePayroll}
    onCheckOffice={onCheckOffice}
    setSelectedPayroll={setSelectedPayroll}
    setSelectedPayrollPeriod={setSelectedPayrollPeriod}
    onExecute={onExecute}
  />;

}

export default Processes;