import React, { useState, useRef, useEffect, useContext } from "react";
import View from "components/crh/reports/puntuality/PuntualityReport.view";
import { getUser } from "api/users";
import Loading from "helpers/Loading";
import { useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import { toast } from "react-toastify";
import { downloadCSV, downloadExcel } from "utils/files";
import moment from "moment";

function PuntualityReport() {

	let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const tab = savedLayout.tabs.find((item) => item.content === "Reporte Puntualidad");

	const { loading, setLoading } = useContext(LoadingContext);

	const reportRef = useRef(null);
	const id = sessionStorage.getItem("id");
	const [searchFilters, setSearchFilters] = useState({
		search: "",
		payroll_id: "",
		payroll_period_id: "",
		start_date: "",
		end_date: "",
		office_id: "",
		job_id: "",
		department_id: ""
	});

	const [filterModal, setFilterModal] = useState(false);
	const [reports, setReports] = useState([]);
	const [user, setUser] = useState({});

	const [permissionExcel, setPermissionExcel] = useState(false);
	const [permissionPdf, setPermissionPdf] = useState(false);
	const [permissionRead, setPermissionRead] = useState(false);

	const [callReports, reqReports] = useGetCall("/reports/puntuality", {
		onCompleted: (response) => {
			setLoading(false);
			setReports(response.data);
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "puntuality_reports", "reportes de puntualidad", "read");
		}
	})

	const setFilters = (values) => {
		setSearchFilters(values);
		callReports(values);
	}

	const showFilterModal = () => setFilterModal(true);
	const hideFilterModal = () => setFilterModal(false);

	const generateCells = () => {

		let csv = [];

		csv.push([
			`ID`,
			`Nombre`,
			`Sucursal`,
			`Departamento`,
			`Puesto`,
			`Periodo`,
			`Retardos`,
		]);

		reports.forEach((report) => {

			let employee = report.employee;
			if(employee.leave && moment.utc(employee.leave).startOf('day') < moment.utc(searchFilters.start_date).startOf('day')) return;

			csv.push([
				report.employee.key,
				`${report.employee.firstname} ${report.employee.mom_lastname} ${report.employee.dad_lastname}`,
				report.employee.office.description,
				report.employee.department.description,
				report.employee.job.description,
				`${moment.utc(searchFilters.start_date).format("DD [de] MMMM [de] YYYY")} - ${moment.utc(searchFilters.end_date).format("DD [de] MMMM [de] YYYY")}`,
				report.retardments
			]);

		});

		return csv;

	}

	const onDownloadCSV = () => {
		downloadCSV({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells(),
			report: "puntualidad",
		});
	}

	const onDownloadXlsx = () => {
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells(),
			report: "puntualidad",
		})
	}

	const onChangeSearch = (event) => {
		if (event.target.value === '') {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
	}, [id]);

	useEffect(() => {
		fetchPost("/permissions/check", { table: "PUNTUALITY_REPORT", permission: "excel" }).then((response) => {
			if (response.data) setPermissionExcel(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "PUNTUALITY_REPORT", permission: "pdf" }).then((response) => {
			if (response.data) setPermissionPdf(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "PUNTUALITY_REPORT", permission: "read" }).then((response) => {
			if (response.data) {
				setPermissionRead(response.data.isAllowed)
				if (response.data.isAllowed) {
					setFilterModal(tab ? tab.active : false)
				} else {
					if (!toast.isActive(`toast-read-puntuality_reports-unauthorized`)) {
						toast.error(`Error, no tienes los permisos para consultar reportes de puntualidad`, { toastId: `toast-read-puntuality_reports-unauthorized` })
					}
				}
			}
		});
	}, []);

	if (loading) return <Loading />;

	return (
		<View
			user={user}
			permissionExcel={permissionExcel}
			permissionPdf={permissionPdf}
			permissionRead={permissionRead}
			handleKeyUp={handleKeyUp}
			onChangeSearch={onChangeSearch}
			searchFilters={searchFilters}
			setLoading={setLoading}
			onDownloadXlsx={onDownloadXlsx}
			onDownloadCSV={onDownloadCSV}
			reportRef={reportRef}
			reports={reports}
			filterModal={filterModal}
			filters={searchFilters}
			setFilters={setFilters}
			showFilterModal={showFilterModal}
			hideFilterModal={hideFilterModal}
		/>
	)


}

export default PuntualityReport;