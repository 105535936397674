import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import { getDevices } from "api/devices";
import View from "components/crh/deviceManager/Instructions.view";

function Instructions({ onSendInstructions }) {

	const onSubmit = (values) =>
		onSendInstructions(values)

	return (
		<View 
            onSubmit={onSubmit}
		/>
	)

}

export default Instructions;