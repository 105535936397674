import React, { useState } from "react";
import FormSave from "components/crh/shifts/FormSave";
import FormSet from "components/crh/shifts/FormSet";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/shifts/Shifts.view";

function Shifts() {

    const [isVisible, setIsVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [isVisibleEdit, setIsVisibleEdit] = useState(false);
    const [modalTitleEdit, setModalTitleEdit] = useState("");
    const [modalContentEdit, setModalContentEdit] = useState(null);

    const [search, setSearch] = useState("");

    const addModal = () => {
        
        fetchPost("/shifts/check_permission", { type: "create" }).then((response) => {
            if (response.data) {
                const data = response.data;
                if (data.isAllowed === true) {
                    setIsVisible(true);
                    setModalTitle("Turno");
                    setModalContent(
                        <FormSave setIsVisible={setIsVisible} />
                    );
                } else {
                    if (!toast.isActive("toast-create-shift-unauthorized")) {
                        toast.error("Error, no tienes los permisos para crear turnos", { toastId: "toast-create-shift-unauthorized" })
                    }
                }
            }
        })

    }

    const addEditModal = (id) => {

        fetchPost("/shifts/check_permission", { type: "edit" }).then((response) => {
            if (response.data) {
                const data = response.data;
                if (data.isAllowed === true) {
                    setIsVisibleEdit(true);
                    setModalTitleEdit("Editar turno");
                    setModalContentEdit(
                        <FormSet
                            id={id}
                            refresh={Math.random()}
                            setIsVisible={setIsVisibleEdit}
                        />
                    );
                } else {
                    if (!toast.isActive("toast-edit-shift-unauthorized")) {
                        toast.error("Error, no tienes los permisos para editar turnos", { toastId: "toast-edit-shift-unauthorized" })
                    }
                }
            }

        })

    }

    const onChangeSearch = (event) => {
        if (event.target.value === '') {
            setSearch(event.target.value);
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            setSearch(event.target.value);
        }
    }

    return (
        <View
            search={search}
            onChangeSearch={onChangeSearch}
            handleKeyUp={handleKeyUp}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            modalTitle={modalTitle}
            modalContent={modalContent}
            addModal={addModal}
            addEditModal={addEditModal}
            modalTitleEdit={modalTitleEdit}
            isVisibleEdit={isVisibleEdit}
            setIsVisibleEdit={setIsVisibleEdit}
            modalContentEdit={modalContentEdit}
        />
    );
}

export default Shifts;