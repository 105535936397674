import React, { useState } from 'react';
import View from "components/landing/Landing.view"
import useResize from "utils/resize";

function Landing() {

    const [selectedResource, setSeletedResource] = useState("");
    const [isVisibleResource, setIsVisibleResource] = useState(false);
    const [isVisibleContact, setIsVisibleContact] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const resize = useResize();

    const addModal = () => {
        setIsVisible(true);
    }

    const addContactModal = () => {
        setIsVisibleContact(true);
    }

    const addResourcetModal = (resource) => {
        setIsVisibleResource(true);
        setSeletedResource(resource);
    }

    return (
        <View
            resize={resize}
            selectedResource={selectedResource}
            addModal={addModal}
            addContactModal={addContactModal}
            addResourcetModal={addResourcetModal}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            isVisibleContact={isVisibleContact}
            isVisibleResource={isVisibleResource}
            setIsVisibleContact={setIsVisibleContact}
            setIsVisibleResource={setIsVisibleResource}
        />
    );
}

export default Landing;