import React, { useState, useRef, useEffect, useContext } from "react";
import View from "components/crh/reports/workedRestDay/WorkedRestDayReport.view";
import { getUser } from "api/users";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { getNameIncidence, getWeekDayName } from "utils/constants";
import { useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { formatISODate } from "utils/date";
import { LoadingContext } from "helpers/LoadingContext";
import { downloadCSV, downloadExcel } from "utils/files";
import moment from "moment";

function WorkedRestDayReport() {

	let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const tab = savedLayout.tabs.find((item) => item.content === "Reporte D. Trabajados");

	const { loading, setLoading } = useContext(LoadingContext);

	const reportRef = useRef(null);
	const id = sessionStorage.getItem("id");
	const [searchFilters, setSearchFilters] = useState({
		search: "",
		payroll_id: "",
		payroll_period_id: "",
		start_date: "",
		end_date: "",
		office_id: "",
		job_id: "",
		department_id: ""
	});

	const [filterModal, setFilterModal] = useState(false);
	const [reports, setReports] = useState([]);
	const [user, setUser] = useState({});

	const [permissionExcel, setPermissionExcel] = useState(false);
	const [permissionPdf, setPermissionPdf] = useState(false);
	const [permissionRead, setPermissionRead] = useState(false);

	const [callReports, reqReports] = useGetCall("/reports/worked_rest_day", {
		onCompleted: (response) => {
			setLoading(false);
			setReports(response.data);
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "worked_rest_day_reports", "reportes de descansos trabajados", "read");
		}
	})

	const setFilters = (values) => {
		setSearchFilters(values);
		callReports(values);
	}

	const showFilterModal = () => setFilterModal(true);
	const hideFilterModal = () => setFilterModal(false);

	const generateCells = (format = "csv") => {

		let csv = [];

		reports.forEach((report) => {

			let employee = report.employee;
			if(employee.leave && moment.utc(employee.leave).startOf('day') < moment.utc(searchFilters.start_date).startOf('day')) return;

			csv.push([
				`ID:`,
				employee.key,
				`Nombre:`,
				`${employee.firstname} ${employee.dad_lastname} ${employee.mom_lastname}`,
				`Puesto:`,
				employee.job.abbreviation,
				`Departamento:`,
				employee.department.abbreviation
			])

			csv.push([
				`Fecha`,
				`Día`,
				`Horario`,
				`Entrada`,
				`Salida Descanso`,
				`Entrada Descanso`,
				`Salida`,
				`Resumen de Incidencias`
			]);

			report.reports.forEach((incidence) => {

				csv.push([
					`${formatISODate(incidence.date, true)}`,
					`${getWeekDayName(incidence.week_day)}`,
					`${function () {
						const schedule = JSON.parse(incidence.schedule);
						if (schedule) {
							if (schedule.has_break) {
								return "Descanso";
							} else {
								return `${schedule?.entry_start.substring(0, 5)} - ${schedule?.exit_start.substring(0, 5)}`;
							}
						} else {
							return "";
						}
					}()
					}`,
					`${incidence.entry ? incidence.entry.substring(0, 5) : "-"}`,
					`${incidence.exit_break ? incidence.exit_break.substring(0, 5) : "-"}`,
					`${incidence.return_break ? incidence.return_break.substring(0, 5) : "-"}`,
					`${incidence.exit ? incidence.exit.substring(0, 5) : "-"}`,
					(format === "csv"
						? `${'"' + JSON.parse(incidence.incidences).map((incidenceName) => (getNameIncidence(incidenceName.incidence))).join("\n") + '"'}`
						: `${JSON.parse(incidence.incidences).map((incidenceName) => (getNameIncidence(incidenceName.incidence))).join("\n")}`
					)
				]);

			});

			csv.push(["", "", "", "", "", "", "Total", `Total descansos trabajados: ${report.reports.length}`]);

			csv.push([]);
			csv.push([]);

		});

		return csv;

	}

	const generateColumnCells = () => {

		let csv = [], idx = 1, index = 1, idx_join = 9;
		const cells_join = [];

		csv.push([
			{value: `No.`, color: "#0170c2"},
			{value: `Id empleado`, color: "#0170c2"},
			{value: `Nombre empleado`, color: "#0170c2"},
			{value: `Centro de costos`, color: "#0170c2"},
			{value: `Turno`, color: "#0170c2"},
			{value: `Día semana`, color: "#0170c2"},
			{value: `Fecha`, color: "#0170c2"},
			{value: `Horario`, color: "#0170c2"},
			{value: `Entrada`, color: "#0170c2"},
			{value: `S. Descanso`, color: "#0170c2"},
			{value: `E. Descanso`, color: "#0170c2"},
			{value: `Salida`, color: "#0170c2"},
			{value: `Resumen de incidencias`, color: "#0170c2"},
		]);

		reports.forEach((report) => {

			let employee = report.employee;
			if(employee.leave && moment.utc(employee.leave).startOf('day') < moment.utc(searchFilters.start_date).startOf('day')) return;

			csv.push([
				{value: `${index} // ID: ${employee.key} // Nombre: ${employee.firstname} ${employee.dad_lastname} ${employee.mom_lastname} // Nómina: ${employee.payroll.description}` +
				` // Puesto: ${employee.job.description} // Departamento: ${employee.department.description}`, color: "#4b83be"}
			])

			cells_join.push(`A${idx_join}:M${idx_join}`)
			idx_join += report.reports.length + 2;

			report.reports.forEach((incidence) => {

				csv.push([
					idx.toString(),
					employee.key,
					`${employee.dad_lastname} ${employee.mom_lastname} ${employee.firstname}`,
					employee.cost_center?.description ?? '',
					employee.shift?.description ?? '',
					`${getWeekDayName(incidence.week_day)}`,
					`${formatISODate(incidence.date, true)}`,
					`${function () {

						if(!incidence.schedule || incidence.schedule == "") return "";

						const schedule = JSON.parse(incidence.schedule);
						if(schedule) {
							if (schedule.has_break) return "Descanso";
							return `${schedule?.entry_start.substring(0, 5)} - ${schedule?.exit_start.substring(0, 5)}`;
						}

						return "";

					}()}`,
					`${incidence.entry ? incidence.entry : "-"}`,
					`${incidence.exit_break ? incidence.exit_break : "-"}`,
					`${incidence.return_break ? incidence.return_break : "-"}`,
					`${incidence.exit ? incidence.exit : "-"}`,
					JSON.parse(incidence.incidences).map((incidenceName) => (getNameIncidence(incidenceName.incidence))).join("\n")
				]);

				idx += 1;
			});

			csv.push(["", "", "", "", "", "", "", "", "", "", "", "", `Total descansos trabajados: ${report.reports.length}`]);
			index += 1;

		});

		return [csv, cells_join];

	}

	const onDownloadCSV = () => {
		downloadCSV({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells(),
			report: "descansos trabajados",
		});
	}

	const onDownloadXlsx = async () => {
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells("xlsx"),
			report: "descansos trabajados",
		})
	}

	const onDownloadXlsxColumns = async () => {

		const [cells, cells_join] = generateColumnCells();
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: cells,
			report: "descansos trabajados",
			cells_to_join: cells_join
		})
	}

	const onChangeSearch = (event) => {
		if (event.target.value === '') {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
	}, [id]);

	useEffect(() => {
		fetchPost("/permissions/check", { table: "WORKED_REST_DAYS_REPORT", permission: "excel" }).then((response) => {
			if (response.data) setPermissionExcel(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "WORKED_REST_DAYS_REPORT", permission: "pdf" }).then((response) => {
			if (response.data) setPermissionPdf(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "WORKED_REST_DAYS_REPORT", permission: "read" }).then((response) => {
			if (response.data) {
				setPermissionRead(response.data.isAllowed)
				if (response.data.isAllowed) {
					setFilterModal(tab ? tab.active : false)
				} else {
					if (!toast.isActive(`toast-read-worked_rest_day_reports-unauthorized`)) {
						toast.error(`Error, no tienes los permisos para consultar reportes de descansos trabajados`, { toastId: `toast-read-worked_rest_day_reports-unauthorized` })
					}
				}
			}
		});
	}, []);

	if (loading) return <Loading />;

	return (
		<View
			user={user}
			permissionExcel={permissionExcel}
			permissionPdf={permissionPdf}
			permissionRead={permissionRead}
			setLoading={setLoading}
			onDownloadXlsx={onDownloadXlsx}
			onDownloadXlsxColumns={onDownloadXlsxColumns}
			onDownloadCSV={onDownloadCSV}
			reportRef={reportRef}
			reports={reports}
			filterModal={filterModal}
			filters={searchFilters}
			onChangeSearch={onChangeSearch}
			handleKeyUp={handleKeyUp}
			setFilters={setFilters}
			showFilterModal={showFilterModal}
			hideFilterModal={hideFilterModal}
		/>
	)

}

export default WorkedRestDayReport;