import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import View from "components/crh/processes/List.view";
import { useSocketIO } from "utils/io";
import { getProcesses } from "api/processes";

function List({ refresh }) {
  
  const token = sessionStorage.getItem("token");
  const [processes, setProcesses] = useState([]);
  const [progressPercentages, setProgressPercentages] = useState({});
  const [refreshValue, setRefreshValue] = useState(null);
  const { data } = useSocketIO({ query: { token, type: "PROCESS" } });

  if(refresh !== refreshValue) setRefreshValue(refresh);

	const [callProcesses, reqProcesses] = useGetCall("/processes", {
		onCompleted: (response) => {
			setProcesses(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "processes", "procesos", "read");
		}
	})

  useEffect(() => {
    
    if(data.process && !progressPercentages[data.process.progress]) {

      setProcesses((processes) => {
  
        let added = false;
        let newProcesses = processes.map((process) => {
          if(process.id === data.process.id) {
            added = true;
            return data.process;
          }
          return process;
        });
  
        if(!added) newProcesses = [data.process, ...newProcesses];
  
        return newProcesses;
  
      });
  
      setProgressPercentages({ ...progressPercentages, [data.process.progress]: true });
  
    }

  }, [data])

  useEffect(() => {
    callProcesses({});
  }, [refresh])

  return <View 
    processes={processes}
  />;

}

export default List;