import React from "react";
import Modal from "helpers/Modal";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import moment from "moment";
const format = 'HH:mm';

function ProfilesListView({
	requests = [],
	addEditModal = () => { },
}) {
	return (<>
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Folio</th>
							<th>Nombre visitante</th>
							<th>A quién visita</th>
							<th>Perfil</th>
							<th>Placas</th>
							<th>Marca</th>
							<th>Color</th>
							<th>Vigencia</th>
							<th>Estatus</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							requests.map((request) => (
								<tr key={request.id} role="row">
									<td>{request.folio}</td>
									<td>{request.name}</td>
									<td>{request.host}</td>
									<td>{request?.visit_profile?.description}</td>
									<td>{request.plates}</td>
									<td>{request?.brand?.key}</td>
									<td>{request?.color?.key}</td>
									<td>
										{
											function(){
												const startDate = moment.utc(`${request?.start_date} ${request?.start_hour}`);
												const endDate = moment.utc(`${request?.end_date} ${request?.end_hour}`);
												return `${startDate.format("DD/MM/YYYY HH:mm")} - ${endDate.format("DD/MM/YYYY HH:mm")}`
												// const diffDays = moment.utc(`${request?.end_date}`).diff(moment.utc(`${request?.start_date}`), 'days');
												// const diffHours = moment.utc(`${request?.end_date} ${request?.end_hour}`).diff(moment.utc(`${request?.end_date} ${request?.start_hour}`), 'hours');
												// if(diffHours > 0 && diffDays > 0) return `${diffDays} días ${diffHours} horas`
												// else if(diffHours > 0) return `${diffHours} horas`
												// else return `${diffDays} días`
											}()
										}
									</td>
									<td>
										{
											function(){
												const endDate = moment.utc(`${request?.end_date} ${request?.end_hour}`);
												const currentDate = moment.utc();
												if(endDate.isBefore(currentDate)) return "Vencida"
												else return "Vigente"
											}()
										}
									</td>
									<td>
										<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => addEditModal(request.id)}>
											<i class="bi bi-pencil"></i>
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

	</>);
}

export default ProfilesListView;