import React from "react";
import ShiftList from "components/crh/timetables/List";
import Modal from "helpers/Modal";

function TimetablesView({
    search,
    onChangeSearch,
    handleKeyUp,
    addEditModal,
    modalTitleEdit,
    isVisibleEdit,
    setIsVisibleEdit,
    modalContentEdit
}) {
    return (
        <>
            <div className="content container-fluid p-5">

                <div className="page-header mb-3">
                    <div className="row align-items-end">
                        <div className="col-sm mb-2 mb-sm-0">
                            <h1 className="page-header-title">Turnos - Horarios</h1>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header card-header-content-md-between">
                        <div class="mb-2 mb-md-0">
                            <div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
                                <div class="input-group-prepend input-group-text" >
                                    <i class="bi-search"></i>
                                </div>
                                <input
                                    type="search"
                                    class="form-control"
                                    placeholder="Buscar turno"
                                    onChange={onChangeSearch}
                                    onKeyUp={handleKeyUp}
                                />
                            </div>
                        </div>
                    </div>

                    <ShiftList
                        search={search}
                        refresh={Math.random()}
                        addEditModal={addEditModal}
                    />

                </div>
            </div>

            <Modal
                title={modalTitleEdit}
                isVisible={isVisibleEdit}
                setIsVisible={setIsVisibleEdit}
            >
                {modalContentEdit}
            </Modal>

        </>
    );

}

export default TimetablesView;