import React, { useState, useEffect } from 'react';
import { validateAuth } from "api/auth";
import { IS_TEST } from "settings";
import View from "components/layout/LayoutGeo.view";

function LayoutGeo() {

	let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const [theme, setTheme] = useState(savedLayout ? savedLayout.theme : "light");
	const [button, setButton] = useState(savedLayout ? savedLayout.button : false);

	useEffect(() => {
		validateAuth({
			id: sessionStorage.getItem("id"),
			type: sessionStorage.getItem("type"),

		}, "GEO").then(response => {
			// if(!response)
				// window.location.href = (IS_TEST ? "/test" : "") + "/geo/login";
		})
	}, []);

	return (
		<View
			theme={theme}
			setTheme={setTheme}
			button={button}
			setButton={setButton}
		/>
	)

}

export default LayoutGeo;