import { API_URL } from "settings";
import { getUrlencoded } from "utils/api";

function getUser(id) {
    const url = `${API_URL}/users/detail/${id}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

function getUsers(query = {}) {
    const url = `${API_URL}/users?${getUrlencoded(query)}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

function getAdminUser(id) {
    const url = `${API_URL}/users/admin/${id}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

function getAdminUsers(query = {}) {
    const url = `${API_URL}/users/admin?${getUrlencoded(query)}`;
    const params = {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
}

export { getUser, getUsers, getAdminUser, getAdminUsers };
