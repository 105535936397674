import React from "react";
import NavBar from "components/nav/Navbar";
import Tabs from 'components/tabs/Tabs';
import "resources/styles/Layout.scss";
import GeoSideBar from "components/nav/GeoSidebar";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import GeoAttendance from "components/geo/attendance/Form";
import GeoAttendanceLogs from "components/geo/attendance/List";
import IncidenceRequests from "components/geo/incidenceRequests/Form";
import IncidencesList from "components/geo/incidenceRequests/List"

function LayoutGeoView({
    tabs,
    onAddTab,
    button,
    setTabs,
    setButton,
    theme,
    setTheme,
}) {
    return (
        <div>
            <BrowserRouter basename="/geo">
            
                <NavBar onAddTab={onAddTab} button={button} setButton={setButton} theme={theme} setTheme={setTheme} />
                <GeoSideBar
                    onAddTab={onAddTab}
                    theme={theme}
                    button={button}
                    setButton={setButton}
                />

                <Switch>
                    <Route path="/attendance" component={GeoAttendance} />
                    <Route path="/records" component={GeoAttendanceLogs} />
                    <Route path="/requests" component={IncidencesList} />
                    <Route path="/incidence_request" component={IncidenceRequests} />
                    <Redirect to="/attendance" />
                </Switch>

            </BrowserRouter>
        </div>
    )
}

export default LayoutGeoView;