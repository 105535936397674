import React from "react";

const PaginationFooter = ({ pageNum, totalPages, onClickPage }) => {

  const maxVisiblePages = 8;

  const getVisiblePages = (page, total) => {

    const pages = [];
    const half = Math.floor((maxVisiblePages - 4) / 2);

    if(total <= maxVisiblePages) {
      for (let i = 1; i <= total; i++) 
        pages.push(i);
    }
    else {

      pages.push(1);

      let start = Math.max(2, page - half);
      let end = Math.min(total - 1, page + half);

      if(page <= half + 2)
        end = maxVisiblePages - 2;
      else if (page >= total - (half + 1))
        start = total - (maxVisiblePages - 3);

      if(start > 2) 
        pages.push("...");

      for (let i = start; i <= end; i++) 
        pages.push(i);

      if(end < total - 1) 
        pages.push("...");

      pages.push(total);

    }

    return pages;

  };

  const visiblePages = getVisiblePages(pageNum, totalPages);

  return (
    <nav className="mt-3">
      <ul className="pagination">
        <li
          style={{ cursor: "pointer" }}
          onClick={() => onClickPage(pageNum - 1)}
          className={`page-item ${pageNum <= 1 ? "disabled" : ""}`}
        >
          <a className="page-link" aria-label="Previous" style={{ cursor: "pointer" }}>
            <span aria-hidden="true">«</span>
          </a>
        </li>
        {visiblePages.map((page, idx) => (
          <li
          style={{ cursor: "pointer" }}
            key={idx}
            onClick={() => typeof page === "number" && onClickPage(page)}
            className={`page-item ${pageNum === page ? "active" : ""} ${
              page === "..." ? "disabled" : ""
            }`}
          >
            <a className="page-link" style={{ cursor: "pointer" }}>{page}</a>
          </li>
        ))}
        <li
          style={{ cursor: "pointer" }}
          onClick={() => onClickPage(pageNum + 1)}
          className={`page-item ${pageNum >= totalPages ? "disabled" : ""}`}
        >
          <a className="page-link" aria-label="Next" style={{ cursor: "pointer" }}>
            <span aria-hidden="true">»</span>
          </a>
        </li>
      </ul>
    </nav>
  );

}

export default PaginationFooter;