import React, { useState } from "react";
import Filters from "components/crh/incidencesAuth/Filters";
import RequestForm from "components/crh/incidencesAuth/RequestForm";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/incidencesAuth/IncidencesAuth.view";

function IncidencesAuth() {

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [filters, setFilters] = useState({});

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalTitleEdit, setModalTitleEdit] = useState("");
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const addModal = () => {
    fetchPost("/permissions/check", { table: "AUTH_INCIDENCE_REQUESTS", permission: "read" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Filtros de búsqueda");
          setModalContent(
            <Filters
              setIsVisible={setIsVisible}
              refresh={Math.random()}
              setFilters={setFilters}
            />
          );
        } else {
          if (!toast.isActive("toast-read-incidences_request-unauthorized")) {
            toast.error("Error, no tienes los permisos para mostrar solicitudes de incidencias", { toastId: "toast-read-incidences_request-unauthorized" })
          }
        }
      }
    })
  }

  const showEditModal = (id) => {
    fetchPost("/permissions/check", { table: "INCIDENCES", permission: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleEdit(true);
          setModalTitleEdit("Editar solicitud de incidencia");
          setModalContentEdit(
            <RequestForm
              id={id}
              refresh={Math.random()}
              setIsVisible={setIsVisibleEdit}
            />
          );
        } else {
          if (!toast.isActive("toast-edit-incidences_request-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar solicitudes de incidencias", { toastId: "toast-create-incidences_request-unauthorized" })
          }
        }
      }
    })

  }

  return (
    <View
      filters={filters}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalTitle={modalTitle}
      modalContent={modalContent}
      addModal={addModal}
      showEditModal={showEditModal}
      setIsVisibleEdit={setIsVisibleEdit}
      modalTitleEdit={modalTitleEdit}
      modalContentEdit={modalContentEdit}
      isVisibleEdit={isVisibleEdit}
    />
  );
}

export default IncidencesAuth;