import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { getContracts } from "api/contracts";
import { showResponseMessage } from "utils/message";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/contracts/List.view";

function List({
	addEditJobModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [contracts, setContracts] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callContracts, reqContracts] = useGetCall("/contracts", {
		onCompleted: (response) => {
			setLoading(false);
			setContracts(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "contracts", "contratos", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/contracts", {
		onCompleted: () => {
			if (!toast.isActive("contract-deleted"))
				toast.success("Contrato eliminado", { toastId: "contract-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "contracts", "contratos", "delete")
		}
	});

	useEffect(() => {
		setLoading(true);
		callContracts({ search });
	}, [refreshDelete, refreshValue, search]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "CONTRACT", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el contrato?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-contract-unauthorized")) {
						toast.error("Error, no tienes los permisos para contratos", { toastId: "toast-delete-contract-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditJobModal(id);
	}

	if (loading) return <Loading />

	return (
		<View contracts={contracts} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;