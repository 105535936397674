import React, { useState, useEffect, useRef } from "react"
import { usePut, usePost } from "utils/api"
import { getDevice, testConnection } from "api/devices"
import { getOffices } from "api/offices"
import { toast } from "react-toastify"
import View from "components/crh/devices/Form.view"
import { useMap, useMapEvent } from "react-leaflet"

function FormSet({ id, refresh, company, setIsVisible }) {

    const [refreshValue, setRefreshValue] = useState(null)
    const formikRef = useRef()
    const [error, setError] = useState(null)
    const [device, setDevice] = useState({})
    const [offices, setOffices] = useState([])
    const [connected, setConnected] = useState(null)
    const [coordinates, setCoordinates] = useState({})
    const [locations, setLocations] = useState([])

    const [callDecode, reqDecode] = usePost("/geolocation/decode", {
        onCompleted: (data) => {
            setLocations(data.data)
            if(data.data.length === 1) {
                formikRef.current.setFieldValue("location", data.data[0].formatted_address || "")
            }
        },
        onError: (err) => {}
    })

    const [callEncode, reqEncode] = usePost("/geolocation/encode", {
        onCompleted: (data) => {
            setLocations(data.data)
            if(data.data.length === 1) {
                setCoordinates(data.data[0].geometry.location)
            }
        },
        onError: (err) => {}
    })

    if(refresh !== refreshValue) setRefreshValue(refresh)

    const callGetCoordinates = async (address) => callEncode({ address })

    const callTestConnection = async (serial_number) => {
        const response = await testConnection(serial_number)
        if (response != null && response.errors.length === 0 && response.data.connected) {
            toast.success("Conexión exitosa", { toastId: "toast-test-connection" })
            setConnected(true)
        }
        else {
            toast.error("Error al conectar", { toastId: "toast-test-connection" })
            setConnected(false)
        }
    }

    const [callUpdate, reqUpdate] = usePut("/devices", {
        onCompleted: () => {

            if (!toast.isActive("toast-update-device"))
                toast.success("Dispositivo actualizado", { toastId: "toast-update-device" })

            setIsVisible(false)
            formikRef.current.setValues({})

        },
        onError: (err) => {
            if (!toast.isActive("toast-update-device-error") && err.status === 500)
                toast.error("Error interno del servidor", { toastId: "toast-update-device-error" })
            else if (!toast.isActive("toast-save-device-error") && err.status === 409)
                toast.error("Ya existe un dispositivo con ese número de serie", { toastId: "toast-save-device-error" })
            else if (!toast.isActive("toast-update-device-unauthorized") && err.status === 403)
                toast.error("Error, no tienes los permisos para editar dispositivos", { toastId: "toast-update-device-unauthorized" })
        }
    })

    useEffect(() => {
        getOffices({ company }).then((data) => {
            if (data.errors.length > 0) {
                if (!toast.isActive("toast-offices-eror"))
                    toast.error("Error al obtener las sucursales", { toastId: "toast-offices-eror" })
            }
            else {
                setOffices(data.data)
            }
        })
    }, [id, refreshValue])

    useEffect(() => {
        getDevice(id).then((data) => {
            if(formikRef.current) formikRef.current.setValues(data.data)
            setDevice(data.data)
            setCoordinates({ lat: data.data.lat, lng: data.data.lng })
        })
        formikRef.current.setValues({})
    }, [id, refreshValue])

    const onSubmit = (values) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { serial_number } = values

        if(!serial_number) {
            toast.error("Todos los campos son obligatorios")
            return
        }

        if(!coordinates.lat || !coordinates.lng) {
            toast.error("Revise la dirección ingresada aparezca en el mapa")
            return
        }

        values.id = id
        callUpdate({ ...values, company, coordinates })

    }

    const onCancel = (resetForm) => {
        setIsVisible(false)
        resetForm({})
    }

    const RecenterAutomatically = ({ lat, lng }) => {

        const map = useMap();
        const events = useMapEvent({
            moveend: () => {},
            click: (e) => {
                setCoordinates(e.latlng)
                callDecode({ lat: e.latlng.lat, lng: e.latlng.lng })
            },
        });

        useEffect(() => { map.setView([lat, lng]); }, [lat, lng]);

        return null;
    }

    return (
        <View
            offices={offices}
            formikRef={formikRef}
            error={error}
            edit={true}
            device={device}
            onSubmit={onSubmit}
            onCancel={onCancel}
            callTestConnection={callTestConnection}
            callGetCoordinates={callGetCoordinates}
            setCoordinates={setCoordinates}
            coordinates={coordinates}
            locations={locations}
            connected={connected}
            RecenterAutomatically={RecenterAutomatically}
        />
    )

}

export default FormSet
