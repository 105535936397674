import React from "react";
import { Field, Formik, Form } from "formik";

function ContactFormView({ formikRef, onSubmit, onCancel }) {

    return (
        <div className='container mt-3' id="contact_form" style={{ padding: "0 2rem 2rem" }}>

            <div className="d-flex flex-row-reverse">
                <i class="bi bi-x-circle" style={{ fontSize: "2rem", cursor: "pointer", color: "#1aa9d1" }}
                    onClick={onCancel}></i>
            </div>

            <div className="row w-100">

                <div className="col-md-6">

                    <h3 className="mb-5" style={{ color: "#081b21", fontWeight: "bold" }}>
                        Aprende cómo podemos ayudarte a tomar el <span style={{ color: "#1aa9d0" }}>control</span> de tu personal
                    </h3>

                    <Formik
                        innerRef={formikRef}
                        initialValues={{}}
                        onSubmit={onSubmit}>
                        {({ values, setFieldValue }) =>
                            <Form>

                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <label class="input">
                                            <Field
                                                type="text"
                                                name="firstname"
                                                id="first_name"
                                                className="form-control input__field"
                                                tabindex="1"
                                                placeholder=" "
                                                required
                                                autofocus="1"
                                            />
                                            <span class="input__label">
                                                Nombre <span className='text-danger fw-bold'>*</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label class="input">
                                            <Field
                                                type="text"
                                                name="lastname"
                                                className="form-control input__field"
                                                tabindex="1"
                                                placeholder=" "
                                                autofocus="1"
                                            />
                                            <span class="input__label">
                                                Apellidos
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label class="input">
                                        <Field
                                            type="text"
                                            name="phone"
                                            className="form-control input__field"
                                            tabindex="1"
                                            placeholder=" "
                                            autofocus="1"
                                        />
                                        <span class="input__label">
                                            Teléfono
                                        </span>
                                    </label>
                                </div>

                                <div className="mb-4">
                                    <label class="input">
                                        <Field
                                            type="email"
                                            name="email"
                                            className="form-control input__field"
                                            tabindex="1"
                                            placeholder=" "
                                            required
                                            autofocus="1"
                                        />
                                        <span class="input__label">
                                            Correo electrónico <span className='text-danger fw-bold'>*</span>
                                        </span>
                                    </label>
                                </div>

                                <div className="mb-4">
                                    <label class="input">
                                        <Field
                                            type="text"
                                            name="employees"
                                            className="form-control input__field"
                                            tabindex="1"
                                            placeholder=" "
                                            autofocus="1"
                                        />
                                        <span class="input__label">
                                            Número de empleados
                                        </span>
                                    </label>
                                </div>

                                <div className="mb-4">
                                    <label class="input">
                                        <Field
                                            as="textarea"
                                            name="comments"
                                            rows="3"
                                            className="form-control input__field"
                                            tabindex="1"
                                            placeholder=" "
                                            autofocus="1"
                                        />
                                        <span class="input__label">
                                            Comentarios
                                        </span>
                                    </label>
                                </div>

                                <div class="pt-3">
                                    <button type="submit" class="d-flex justify-content-between btn btn-primary btn-custom-light w-100" style={{ padding: "1rem 2rem" }}>
                                        <h5>Enviar</h5>
                                        <div class="bg-white" style={{ width: "2rem", height: "2rem", position: "unset", borderRadius: "50%" }}>
                                            <img src="assets/icons/arrow.svg" style={{ width: "2rem", height: "2rem", position: "unset" }} alt="" />
                                        </div>
                                    </button>
                                </div>

                            </Form>
                        }</Formik>

                </div>

                <div className="col-md-6">
                    <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100%" }}>
                        <img src="assets/img/lector_control.png" alt="Lector" width="130%" />
                    </div>
                </div>

            </div>



        </div >
    );
}

export default ContactFormView;