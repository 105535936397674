import React, { useState, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { permissionTablesArray, permissionTypes } from "utils/constants";
import { showResponseMessage } from "utils/message";
import View from "components/crh/permissions/Form.view";

function FormSave({ setIsVisible }) {

    const formikRef = useRef(null);
    const [permissions, setPermissions] = useState(permissionTablesArray.map((table) => {
        let types = {};
        permissionTypes.forEach((pType) => {
            types[pType] = false;
        });
        return {
            table: table,
            permissions: types
        }
    }));

    const [callSave, reqSave] = usePost("/permissions", {
        onCompleted: () => {
            if (!toast.isActive("permission-created"))
                toast.success("Perfil de permisos creado correctamente", { toastId: "permission-created" });

            formikRef.current.setValues({});
            setPermissions(permissionTablesArray.map((table) => {
                let types = {};
                permissionTypes.forEach((pType) => {
                    types[pType] = false;
                });
                return {
                    table: table,
                    permissions: types
                }
            }));
            setIsVisible(false);

        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(err.status, "permissions", "permisos", "create");
        }
    })



    const onChangePermission = (table, permission) => {

        let newPermissions = [...permissions];
        const idxTableItem = newPermissions.findIndex((item) => item.table === table);
        if (idxTableItem === -1) return;

        if (permission === "access") {
            const prevValue = newPermissions[idxTableItem].permissions[permission];
            permissionTypes.forEach((type) => {
                newPermissions[idxTableItem].permissions[type] = !prevValue;
            })
        } else {
            const prevValue = newPermissions[idxTableItem].permissions[permission];
            newPermissions[idxTableItem].permissions[permission] = !prevValue;
        }

        setPermissions([...newPermissions])

    }

    const onSubmit = (values) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        values.data = permissions;

        const { key, description, observation } = values
        if (!key || !description || !observation) {
            toast.error("Rellene los campos obligatorios");
            return;
        }

        callSave(values);

    }

    const onCancel = () => {

        formikRef.current.setValues({});
        setPermissions(permissionTablesArray.map((table) => {
            let types = {};
            permissionTypes.forEach((pType) => {
                types[pType] = false;
            });
            return {
                table: table,
                permissions: types
            }
        }));
        setIsVisible(false);
    }


    return (
        <View
            formikRef={formikRef}
            permissions={permissions}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onChangePermission={onChangePermission}
            setIsVisible={setIsVisible}
        />
    )
}

export default FormSave;