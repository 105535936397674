import React, { useEffect, useState, useRef } from "react";
import View from "components/crh/reports/attendance/Attendance.view";
import { getParameters } from "api/companies";
import { toast } from "react-toastify";
import { getUser } from "api/users";
import moment from "moment/moment";
import Loading from "helpers/Loading";
import { formatISODate } from "utils/date";
import { showResponseMessage } from "utils/message";
import { useGetCall, fetchPost, usePost } from "utils/api";
import { downloadCSV, downloadExcel } from "utils/files";

function Attendance() {

  let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
  const tab = savedLayout.tabs.find((item) => item.content === "Asis. registrados");

  const reportRef = useRef(null);
  const id = sessionStorage.getItem("id");
  const [searchFilters, setSearchFilters] = useState({
    search: "",
    start_date: "",
    end_date: "",
    start_hour: "",
    end_hour: "",
    serial_number: "",
    key: "",
    job_id: "",
    department_id: "",
    group_id: "",
    payroll_id: "",
    office_id: "",
    attendance_type: "LECTOR",
  });
  const [filterModal, setFilterModal] = useState(false);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [officeDetails, setOfficeDetails] = useState({});
  const [statusDetails, setStatusDetails] = useState({});
  const [parameters, setParameters] = useState({});
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false)
  const [detailsData, setDetailsData] = useState({})

  const [permissionExcel, setPermissionExcel] = useState(false);
  const [permissionPdf, setPermissionPdf] = useState(false);
  const [permissionRead, setPermissionRead] = useState(false);

  const [callAttendanceLogs, reqAttendanceLogs] = useGetCall("/attendance_logs", {
    onCompleted: (response) => {
      setLoading(false);
      setAttendanceLogs(response.data.attendanceLogs);
      setOfficeDetails(response.data.officeDetails);
      setStatusDetails(response.data.statusDetails);
    },
    onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "attendance_logs", "registros de reportes", "read");
    }
  })

  const [callUpdate, reqUpdate] = usePost("/attendance_logs", {
    onCompleted: () => {
        if(!toast.isActive("toast-update-attendance-log"))
            toast.success("Marcaje actualizado correctamente", { toastId: "toast-update-attendance-log" })
        callAttendanceLogs({ ...searchFilters, registered: true });
    },
    onError: (err) => {
        console.error(err)
        if(err.status === 400) {
            if(!toast.isActive("attendance-log-error-400"))
                toast.error("Campos obligatorios requeridos", { toastId: "attendance-log-error-400" });
        } 
        else if(err.status === 403) {
            if(!toast.isActive("toast-create-attendance-logs-unauthorized"))
                toast.error("Error, no tienes los permisos para actualizar marcajes", { toastId: "toast-create-attendance-logs-unauthorized" })
        } 
        else {
            if(!toast.isActive("attendance-log-error-500"))
                toast.error("Error interno del servidor", { toastId: "attendance-log-error-500" });
        }
    }
  })

  const setFilters = (values) => {
    values.offset = moment().utcOffset();
    setSearchFilters(values);
    callAttendanceLogs({ ...values, registered: true })
  }

  const showModal = () => setFilterModal(true);
  const hideModal = () => setFilterModal(false);

  const generateCells = () => {

    let csv = [];

    attendanceLogs.forEach((report) => {

      let employee = report.employee;
      if(employee.leave && moment.utc(employee.leave).startOf('day') < moment.utc(searchFilters.start_date).startOf('day')) return;
      if(report.attendanceLogs.length === 0) return;

      csv.push([
        `ID:`,
        employee.key,
        `Nombre:`,
        `${employee.firstname} ${employee.dad_lastname} ${employee.mom_lastname}`,
        `Sucursal:`,
        employee.office.abbreviation,
        `Departamento:`,
        employee.department.abbreviation,
        `Puesto:`,
        employee.job.abbreviation
      ])

      if (report.attendanceLogs.length > 0) csv.push([]);

      csv.push([
        `Fecha`,
        `Descripción`,
        `Hora`,
        `Estatus`,
      ]);

      report.attendanceLogs.forEach((attendanceLog) => {
        csv.push([
          `${formatISODate(attendanceLog.log.date, true)}`,
          `${attendanceLog.device ? attendanceLog.device.description : (attendanceLog.log.location ?? ("Geolocalización " + attendanceLog.log.lat + " " + attendanceLog.log.lng))}`,
          `${attendanceLog.log.time}`,
          function () {
            if (attendanceLog.log.status === "CREATED") return "Sin procesar"
            else if (attendanceLog.log.status === "PROCESSED") return "Procesado"
            else if (attendanceLog.log.status === "DUPLICATED") return "Duplicado"
            else if (attendanceLog.log.status === "OUT_OF_RANGE") return "Fuera de rango"
          }()
        ]);
      });


      csv.push([]);
      csv.push([]);

    });

    return csv;

  }

  const generateColumnCells = () => {

    let csv = [], idx = 1, index = 1, idx_join = 9;
    const cells_join = [];

    csv.push([
      { value: `No.`, color: "#0170c2" },
      { value: `Id empleado`, color: "#0170c2" },
      { value: `Nombre empleado`, color: "#0170c2" },
      { value: `Centro de costos`, color: "#0170c2" },
      { value: `Turno`, color: "#0170c2" },
      { value: `Fecha`, color: "#0170c2" },
      { value: `Descripción`, color: "#0170c2" },
      { value: `Hora`, color: "#0170c2" },
      { value: `Estatus`, color: "#0170c2" }
    ]);

    attendanceLogs.forEach((report) => {

      let employee = report.employee;

      if (report.attendanceLogs.length > 0) {
        csv.push([
          {
            value: `${index} // ID: ${employee.key} // Nombre: ${employee.firstname} ${employee.dad_lastname} ${employee.mom_lastname} // Nómina: ${employee.payroll.description}` +
              ` // Puesto: ${employee.job.description} // Departamento: ${employee.department.description}`, color: "#4b83be"
          }
        ])
        cells_join.push(`A${idx_join}:I${idx_join}`)
        idx_join += report.attendanceLogs.length + 1;
      }

      report.attendanceLogs.forEach((attendanceLog) => {
        csv.push([
          idx.toString(),
          employee.key,
          `${employee.dad_lastname} ${employee.mom_lastname} ${employee.firstname}`,
          employee.cost_center?.description ?? '',
          employee.shift?.description ?? '',
          `${formatISODate(attendanceLog.log.date, true)}`,
          `${attendanceLog.device ? attendanceLog.device.description : (attendanceLog.log.location ?? ("Geolocalización " + attendanceLog.log.lat + " " + attendanceLog.log.lng))}`,
          `${attendanceLog.log.time}`,
          function () {
            if (attendanceLog.log.status === "CREATED") return "Sin procesar"
            else if (attendanceLog.log.status === "PROCESSED") return "Procesado"
            else if (attendanceLog.log.status === "DUPLICATED") return "Duplicado"
            else if (attendanceLog.log.status === "OUT_OF_RANGE") return "Fuera de rango"
          }()
        ]);

        idx += 1;
      });

      index += 1;

    });

    return [csv, cells_join];

  }

  const onDownloadCSV = () => {
    downloadCSV({
      user,
      start_date: searchFilters.start_date,
      end_date: searchFilters.end_date,
      cells: generateCells(),
      report: "marcajes",
    });
  }

  const onDownloadXlsx = async () => {
    downloadExcel({
      user,
      start_date: searchFilters.start_date,
      end_date: searchFilters.end_date,
      cells: generateCells("xlsx"),
      report: "marcajes",
    })
  }

  const onDownloadXlsxColumns = async () => {

    const [cells, cells_join] = generateColumnCells();
    downloadExcel({
      user,
      start_date: searchFilters.start_date,
      end_date: searchFilters.end_date,
      cells: cells,
      report: "marcajes",
      cells_to_join: cells_join
    })
  }

  const changeStatus = (attendanceLog, active) =>
    callUpdate({ attendance_log_id: attendanceLog.id, active });

  useEffect(() => {
    getParameters().then((data) => {
      setParameters(data.data);
    })
    fetchPost("/permissions/check", { table: "ATTENDACE_REGISTER_REPORT", permission: "excel" }).then((response) => {
      if (response.data) setPermissionExcel(response.data.isAllowed);
    });
    fetchPost("/permissions/check", { table: "ATTENDACE_REGISTER_REPORT", permission: "pdf" }).then((response) => {
      if (response.data) setPermissionPdf(response.data.isAllowed);
    });
    fetchPost("/permissions/check", { table: "ATTENDACE_REGISTER_REPORT", permission: "read" }).then((response) => {
      if (response.data) {
        setPermissionRead(response.data.isAllowed)
        if (response.data.isAllowed) setFilterModal(tab ? tab.active : false);
      };
    });
  }, []);

  useEffect(() => {
    getUser(id).then((response) => {
      setUser(response.data)
    });
  }, [id]);

  const onChangeSearch = (event) => {
    if(event.target.value === '')
      setFilters({ ...searchFilters, search: event.target.value });
  }

  const handleKeyUp = (event) => {
    if(event.key === "Enter")
      setFilters({ ...searchFilters, search: event.target.value });
  }

  const refreshAttendanceLogs = () =>
    callAttendanceLogs({ ...searchFilters, registered: true });

  if(loading) return <Loading />

  return (
    <View
      user={user}
      isDetailsVisible={isDetailsVisible}
      setIsDetailsVisible={setIsDetailsVisible}
      detailsData={detailsData}
      setDetailsData={setDetailsData}
      permissionExcel={permissionExcel}
      permissionPdf={permissionPdf}
      permissionRead={permissionRead}
      setLoading={setLoading}
      searchFilters={searchFilters}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      onDownloadCSV={onDownloadCSV}
      onDownloadXlsx={onDownloadXlsx}
      onDownloadXlsxColumns={onDownloadXlsxColumns}
      reportRef={reportRef}
      attendanceLogs={attendanceLogs}
      filters={searchFilters}
      setFilters={setFilters}
      hideModal={hideModal}
      showModal={showModal}
      setFilterModal={setFilterModal}
      filterModal={filterModal}
      officeDetails={officeDetails}
      statusDetails={statusDetails}
      parameters={parameters}
      changeStatus={changeStatus}
      refreshAttendanceLogs={refreshAttendanceLogs}
    />
  );

}

export default Attendance;