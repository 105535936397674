import React from "react";
import RegimeList from "components/crh/regimes/List";
import Modal from "helpers/Modal";

function RegimesView({
	search,
	onChangeSearch,
	handleKeyUp,
	isVisible,
	setIsVisible,
	modalTitle,
	modalContent,
	addJobModal,
	addEditJobModal,
	modalTitleEdit,
	isVisibleEdit,
	setIsVisibleEdit,
	modalContentEdit
}) {
	return (
		<>
			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">

						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Régimen</h1>
						</div>

						<div className="col-sm-auto">
							<button type="button" className="btn btn-primary" onClick={addJobModal}>
								<i className="bi bi-plus-circle" style={{marginRight: "7px"}}></i>
								Agregar régimen
							</button>
						</div>
					</div>
				</div>		

				<div class="card">
					<div class="card-header card-header-content-md-between">
						<div class="mb-2 mb-md-0">
							<div class="input-group input-group-merge input-group-flush" style={{width: "50%"}}>
								<div class="input-group-prepend input-group-text" >
									<i class="bi-search"></i>
								</div>
								<input 
									type="search" 
									class="form-control" 
									placeholder="Buscar régimen" 
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
								/>
							</div>
						</div>
					</div>

					<RegimeList
						search={search}
						refresh={Math.random()}
						addEditJobModal={addEditJobModal}
					/>

				</div>
			</div>

			<Modal
				title={modalTitle}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				{modalContent}
			</Modal>

			<Modal
				title={modalTitleEdit}
				isVisible={isVisibleEdit}
				setIsVisible={setIsVisibleEdit}
			>
				{modalContentEdit}
			</Modal>

		</>
	);
}

export default RegimesView;