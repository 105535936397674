import React, { useRef, useState, useEffect } from "react";
import FormSave from "components/crh/employees/FormSave";
import FormSet from "components/crh/employees/FormSet";
import View from "components/crh/employees/Employees.view";
import { downloadCSV, downloadExcel } from "utils/files";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import { getUser } from "api/users";


function Employees({ company = {}, theme }) {

  const id = sessionStorage.getItem("id");
  const countRef = useRef(null);
  const employeeListRef = useRef(null);
  const reportRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const [isVisibleLoad, setIsVisibleLoad] = useState(false);

  const [excel, setExcel] = useState(false);
  const [read, setRead] = useState(false);
  const [load, setLoad] = useState(false);
  const [create, setCreate] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const [searchFilters, setSearchFilters] = useState({
    search: "",
    payroll_id: "",
    office_id: "",
    job_id: "",
    department_id: "",
    shift_id: "",
    status: "ACTIVE",
    geolocation: false
  });

  const [user, setUser] = useState({});

  const setFilters = (values) => setSearchFilters(values);
  const showFilterModal = () => setFilterModal(true);
  const hideFilterModal = () => setFilterModal(false);

  const onDownloadCSV = () => {
    const cells = employeeListRef.current.generateCells();
    downloadCSV({
      user,
      cells: cells,
      report: "empleados",
      header: false
    });
  }

  const onDownloadXlsx = async () => {

    fetchPost("/employees/check_permission", { type: "excel" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          const cells = employeeListRef.current.generateCells();
          downloadExcel({
            user,
            cells: cells,
            report: "empleados",
            date: false
          });
          return;
        }
      }

      if (!toast.isActive("toast-excel-employees-unauthorized")) {
        toast.error("Error, no tienes los permisos para descargar el excel", { toastId: "toast-excel-employees-unauthorized" })
      }

    })

  }

  const addEmployeeModal = () => {

    fetchPost("/employees/check_permission", { type: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalContent(
            <FormSave
              company={company.id}
              setIsVisible={setIsVisible}
              refresh={Math.random()}
              theme={theme}
            />
          );
        } else {
          if (!toast.isActive("toast-create-employee-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear empleados", { toastId: "toast-create-employee-unauthorized" })
          }
        }
      }
    })
  }

  const addBulkLoadModal = () => {
    fetchPost("/employees/check_permission", { type: "load" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleLoad(true);
        } else {
          if (!toast.isActive("toast-load-employee-unauthorized")) {
            toast.error("Error, no tienes los permisos para hacer carga masiva empleados", { toastId: "toast-load-employee-unauthorized" })
          }
        }
      }
    })
  }

  const addEditEmployeeModal = (id) => {
    setIsVisibleEdit(true);
    setModalContentEdit(
      <FormSet
        id={id}
        theme={theme}
        company={company.id}
        refresh={Math.random()}
        setIsVisible={setIsVisibleEdit}
      />
    );
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setFilters({ ...searchFilters, search: event.target.value });
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setFilters({ ...searchFilters, search: event.target.value });
    }
  }

  useEffect(() => {
    getUser(id).then((response) => {
      setUser(response.data)
    });

    fetchPost("/permissions/check", { table: "EMPLOYEE", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setCreate(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "EMPLOYEE", permission: "load" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setLoad(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "EMPLOYEE", permission: "read" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setRead(true);
        }
      }
    })

    fetchPost("/permissions/check", { table: "EMPLOYEE", permission: "excel" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setExcel(true);
        }
      }
    })

  }, [id]);

  return (
    <View
      company={company}
      excel={excel}
      read={read}
      load={load}
      create={create}
      user={user}
      reportRef={reportRef}
      onDownloadCSV={onDownloadCSV}
      onDownloadXlsx={onDownloadXlsx}
      employeeListRef={employeeListRef}
      countRef={countRef}
      showFilterModal={showFilterModal}
      hideFilterModal={hideFilterModal}
      filterModal={filterModal}
      setFilterModal={setFilterModal}
      setFilters={setFilters}
      searchFilters={searchFilters}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      modalContent={modalContent}
      addEmployeeModal={addEmployeeModal}
      addEditEmployeeModal={addEditEmployeeModal}
      isVisibleEdit={isVisibleEdit}
      setIsVisibleEdit={setIsVisibleEdit}
      modalContentEdit={modalContentEdit}
      theme={theme}
      isVisibleLoad={isVisibleLoad}
      setIsVisibleLoad={setIsVisibleLoad}
      addBulkLoadModal={addBulkLoadModal}
    />
  );

}

export default Employees;