import React from "react";

function AdminReportView({
	admins,
	onChangeSearch,
	handleKeyUp,
	offices,
	setOffice,
}) {
	return (
		<>
			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Reporte de administradores</h1>
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header">
						<div class="mb-2 mb-md-0 row">
							<div className="col-md-4">
								<div class="input-group input-group-merge input-group-flush">
									<div class="input-group-prepend input-group-text" >
										<i class="bi-search"></i>
									</div>
									<input
										type="search"
										class="form-control"
										placeholder="Buscar empleado"
										onChange={onChangeSearch}
										onKeyUp={handleKeyUp}
									/>
								</div>
							</div>
							<div className='col-md-2 form-group'>
								<label className="input">
									<select className="input__field form-control" onChange={e => setOffice(e.target.value)}>
										<option value="">Sin selección</option>
										{
											offices.map((office) =>
												<option value={office.id}>{office.description}</option>
											)
										}
									</select>
									<span class="input__label">
										Sucursal
									</span>
								</label>
							</div>
						</div>
					</div>

				</div>

				<div class="table-responsive datatable-custom position-relative">
					<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
						<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
							<thead>
								<tr role="row">
									<th>Serie de lector</th>
									<th>Descripción</th>
									<th>ID Usuario</th>
									<th>Nombre</th>
									<th>Huellas</th>
									<th>Rostros</th>
									<th>Contraseña</th>
								</tr>
							</thead>
							<tbody>
								{
									admins && admins.map(admin =>
										admin?.device_employees?.map(deviceEmployee =>
											<tr>
												<td>
													{deviceEmployee.device.serial_number}
												</td>
												<td>
													{deviceEmployee.device.description}
												</td>
												<td>
													{admin.key}
												</td>
												<td>
													{admin.firstname} {admin.dad_lastname} {admin.mom_lastname}
												</td>
												<td>
													{admin.face_count}
												</td>
												<td>
													{admin.fingerprint_count}
												</td>
												<td>
													********
												</td>
											</tr>
										)
									)
								}
							</tbody>
						</table>
					</div>
				</div>

			</div>
		</>
	);
}

export default AdminReportView;