import React from "react";
import Form from "components/crh/parameters/Form";

function ParametersView() {
	return (
		<>
			<div className="content container-fluid p-5">
				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Parámetros generales</h1>
						</div>
					</div>
				</div>
                <Form />
			</div>
		</>
	);
}

export default ParametersView;