import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/deviceManager/Devices.view";

function Devices({ selectedDevices, setSelectedDevices }) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);
	const [devices, setDevices] = useState([])
	const [search, setSearch] = useState("")

	const [callDevices, reqDevices] = useGetCall("/devices", {
		onCompleted: (response) => {
			setLoading(false);
			setDevices(response.data)
		},
		onError: (error) => {
			setLoading(false);
			console.error(error);
			showResponseMessage(error.status, "devices", "dispositivos", "read");
		}
	})

	useEffect(() => {
		setLoading(true);
		callDevices({ search })
	}, [refreshDelete, refreshValue, search])

	const onChangeSearch = (event) => {
		if (event.target.value === '') {
			setSearch(event.target.value);
		}
	}

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			setSearch(event.target.value);
		}
	}

	if (loading) return <Loading />

	return (
		<View
			search={search}
			onChangeSearch={onChangeSearch}
			handleKeyUp={handleKeyUp}
			selectedDevices={selectedDevices}
			setSelectedDevices={setSelectedDevices}
			devices={devices}
		/>
	)

}

export default Devices;