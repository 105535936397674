import React from "react";
import { getNameIncidence } from "utils/constants";

function ListView({ incidences, onDelete, onEdit }) {

	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Clave</th>
							<th>Descripción</th>
							<th>Estatus</th>
							<th>Solicitud RH</th>
							<th>Equivalencia</th>
							<th>Función</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							incidences.map((incidence) => (
								<tr key={incidence.id} role="row">
									<td>{incidence.key}</td>
									<td>{incidence.description}</td>
									<td>{incidence.status ? "Activo" : "Inactivo"}</td>
									<td>{incidence.request_rh ? "Si" : "No"}</td>
									<td>{incidence?.equivalence}</td>
									<td>{getNameIncidence(incidence.type)}</td>
									<td>
										<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(incidence.id)}>
											<i class="bi bi-pencil" style={{ marginRight: "7px" }}></i>
											Editar
										</button>
										<button className="btn btn-danger" onClick={() => onDelete(incidence.id)}>
											<i class="bi bi-trash" style={{ marginRight: "7px" }}></i>
											Eliminar
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>

	);
}

export default ListView;