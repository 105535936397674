import React, { useState } from "react";
import { useGetCall, usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/plannings/Form.view";
import { useEffect } from "react";
import { weekDays } from 'utils/constants';

function FormSave({ refresh, setIsVisible }) {

    const [error, setError] = useState(null);
    
    const [payrollPeriods, setPayrollPeriods] = useState([]);
    const [offices, setOffices] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [departments, setDepartments] = useState([]);
    
    const [employees, setEmployees] = useState([]);
    const [schedules, setSchedules] = useState([]);
    
    const [planning, setPlanning] = useState([]);
    const [planningValues, setPlanningValues] = useState({});
    const [filters, setFilters] = useState({});

    const [visibleModal, setVisibleModal] = useState(null);
    const [refreshValue, setRefreshValue] = useState(null);

    if(refreshValue !== refresh) {
        setRefreshValue(refresh);
    }

    const [callSave, reqSave] = usePost("/plannings", {
        onCompleted: () => {
            
            if (!toast.isActive("planning-created"))
                toast.success("Planeación creada correctamente", { toastId: "planning-created" });

            setIsVisible(false);

        },
        onError: (err) => {
            console.error(err)

            if(err.status == 400) 
                if(!toast.isActive("planning-created")) 
                    toast.error("Completa todos los campos", { toastId: "planning-created" });

            if(err.status == 403)
                if(!toast.isActive("planning-created")) 
                    toast.error("No tienes los permisos para crear planeaciones", { toastId: "planning-created" });

            if(err.status == 404)
                if(!toast.isActive("planning-created")) 
                    toast.error("Error al crear la planeación", { toastId: "planning-created" });

            if(err.status == 409)
                if(!toast.isActive("planning-created"))
                    toast.error("Ya existe una planeación para este periodo", { toastId: "planning-created" });

            if(err.status == 500)
                if(!toast.isActive("planning-created"))
                    toast.error("Error al crear la planeación", { toastId: "planning-created" });

        }
    })

    const [callGetPayrollPeriods, reqPayrollPeriods] = useGetCall("/payroll_periods", {
        onCompleted: (response) => {
            setPayrollPeriods(response.data)
        },
        onError: (error) => {
          toast.error("Error al obtener los periodos de nómina");
        }
    });

    const [callGetEmployees, reqEmployees] = useGetCall("/employees", {
        onCompleted: (response) => {
            setEmployees(response.data)
        },
        onError: (error) => {
          toast.error("Error al obtener las sucursales");
        }
    });

    const [callGetSchedules, reqSchedules] = useGetCall("/schedules", {
        onCompleted: (response) => {
            setSchedules(response.data)
        },
        onError: (error) => {
          toast.error("Error al obtener las sucursales");
        }
    });

    const [callGetOffices, reqOffices] = useGetCall("/offices", {
        onCompleted: (response) => {
            setOffices(response.data)
        },
        onError: (error) => {
          toast.error("Error al obtener las sucursales");
        }
    });

    const [callGetJobs, reqJobs] = useGetCall("/jobs", {
        onCompleted: (response) => {
            setJobs(response.data)
        },
        onError: (error) => {
          toast.error("Error al obtener las sucursales");
        }
    });

    const [callGetDepartments, reqDepartments] = useGetCall("/departments", {
        onCompleted: (response) => {
            setDepartments(response.data)
        },
        onError: (error) => {
          toast.error("Error al obtener las sucursales");
        }
    });

    const onSubmit = () => {

        if(!planningValues.payrollPeriod)
            return toast.error("El periodo de nómina es requerido");

        if(!planningValues.tolerance)
            return toast.error("La tolerancia es requerida");

        callSave({
            planning,
            ...planningValues
        })
        
    }

    const onCancel = () => {
        setIsVisible(false);
    }

    const onChangePlanning = (employeePlanning, weekDay, schedule) => {

        let params = employeePlanning.params;
        params[weekDay].schedule = schedule;

        let previousPlanning = planning;
        let index = previousPlanning.findIndex(planning => planning.employee.id === employeePlanning.employee.id);
        previousPlanning[index].params = params;

        setPlanning(previousPlanning);
        setVisibleModal(null);

    }

    const onAddIncidence = (employeePlanning, weekDay, incidence) => {
            
        let params = employeePlanning.params;
        params[weekDay].incidenceRequest = incidence;

        let previousPlanning = planning;
        let index = previousPlanning.findIndex(planning => planning.employee.id === employeePlanning.employee.id);
        previousPlanning[index].params = params;

        setPlanning(previousPlanning);
        setVisibleModal(null);
    
    }

    useEffect(() => {
        callGetPayrollPeriods();
        callGetEmployees({ has_planning: true });
        callGetSchedules();
        callGetOffices();
        callGetJobs();
        callGetDepartments();
    }, [refreshValue]);

    useEffect(() => {
        
      let initialData = {};
      weekDays.forEach((day) => {
        initialData[day] = {
          schedule: {},
          incidenceRequest: {}
        };
      });

      let planning = employees.map(employee => {
          return {
              employee: employee,
              params: initialData
          }
      });

      setPlanning(planning);

    }, [employees, schedules]);

    return (
        <View
            planning={planning}
            payrollPeriods={payrollPeriods}
            jobs={jobs}
            departments={departments}
            offices={offices}
            schedules={schedules}
            filters={filters}
            setFilters={setFilters}
            planningValues={planningValues}
            setPlanningValues={setPlanningValues}
            setPlanning={setPlanning}
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
            onChangePlanning={onChangePlanning}
            onAddIncidence={onAddIncidence}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            setIsVisible={setIsVisible}
            setError={setError}
        />
    )

}

export default FormSave;