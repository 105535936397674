import React from 'react';

function EmployeeDevicesFormView({
  deviceEmployees = [],
  onUpdate,
  absStatus,
  onChangeAll,
  onCancel,
  onSubmit
}) {
    return (
      <div class="table-responsive datatable-custom position-relative" >
        <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
          <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
            <thead>
              <tr role="row">
                <th>Sucursal</th>
                <th>ID</th>
                <th>Número serial</th>
                <th>Modelo</th>
                <th>Ubicación</th>
                <th>Descripción</th>
                <th className='d-flex justify-content-center align-items-center'>
                  <input 
                    checked={absStatus}
                    onChange={() => onChangeAll(!absStatus)}
                    style={{width: "25px", height: "25px"}}
                    type="checkbox" />
                </th>
              </tr>
            </thead>
            <tbody>
              {
                deviceEmployees.map((deviceEmployee) => (
                  <tr key={deviceEmployee.device.id} role="row">
                    <td>
                      {deviceEmployee.device.office ? deviceEmployee.device.office.key : ""}
                    </td>
                    <td>
                      {deviceEmployee.device.id}
                    </td>
                    <td>
                      {deviceEmployee.device.serial_number}
                    </td>
                    <td>
                      {deviceEmployee.device.model}
                    </td>
                    <td>
                      {deviceEmployee.device.location}
                    </td>
                    <td>
                      {deviceEmployee.device.description}
                    </td>
                    <td className='d-flex justify-content-center align-items-center'>
                      <input 
                        style={{width: "25px", height: "25px"}}
                        onChange={() => onUpdate(deviceEmployee.device.id, !deviceEmployee.new_status)}
                        checked={deviceEmployee.new_status}
                        type="checkbox" />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div class="pt-3">
            <button type="submit" class="btn btn-primary" onClick={onSubmit}>
              <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
              Guardar
            </button>
            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
              <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
}

export default EmployeeDevicesFormView;