import React, { useContext, useState, useEffect } from "react";
import View from "components/crh/reports/attendanceNotRegistered/AttendanceNotRegistered.view";
import { LoadingContext } from "helpers/LoadingContext";
import { useGetCall, fetchPost } from "utils/api"
import { showResponseMessage } from "utils/message";
import Loading from "helpers/Loading"
import moment from "moment/moment";

function AttendanceNotRegistered() {

  let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const tab = savedLayout.tabs.find((item) => item.content === "Asis. no registrados");

	const { loading, setLoading } = useContext(LoadingContext);

  const [searchFilters, setSearchFilters] = useState({
    start_date: "",
    end_date: "",
    start_hour: "",
    end_hour: "",
    serial_number: "",
    pin: "",
    attendance_type: "LECTOR",
  });
  const [filterModal, setFilterModal] = useState(false);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [officeDetails, setOfficeDetails] = useState({});
  const [statusDetails, setStatusDetails] = useState({});

  const [permissionRead, setPermissionRead] = useState(false);

  const [callAttendanceLogs, reqAttendanceLogs] = useGetCall("/attendance_logs", {
    onCompleted: (response) => {
      setLoading(false);
      setAttendanceLogs(response.data.attendanceLogs);
      setOfficeDetails(response.data.officeDetails);
      setStatusDetails(response.data.statusDetails);
    },
    onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "attendance_logs", "registros de reportes", "read");
    }
  })

  useEffect(() => {
    fetchPost("/permissions/check", { table: "ATTENDACE_NO_REGISTER_REPORT", permission: "read" }).then((response) => {
      if (response.data) {
        setPermissionRead(response.data.isAllowed)
        if (response.data.isAllowed) setFilterModal(tab ? tab.active : false);
      };
    });
  }, [])
  
  const setFilters = (values) => {
    setLoading(true);
    values.offset = moment().utcOffset();
    callAttendanceLogs({ ...values, registered: false })
    setSearchFilters(values);
  }

  if (loading) return <Loading />

  const showModal = () => setFilterModal(true);
  const hideModal = () => setFilterModal(false);


  return (
    <View 
      permissionRead={permissionRead}
      attendanceLogs={attendanceLogs}
      filters={searchFilters}
      setFilters={setFilters}
      hideModal={hideModal}
      showModal={showModal}
      setFilterModal={setFilterModal}
      filterModal={filterModal}
      officeDetails={officeDetails}
      statusDetails={statusDetails}
    />
  );
}

export default AttendanceNotRegistered;