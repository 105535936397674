import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { getGroups } from "api/groups";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/groups/List.view";

function GroupsList({
	search,
	refresh,
	addEditGroupModal,
	addDetailGroupModal,
	theme
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [refreshValue, setRefreshValue] = useState(null)
	const [refreshDelete, setRefreshDelete] = useState(null)
	const [groups, setGroups] = useState([])

	if (refresh !== refreshValue) {
		setRefreshValue(refresh)
	}

	const [callGroups, reqGroups] = useGetCall("/groups", {
		onCompleted: (response) => {
			setLoading(false);
			setGroups(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "groups", "grupos de trabajo", "read");
		}
	})

	const [callDelete, reqDelete] = useDelete("/groups", {
		onCompleted: () => {
			if (!toast.isActive("delete-employee"))
				toast.success("Empleado eliminado", { toastId: "delete-employee" })
			setRefreshDelete(Math.random())
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "groups", "grupos de trabajo", "delete");
		}
	})

	useEffect(() => {
		setLoading(true);
		callGroups({ search });
	}, [refreshDelete, refreshValue, search])

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "WORK_GROUPS", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el empleado?"))
						callDelete({ id: id });
				} else {
					if (!toast.isActive("toast-delete-groups-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar grupos de trabajo", { toastId: "toast-delete-groups-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => addEditGroupModal(id);

	const onDetail = (id) => addDetailGroupModal(id);

	if (loading) return <Loading />

	return (
		<View
			groups={groups}
			onDelete={onDelete}
			onDetail={onDetail}
			onEdit={onEdit}
			theme={theme}
		/>
	)

}

export default GroupsList