import React from "react";
import LoadCsvField from "helpers/LoadCsvFIeld";
import Modal from "helpers/Modal";

function LoadDataView({ 
    isVisible, 
    onCancel, 
    data, 
    onLoadFile, 
    onDownloadExampleFile, 
    theme,
    inputFileRef,
    onSubmit
}) {
	return (
        <Modal
            title={"Carga inicial de saldo de vacaciones"}
            isVisible={isVisible}
            setIsVisible={onCancel}
        >			
            <div className='container mt-3'>

                <div className="row">
                    <div className="col-md-4">
                        <div class="mb-3">
                            <button
                                className="btn btn-primary"
                                onClick={onDownloadExampleFile}
                            >
                                Descargar plantilla
                            </button>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <LoadCsvField 
                                        inputRef={inputFileRef}
                                        type="file" 
                                        name="file" 
                                        className="form-control input__field" 
                                        onLoad={onLoadFile}
                                        required
                                    />
                                    <span class="input__label">
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div> 

                <div class="table-responsive datatable-custom position-relative">
                    <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "30vh"}}>
                        <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">          
                            { 
                                data.headers && data.rows 
                                    ?   <>                                            
                                            <thead>
                                                <tr role="row">
                                                    {
                                                        data.headers.map(header => 
                                                            <th>{header}</th>
                                                        )
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.rows.map(cols => (
                                                        <tr role="row">
                                                            {
                                                                cols.map(col => (
                                                                    <td>
                                                                        {col}
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </>
                                    :   <thead>
                                            <tr>
                                                <td>
                                                    La previsualización no está disponible
                                                </td>
                                            </tr>
                                        </thead>
                            }
                        </table>
                    </div>
                </div>

                <div class="pt-3">
                    <button type="submit" class="btn btn-primary" onClick={onSubmit}>
                        <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                        Cargar
                    </button>
                    <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                        <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                        Cancelar
                    </button>
                </div>

            </div>
        </Modal>
	);
}

export default LoadDataView;