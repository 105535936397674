import React from "react";
import Devices from "components/crh/deviceManager/Devices";
import Instructions from "components/crh/deviceManager/Instructions";

function DeviceManagerView({
    selectedDevices,
    setSelectedDevices,
	onSendInstructions
}) {
	return (
		<>
            <div className="content container-fluid p-5">
                <div className="row">
                    <div className="col-md-6">
                        <Devices 
                            selectedDevices={selectedDevices}
                            setSelectedDevices={setSelectedDevices}
                        />
                    </div>
                    <div className="col-md-6">
                        <Instructions
                            onSendInstructions={onSendInstructions}
                        />
                    </div>
                </div>
            </div>
		</>
	);
}

export default DeviceManagerView;