import React from 'react';
import NavBar from "components/nav/Navbar";
import SideBar from "components/nav/Sidebar";
import Tabs from "components/tabs/Tabs";

function LayoutView({
	activeTabId,
	setActiveTabId,
	onAddTab, 
	tabs,
	setTabs,
	theme,
	setTheme,
	button,
	setButton
}) {

	return (
		<div>
			<NavBar onAddTab={onAddTab} theme={theme} setTheme={setTheme} button={button} setButton={setButton} />
			<SideBar
				onAddTab={onAddTab}
				theme={theme}
				button={button}
				setButton={setButton}
				setTabs={setTabs}
			/>

			<div className={button ? 'container_admin' : 'container_admin_active'} style={{ transition: "all 0.3s ease-in" }}>
				<Tabs 
					theme={theme} 
					tabs={tabs} 
					setTabs={setTabs}
					activeTabId={activeTabId}
					setActiveTabId={setActiveTabId}
				/>
			</div>

		</div>
	);
}
export default LayoutView;