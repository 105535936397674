import React, {useState, useEffect, useContext} from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { getReasonsLeave } from "api/reasonsLeave";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/reasonsLeave/List.view";

function List({
	addEditJobModal,
	refresh,
	search
}) {
	
	const { loading, setLoading } = useContext(LoadingContext);

	const [reasonsLeave, setReasonsLeave] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callReasonsLeave, reqReasonsLeave] = useGetCall("/reasons_leave", {
		onCompleted: (response) => {
			setLoading(false);
			setReasonsLeave(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "reason_leave", "motivos de baja", "read");
		}
	})

	const [callDelete, reqDelete] = useDelete("/reasons_leave", {
		onCompleted: () => {
			if (!toast.isActive("reasonLeave-deleted"))
				toast.success("Motivo de baja eliminado", { toastId: "reasonLeave-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			showResponseMessage(error.status, "reason_leave", "motivos de baja", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callReasonsLeave({ search });
	}, [refreshDelete, refreshValue, search]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "REASON_LEAVE", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el motivo de baja?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-reason_leave-unauthorized")) {
						toast.error("Error, no tienes los permisos para motivos de baja", { toastId: "toast-delete-reason_leave-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditJobModal(id);
	}

	if (loading) return <Loading />

	return (
		<View reasonsLeave={reasonsLeave} onDelete={onDelete} onEdit={onEdit}/>
	);
}

export default List;