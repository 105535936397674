import React, { useState, useEffect, useRef } from "react"
import { usePost, useGetCall } from "utils/api"
import { toast } from "react-toastify"
import { showResponseMessage } from "utils/message"
import View from "components/crh/employees/Form.view"

function FormSave({ company, setIsVisible, refresh }) {

    const fileInputRef = useRef()
    const formikRef = useRef()
    const [offices, setOffices] = useState([])
    const [jobs, setJobs] = useState([])
    const [departments, setDepartments] = useState([])
    const [regimes, setRegimes] = useState([])
    const [contracts, setContracts] = useState([])
    const [payrolls, setPayrolls] = useState([])
    const [shifts, setShifts] = useState([])
    const [reasonLeaves, setReasonLeaves] = useState([])
    const [bosses, setBosses] = useState([])
    const [areas, setAreas] = useState([])
    const [subareas, setSubareas] = useState([])
    const [costCenters, setCostCenters] = useState([])
    const [groups, setGroups] = useState([])
    const [refreshValue, setRefreshValue] = useState(null)

    if (refresh !== refreshValue) {
        setRefreshValue(refresh)
    }

    const [callOffices, reqOffices] = useGetCall('/offices', {
        onCompleted: (response) => {
            setOffices(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "offices", "sucursales", "read")
        }
    })

    const [callJobs, reqJobs] = useGetCall('/jobs', {
        onCompleted: (response) => {
            setJobs(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "jobs", "puestos", "read")
        }
    })

    const [callDepartments, reqDepartments] = useGetCall('/departments', {
        onCompleted: (response) => {
            setDepartments(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "departments", "departamentos", "read")
        }
    })

    const [callRegimes, reqRegimes] = useGetCall('/regimes', {
        onCompleted: (response) => {
            setRegimes(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "regimes", "regimenes", "read")
        }
    })

    const [callContracts, reqContracts] = useGetCall('/contracts', {
        onCompleted: (response) => {
            setContracts(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "contracts", "contratos", "read")
        }
    })

    const [callPayrolls, reqPayrolls] = useGetCall('/payrolls', {
        onCompleted: (response) => {
            setPayrolls(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "payrolls", "nóminas", "read")
        }
    })

    const [callShifts, reqShifts] = useGetCall('/shifts', {
        onCompleted: (response) => {
            setShifts(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "shifts", "turnos", "read")
        }
    })

    const [callReasonsLeave, reqReasonsLeave] = useGetCall('/reasons_leave', {
        onCompleted: (response) => {
            setReasonLeaves(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "reasons_leave", "motivos de baja", "read")
        }
    })

    const [callBosses, reqBosses] = useGetCall('/bosses', {
        onCompleted: (response) => {
            setBosses(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "bosses", "jefes directos", "read")
        }
    })

    const [callAreas, reqAreas] = useGetCall('/areas', {
        onCompleted: (response) => {
            setAreas(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "areas", "áreas", "read")
        }
    })

    const [callSubareas, reqSubareas] = useGetCall('/subareas', {
        onCompleted: (response) => {
            setSubareas(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "subareas", "subáreas", "read")
        }
    })

    const [callCostCenters, reqCostCenters] = useGetCall('/cost_centers', {
        onCompleted: (response) => {
            setCostCenters(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "cost_centers", "centros de costos", "read")
        }
    })

    const [callGroups, reqGroups] = useGetCall('/groups', {
        onCompleted: (response) => {
            setGroups(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "groups", "grupos de empleados", "read")
        }
    })

    const [callSave, reqSave] = usePost("/employees", {
        onCompleted: () => {
            if (!toast.isActive("toast-save-employee"))
                toast.success("Empleado creado correctamente", { toastId: "toast-save-employee" })
            setIsVisible(false)
            formikRef.current.setValues({})
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("employee-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "employee-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-create-employees-unauthorized")) {
                    toast.error("Error, no tienes los permisos para crear empleados", { toastId: "toast-create-employees-unauthorized" })
                }
            } else if (err.status === 409) {
                if (!toast.isActive("employee-error-409")) {
                    toast.error("Ya existe un empleado con ese ID de empleado o ID en lector, por favor ingrese otras", { toastId: "employee-error-409" });
                }
            } else {
                if (!toast.isActive("employee-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "employee-error-500" });
                }
            }
        }
    })

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { firstname, dad_lastname, job_id, department_id, regime_id, contract_id, payroll_id, admission, office_id, start_shift } = values

        if (!firstname || !dad_lastname || !job_id || !department_id || !regime_id || !contract_id || !payroll_id || !admission || !office_id || !start_shift) {
            toast.error("Todos los campos son obligatorios")
            return
        }

        values.admission = new Date(values.admission).toISOString()
        values.start_shift = values.start_shift ? new Date(values.start_shift).toISOString() : null
        values.leave = values.leave ? new Date(values.leave).toISOString() : null

        values.user_id = sessionStorage.getItem("id")
        callSave({ ...values, company })

    }

    const onCancel = (resetForm) => {
        setIsVisible(false)
        resetForm({})
    }

    useEffect(() => {

        callOffices({ company });
        callJobs({ company });
        callDepartments({ company });
        callRegimes({ company });
        callContracts({ company });
        callPayrolls({ company });
        callShifts({ company });
        callReasonsLeave({ company });
        callBosses({ company });
        callAreas({ company });
        callSubareas({ company });
        callCostCenters({ company });
        callGroups({ company });

    }, [refreshValue])

    return (
        <View
            fileInputRef={fileInputRef}
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
            jobs={jobs}
            departments={departments}
            regimes={regimes}
            contracts={contracts}
            payrolls={payrolls}
            offices={offices}
            shifts={shifts}
            reasonLeaves={reasonLeaves}
            bosses={bosses}
            areas={areas}
            subareas={subareas}
            costCenters={costCenters}
            groups={groups}
            setIsVisible={setIsVisible}
        />
    )

}

export default FormSave