import React, { useState, useEffect, useRef } from "react";
import View from "components/crh/visits/visitRequests/Filters.view";
import { useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";

function Filters({ filters, setFilters, hideModal }) {

	const formikRef = useRef();
	const [profiles, setProfiles] = useState([]);

	const [callProfiles, reqProfiles] = useGetCall("/visit_profiles", {
		onCompleted: (response) => {
			setProfiles(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "visit_profiles", "perfiles", "read");
		}
	});

	const onSubmit = (values) => {
		setFilters(values);
		hideModal();
	}

	useEffect(() => {
		callProfiles();
	}, []);

	return (
		<View
			profiles={profiles}
			filters={filters}
			onSubmit={onSubmit}
			formikRef={formikRef}
		/>
	);

}

export default Filters;