import React from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import { Formik, Form, Field } from 'formik';

function FormView({
    users,
    searchRef,
    selectedUsers,
    setSelectedUsers,
    selectAll,
    setSelecAll,
    formikRef,
    onSubmit,
    onCancel,
    selectUser,
    handleKeyUp,
    onChangeSearch
}) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={{}}
                onSubmit={onSubmit}>
                {({ values, setFieldValue }) =>
                    <Form>

                        <div class="mb-3">
                            <div class="input-group input-group-merge input-group-flush">
                                <div class="input-group-prepend input-group-text" >
                                    <i class="bi-search"></i>
                                </div>
                                <input
                                    type="search"
                                    ref={searchRef}
                                    class="form-control"
                                    placeholder="Buscar usuario"
                                    onChange={onChangeSearch}
                                    onKeyUp={handleKeyUp}
                                />
                            </div>
                        </div>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Correo</th>
                                    <th scope="col">Perfil permisos</th>
                                    <th scope="col">

                                        <div class="text-center align-items-center">
                                            <input type="checkbox" class="form-check-input"
                                                onClick={() => {
                                                    if (!selectAll) {
                                                        setSelectedUsers(users.map(user => user.id));
                                                        setSelecAll(true);
                                                    } else {
                                                        setSelectedUsers([]);
                                                        setSelecAll(false);
                                                    }
                                                }}
                                                style={{ width: "25px", height: "25px" }}
                                                checked={function () {
                                                    if (selectedUsers.length === users.length) return true
                                                    return false;
                                                }()}
                                            />
                                        </div>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((user) => (
                                        <tr key={user.id}>
                                            <td>{user?.username ?? "-"}</td>
                                            <td>{user?.firstname} {user?.lastname}</td>
                                            <td>{user?.email ?? "-"}</td>
                                            <td>{user?.profileData?.key ?? "Sin perfil"}</td>
                                            <td>
                                                <div class="text-center align-items-center">
                                                    <input type="checkbox" class="form-check-input"
                                                        onClick={() => { }}
                                                        style={{ width: "25px", height: "25px" }}
                                                        onChange={() => selectUser(user.id)}
                                                        checked={selectedUsers.findIndex((item) => item === user.id) !== -1}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                Guardar
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;