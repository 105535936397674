import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/visits/brands/List.view";

function BrandsList({
	search,
	refresh,
	addEditModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [brands, setBrands] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refreshValue !== refresh) {
		setRefreshValue(refresh);
	}


	const [callBrands, reqBrands] = useGetCall("/brands", {
		onCompleted: (response) => {
			setLoading(false);
			setBrands(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "brands", "marcas", "read");
		}
	})


	const [callDelete, reqDelete] = useDelete("/brands", {
		onCompleted: () => {
			if (!toast.isActive("brand-deleted"))
				toast.success("Marca eliminada", { toastId: "brand-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "brands", "marcas", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callBrands({ search });
	}, [search, refreshValue, refreshDelete]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "BRANDS", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar la marca?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-catalogs-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar marcas", { toastId: "toast-delete-brands-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View brands={brands} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default BrandsList;