import React from "react";
import LoadCsvField from "helpers/LoadCsvFIeld";

const getPayrollPeriod = (periods, id) => {
  return periods.find(period => period.id.toString() == id);
}

const getWeeklyPeriods = (periods) => {
  let weeklyPeriods = periods.filter(period => period.payroll.key === 'S');
  return weeklyPeriods;
}

function BulkLoadView({
    planningValues,
    setPlanningValues,
    payrollPeriods,
    error,
    onDownloadExampleFile,
    inputFileRef,
    onLoadFile,
    data,
    validations,
    onSubmit,
    onCancel,
    onReset
}) {

    return (
        <div className='container mt-3'>

            <div className="row">
                <div className="col-md-6">
                    <div class="mb-3 d-flex">
                        <button
                            className="btn btn-primary"
                            onClick={onDownloadExampleFile}
                            style={{ marginRight: "10px" }}
                        >
                            Descargar plantilla
                        </button>
                        <button
                            className="btn btn-warning"
                            onClick={onReset}
                        >
                            Reestablecer datos
                        </button>
                    </div>
                </div>
                <div className="col-md-6">
                    <div class="mb-3">
                        <div class="form-group">
                            <label class="input">
                                <LoadCsvField
                                    inputRef={inputFileRef}
                                    type="file"
                                    name="file"
                                    className="form-control input__field"
                                    onLoad={onLoadFile}
                                    required
                                />
                                <span class="input__label">
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='h6 mt-2 mb-4'>
              Configuración:
            </div>

            <div className='d-flex mt-3 mb-3'>

              <label class="input">
                <select 
                  class="input__field form-control" 
                  required 
                  onChange={(e) => setPlanningValues({ ...planningValues, tolerance: e.target.value })}
                  value={planningValues?.tolerance}
                >
                  <option value="">Seleccionar</option>
                  {
                    Array.from(Array(101), (_, index) => index).map((minute) =>
                      <option key={minute} value={minute} selected={planningValues?.tolerance === minute}>
                        {minute} minutos
                      </option>
                    )
                  }
                </select>
                <span class="input__label">
                  Tolerancia
                  <span className='text-danger fw-bold'>*</span>
                </span>
              </label>

              <label class="input ms-2">
                <select 
                  class="input__field form-control" 
                  required 
                  value={planningValues.payrollPeriod?.id}
                  onChange={(e) => setPlanningValues({...planningValues, payrollPeriod: getPayrollPeriod(payrollPeriods, e.target.value)})}
                >
                  <option value="">Seleccionar</option>
                  {
                    getWeeklyPeriods(payrollPeriods).map((period, index) => (
                      <option key={index} value={period.id} selected={planningValues.payrollPeriod?.id.toString() === period.id.toString()}>
                        {period.description}
                      </option>
                    ))
                  }
                </select>
                <span class="input__label">
                  Periodo semanal
                  <span className='text-danger fw-bold'>*</span>
                </span>
              </label>

            </div>

            <div class="mb-3">
              <div class="form-group d-flex bg-crh">
                <div class="pt-1" style={{ width: "90%" }}>
                  <h5 class="text-center"><label for="switchExtraTime">Tiempo extra</label></h5>
                </div>
                <div class="text-center" style={{ width: "10%" }}>
                  <div className="form-check form-switch pt-1" id="extra-time-switch">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      id="switchExtraTime"
                      checked={planningValues.extra}
                      onChange={() => { 
                        setPlanningValues({ ...planningValues, extra: !planningValues.extra });
                      }} 
                    />
                  </div>
                </div>
              </div>
            </div>

            {
              planningValues?.extra ? (
                <div class="mb-3">
                  <div class="form-group">
                    <select
                      name="type_extra" 
                      class="form-control input__field" 
                      required 
                      value={planningValues?.type_extra}
                      onChange={(e) => setPlanningValues({ ...planningValues, type_extra: e.target.value })}
                    >
                      <option value="">Selecciona el momento del cálculo</option>
                      <option value="BEFORE">Calcular antes de la jornada</option>
                      <option value="AFTER">Calcular después de la jornada</option>
                      <option value="BOTH">Calcular antes y después de la jornada</option>
                    </select>
                  </div>
                </div>
              ) : null
            }

            <div class="mb-3">
              <div class="form-group d-flex bg-crh">
                <div class="pt-1" style={{ width: "90%" }}>
                  <h5 class="text-center"><label for="switchRoundExtraTime">Redondear tiempo extra</label></h5>
                </div>
                <div class="text-center" style={{ width: "10%" }}>
                  <div className="form-check form-switch pt-1" id="roundExtraTimeSwitch">
                      <input 
                        class="form-check-input" 
                        type="checkbox" 
                        id="switchRoundExtraTime"
                        checked={planningValues.round} 
                        onChange={() => { 
                          setPlanningValues({ ...planningValues, round: !planningValues.round });
                        }}
                      />
                  </div>
                </div>
              </div>
            </div>

            {
              planningValues?.round ? (
                <div class="mb-3">
                  <div class="form-group row">
                    <div class="col-md-6">
                      <select 
                        name="type_round" 
                        class="form-control input__field" 
                        required 
                        value={planningValues?.type_round || ''}
                        onChange={(e) => setPlanningValues({ ...planningValues, type_round: e.target.value })}
                      >
                          <option value="">Selecciona el tipo de redondeo</option>
                          <option value="COMPLETE_HOURS">Horas completas</option>
                          <option value="HALF_HOURS">Medias horas</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <select
                        name="distribution_round" 
                        class="form-control input__field" 
                        required 
                        value={planningValues?.distribution_round || ''}
                        onChange={(e) => setPlanningValues({ ...planningValues, distribution_round: e.target.value })}
                      >
                        <option value="">Selecciona la distribución del redondeo</option>
                        <option value="UP">Arriba (15 minutos)</option>
                        <option value="DOWN">Abajo (15 minutos)</option>
                        <option value="BOTH">Ambos (15 minutos)</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : null
            }

            <div class="table-responsive datatable-custom position-relative">
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "30vh" }}>
                    <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                        {
                            data.headers && data.rows && !error
                                ? <>
                                    <thead>
                                        <tr role="row">
                                            {
                                                data.headers.map(header =>
                                                    <th>{header}</th>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.rows.map((cols, indexRow) => {
                                                const error = validations.find((validation) => validation.index === indexRow);
                                                if (error) {
                                                    if (error.status === "INVALID") {
                                                        return (
                                                            <tr role="row" class="bg-secondary">
                                                                {cols.map(col => (<td>{col}</td>))}
                                                            </tr>
                                                        )
                                                    }
                                                    if (error.status === "DUPLICATED") {
                                                        return (
                                                            <tr role="row" class="bg-warning">
                                                                {cols.map(col => (<td>{col}</td>))}
                                                            </tr>
                                                        )
                                                    }
                                                    if (error.status === "EMPTY") {
                                                        const errorCols = error.cols;
                                                        return (
                                                            <tr role="row">
                                                                {cols.map((col, idx) => {
                                                                    if (errorCols.findIndex((errorCol) => errorCol === idx) !== -1) {
                                                                        return (<td class="bg-danger">{col}</td>)
                                                                    }
                                                                    return (<td>{col}</td>)
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                    if (error.status === "NOT_FOUND") {
                                                        const errorCols = error.cols;
                                                        return (
                                                            <tr role="row">
                                                                {cols.map((col, idx) => {
                                                                    if (errorCols.findIndex((errorCol) => errorCol === idx) !== -1) {
                                                                        return (<td class="bg-primary" style={{ color: "white" }}>{col}</td>)
                                                                    }
                                                                    return (<td>{col}</td>)
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                }
                                                return (
                                                    <tr role="row" class="bg-success" style={{ color: "white" }}>
                                                        {cols.map(col => (<td>{col}</td>))}
                                                    </tr>
                                                )

                                            })
                                        }
                                    </tbody>
                                </>
                                : <thead>
                                    <tr>
                                        <td>
                                            La previsualización no está disponible
                                        </td>
                                    </tr>
                                </thead>
                        }
                    </table>
                </div>
            </div>

            <div class="d-flex pt-3 justify-content-between">
                <div>
                    <button type="submit" class="btn btn-primary" onClick={onSubmit}>
                        <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                        Insertar datos
                    </button>
                    <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                        <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                        Cancelar
                    </button>
                </div>
                <table class="w-50">
                    <tr>
                        <td><div class="bg-secondary" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Fila de datos inválidos</td>
                        <td><div class="bg-warning" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Fila duplicada en el sistema</td>
                    </tr>
                    <tr>
                        <td><div class="bg-danger" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Datos obligatorios faltantes</td>
                        <td><div class="bg-success" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Fila correcta y lista para insertarse</td>
                    </tr>
                    <tr>
                        <td><div class="bg-primary" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                        <td>Clave no encontrada en el sistema</td>
                    </tr>
                    
                </table>
            </div>

        </div>
    );
}

export default BulkLoadView;