import React, { useState, useEffect } from "react";
import { getUser } from "api/users";
import { usePost } from "utils/api"
import View from "components/nav/Sidebar.view";

function SideBar({
	onAddTab,
	setTabs,
	theme,
	button
}) {

	const id = sessionStorage.getItem("id");
	const [user, setUser] = useState({});
	const [permissions, setPermissions] = useState([]);
	const isCrh = sessionStorage.getItem("type") === "SUPERADMIN";

	const [callProfile, reqProfile] = usePost('/permissions/profile', {
		onCompleted: (response) => {
			setPermissions(response.data);
		},
		onError: (error) => {
			console.error(error);
		}
	});

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
		callProfile({});
	}, [id])


	return (
		<View
			permissions={permissions}
			onAddTab={onAddTab}
			setTabs={setTabs}
			theme={theme}
			user={user}
			button={button}
			isCrh={isCrh}
		/>
	)

}

export default SideBar;