import React from 'react';
import { Formik, Form, Field } from 'formik';
import { SketchPicker } from 'react-color';
import { getNameIncidence } from "utils/constants";
import "resources/styles/Incidences.scss";

const incidences = [
    "FOUL",
    "RETARDMENT",
    "EARLY_DEPARTURE",
    "ENTRY_OMISSION",
    "EXIT_OMISSION",
    "EXTRA_TIME",
    "WORKED_HOLIDAYS",
    "WORKED_BANK_HOLIDAY",
    "VACATION_BONUS",
    "REST_DAY",
    "WORKED_REST_DAY",
    "BANK_HOLIDAY",
    "LICENSE",
    "PERMISSION_WITH_PAY",
    "PERMISSION_WITHOUT_PAY",
    "PERMISSION_RELEASE_EARLY",
    "PERMISSION_ARRIVE_LATE",
    "PERMISSION_LEAVE_HOURS",
    "JUSTIFY_ENTRY_OMISSION",
    "JUSTIFY_EXIT_OMISSION",
    "HOLIDAYS",
    "HOURLY_VACATIONS",
    "ECONOMIC_DAY",
    "INSERT_MARKINGS"
]

function FormView({
    formikRef = () => { },
    edit = false,
    displayColorPicker = false,
    incidence = {},
    onSubmit,
    error,
    status,
    requestRh,
    requestGeo,
    setStatus,
    setRequestRh,
    setRequestGeo,
    setError,
    onCancel,
    color,
    onChangeColor,
    onClickShow,
    onCloseColorPicker
}) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={incidence}
                onSubmit={onSubmit}>
                {({ values, setFieldValues, resetForm }) =>
                    <Form>
                        {/* Key */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="key" class="form-control input__field" placeholder=" " required value={values.key || ''} />
                                    <span class="input__label">
                                        Clave <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Description */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                                    <span class="input__label">
                                        Descripción <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                         {/* Abbreviation */}
                         <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="abbreviation" class="form-control input__field" placeholder=" " required value={values.abbreviation || ''} />
                                    <span class="input__label">
                                        Abreviación <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Type Incidence */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="type" class="form-control input__field" placeholder=" " required value={values.type || ''}>
                                        <option value="">Selecciona una incidencia</option>
                                        {
                                            incidences.map((name, idx) => {
                                                return <option key={idx} value={name}>{getNameIncidence(name)}</option>
                                            })
                                        }
                                    </Field>
                                    <span class="input__label">
                                        Incidencia <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Equivalence */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="equivalence" class="form-control input__field" placeholder=" " value={values.equivalence || ''} />
                                    <span class="input__label">Equivalencia</span>
                                </label>
                            </div>
                        </div>

                        {/* Others */}
                        <div class="mb-3">
                            <div class="form-group row">
                                <div class="col-md-4 d-flex">
                                    <h5 style={{ width: "50%" }}><label for="status-switch">Estatus</label></h5>
                                    <div className="form-check form-switch pt-1" id="status" style={{ width: "50%" }}>
                                        <input class="form-check-input" type="checkbox" id="status-switch"
                                            onChange={() => { setStatus(!status) }} checked={status} />
                                    </div>
                                </div>

                                <div class="col-md-4 d-flex">
                                    <h5 style={{ width: "50%" }}><label for="request-switch">Solicitud RH</label></h5>
                                    <div className="form-check form-switch pt-1" id="request" style={{ width: "50%" }}>
                                        <input class="form-check-input" type="checkbox" id="request-switch"
                                            onChange={() => { setRequestRh(!requestRh) }} checked={requestRh} />
                                    </div>
                                </div>

                                <div class="col-md-4 d-flex">
                                    <h5 style={{ width: "50%" }}><label for="geo-switch">Solicitud Geo</label></h5>
                                    <div className="form-check form-switch pt-1" id="geo" style={{ width: "50%" }}>
                                        <input class="form-check-input" type="checkbox" id="geo-switch"
                                            onChange={() => { setRequestGeo(!requestGeo) }} checked={requestGeo} />
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Attendance report */}
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <h5 class="bg-crh" style={{ padding: "5px 0" }}>Reporte de asistencia</h5>

                                <div class="row mt-4">
                                    <div class="col-md-6">
                                        <label class="input">
                                            <Field as="select" name="preference" class="form-control input__field" placeholder=" " required value={values.preference || ''}>
                                                <option value="">Selecciona una preferencia</option>
                                                <option value="1">1 (alta)</option>
                                                <option value="2">2 (media)</option>
                                                <option value="3">3 (baja)</option>
                                            </Field>
                                            <span class="input__label">
                                                Preferencia <span className='text-danger fw-bold'>*</span>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="col-md-6 d-flex">
                                        <h5 style={{ width: "50%" }}>Asignar color</h5>
                                        <div style={{
                                            width: '25%',
                                            height: '25px',
                                            padding: '5px',
                                            border: '1px solid black',
                                            background: '#fff',
                                            borderRadius: '1px',
                                            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                            cursor: 'pointer',
                                        }} onClick={onClickShow}>
                                            <div style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '2px',
                                                backgroundColor: color
                                            }} />
                                        </div>
                                        {
                                            displayColorPicker ? (
                                                <div style={{ position: 'absolute', zIndex: '2' }}>
                                                    <div style={{
                                                        position: 'fixed',
                                                        top: '0px',
                                                        right: '0px',
                                                        bottom: '0px',
                                                        left: '0px',
                                                    }} onClick={onCloseColorPicker} />
                                                    <SketchPicker color={color} onChange={onChangeColor} />
                                                </div>) : null
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar incidencia" : "Guardar incidencia"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;