import React, { useState } from "react";
import View from "components/crh/holidayLogs/List.view";

function HolidayLogsList({
	addDetailModal,
	theme,
  logs
}) {

	const [showIncidenceModal, setShowIncidenceModal] = useState(false);
	const [holidayLog, setHolidayLog] = useState(null);

	const onDetail = (id) => addDetailModal(id);

	return (
		<View
			logs={logs}
			onDetail={onDetail}
			theme={theme}
			showIncidenceModal={showIncidenceModal}
			holidayLog={holidayLog}
			setShowIncidenceModal={setShowIncidenceModal}
			setHolidayLog={setHolidayLog}
		/>
	);

}

export default HolidayLogsList;