import React from "react";
import { Formik, Form, Field } from 'formik';

function FiltersView({
	offices,
	jobs,
	shifts,
	departments,
	payrolls,
	filters,
	onSubmit,
	formikRef
}) {
	return (
		<div className='container mt-3'>
			<Formik
				innerRef={formikRef}
				initialValues={filters}
				onSubmit={onSubmit}>
				{({ values, setFieldValue, resetForm }) =>
					<Form>
						{/* Payrolls type */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Tipo de nómina</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="payroll_id" className="form-control" value={values.payroll_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										payrolls.map(payroll =>
											<option key={payroll.id} value={payroll.id}>{payroll.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Offices */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Sucursal</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="office_id" className="form-control" value={values.office_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										offices.map(office =>
											<option key={office.id} value={office.id}>{office.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Jobs */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Puesto</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="job_id" className="form-control" value={values.job_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										jobs.map(job =>
											<option key={job.id} value={job.id}>{job.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Departments */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Departamento</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="department_id" className="form-control" value={values.department_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										departments.map(department =>
											<option key={department.id} value={department.id}>{department.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Shifts */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Turnos</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="shift_id" className="form-control" value={values.shift_id || ''}>
									<option value="">Seleccione una opción</option>
									{
										shifts.map(shift =>
											<option key={shift.id} value={shift.id}>{shift.key} - {shift.description}</option>
										)
									}
								</Field>
							</div>
						</div>

						{/* Estatus */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Estatus</h4>
							</div>
							<div className="col-md-8">
								<Field as="select" name="status" className="form-control" value={values.status || ''}>
									<option value="">Seleccione una opción</option>
									<option value="ACTIVE">Activo</option>
									<option value="LEAVE">Inactivo</option>
								</Field>
							</div>
						</div>

						{/* Geolocation */}
						<div className="row my-2">
							<div className="col-md-4">
								<h4>Solo geolocalización</h4>
							</div>
							<div className="col-md-8">
								<div>
									<input class="form-check-input" type="checkbox" checked={values.geolocation}
										onChange={() => setFieldValue("geolocation", !values.geolocation)} />
								</div>
							</div>
						</div>

						<div class="pt-3">
							<button type="submit" class="btn btn-primary">
								<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
								Aceptar
							</button>
						</div>

					</Form>
				}</Formik>

		</div >
	)
}

export default FiltersView;