import React, { useState, useEffect, useContext, useRef } from "react"
import { toast } from "react-toastify"
import { useDelete, useGetCall, usePost } from "utils/api"
import { showResponseMessage } from "utils/message"
import { LoadingContext } from "helpers/LoadingContext"
import View from "components/crh/timetableHistory/TimetableHistory.view"
import moment from "moment";

function TimetableHistory() {

  const { loading, setLoading } = useContext(LoadingContext)
  const [refreshValue, setRefreshValue] = useState(null)
  const formikRef = useRef(null)

  const pageSize = 50
  const [pageNum, setPageNum] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [departments, setDepartments] = useState([])
  const [jobs, setJobs] = useState([])

  const [employees, setEmployees] = useState([])
  const [shifts, setShifts] = useState([])
  const [employeeTimetableHistories, setEmployeeTimetableHistories] = useState([])

  const [selectedEmployees, setSelectedEmployees] = useState({})
  const [selectedShift, setSelectedShift] = useState({})
  const [searchFilters, setSearchFilters] = useState({
    history_search: "",
    programming_search: "",
    job_id: "",
    department_id: "",
    start_date: moment.utc().startOf("month").format("YYYY-MM-DD"),
    end_date: moment.utc().format("YYYY-MM-DD")
  })

  const [callDeleteTimetableHistory, reqDeleteTimetableHistory] = useDelete("/timetable_histories", {
    onCompleted: (response) => {
      if (!toast.isActive("toast-timetable-history-delete-success"))
        toast.success("Programación de turno eliminada con éxito", { toastId: "toast-timetable-history-delete-success" })
      setRefreshValue(Math.random())
    },
    onError: (error) => {
      showResponseMessage(error.status, "timetable_history", "programación de turnos", "delete");
    }
  })

  const [callPostTimetableHistory, reqPostTimetableHistory] = usePost("/timetable_histories", {
    onCompleted: (response) => {

      if (!toast.isActive("toast-timetable-history-success"))
        toast.success("Turno programado con éxito", { toastId: "toast-timetable-history-success" })

      setRefreshValue(Math.random())
      setSelectedEmployees({})
      setSelectedShift({})
      formikRef.current.resetForm()

    },
    onError: (error) => {
      showResponseMessage(error.status, "timetable_history", "histórico de turnos", "create");
    }
  })

  const [callTimeTableHistories, reqTimeTableHistories] = useGetCall("/timetable_histories", {
    onCompleted: (response) => {
      setLoading(false)
      setEmployeeTimetableHistories(response.data.timetableHistories)
      setTotalPages(response.data.totalPages)
    },
    onError: (error) => {
      showResponseMessage(error.status, "timetable_history", "histórico de turnos", "read");
    }
  })

  const [callShifts, reqShifts] = useGetCall("/shifts", {
    onCompleted: (response) => {
      setLoading(false)
      setShifts(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "shifts", "turnos", "read");
    }
  })

  const [callEmployees, reqEmployees] = useGetCall("/employees", {
    onCompleted: (response) => {
      setLoading(false)
      setEmployees(response.data)
    },
    onError: (error) => {
      setLoading(false)
      showResponseMessage(error.status, "employees", "empleados", "read");
    }
  })

  const [callDepartments, reqDepartments] = useGetCall("/departments", {
    onCompleted: (response) => {
      setLoading(false)
      setDepartments(response.data)
    },
    onError: (error) => {
      setLoading(false)
      showResponseMessage(error.status, "departments", "departamentos", "read");
    }
  })

  const [callJobs, reqJobs] = useGetCall("/jobs", {
    onCompleted: (response) => {
      setLoading(false)
      setJobs(response.data)
    },
    onError: (error) => {
      setLoading(false)
      showResponseMessage(error.status, "jobs", "puestos", "read");
    }
  })

  const handleSubmit = (values) => {

    const trueSelectedEmployees = Object.keys(selectedEmployees)
      .filter((key) => selectedEmployees[key] === true)
      .map((key) => parseInt(key))

    const data = {
      employees: trueSelectedEmployees,
      shift: selectedShift.id,
      start_date: values.startDate,
      end_date: values.endDate
    }

    callPostTimetableHistory(data)

  }

  const handleCancel = () => {
    setSelectedEmployees({})
    setSelectedShift({})
    formikRef.current.resetForm()
  }

  const handleDelete = (timetableHistory) => {
    callDeleteTimetableHistory({
      employee_id: timetableHistory.employee.id,
      start_date: timetableHistory.start_date,
      end_date: timetableHistory.end_date
    })
  }

  const handleChangeSearch = (event, field) => {
    if (event.target.value === '') {
      setSearchFilters({ ...searchFilters, [field]: event.target.value });
    }
  }

  const handleKeyUp = (event, field) => {
    if (event.key === "Enter") {
      setSearchFilters({ ...searchFilters, [field]: event.target.value });
    }
  }

  useEffect(() => {
    callEmployees({
      job_id: searchFilters.job_id,
      department_id: searchFilters.department_id,
      search: searchFilters.programming_search,
      status: "ACTIVE",
      basic_info: true
    })
  }, [refreshValue, searchFilters])

  useEffect(() => {
    setLoading(true)
    callTimeTableHistories({ 
      job_id: searchFilters.job_id,
      department_id: searchFilters.department_id,
      search: searchFilters.history_search,
      start_date: searchFilters.start_date,
      end_date: searchFilters.end_date,
      page_num: pageNum,
      page_size: pageSize
    })
  }, [refreshValue, searchFilters, pageNum])

  useEffect(() => {
    setLoading(true)
    callShifts()
    callDepartments()
    callJobs()
  }, [refreshValue])

  return <View
    loading={loading}
    searchFilters={searchFilters}
    employees={employees}
    shifts={shifts}
    departments={departments}
    jobs={jobs}
    employeeTimetableHistories={employeeTimetableHistories}
    pageNum={pageNum}
    totalPages={totalPages}
    setPageNum={setPageNum}
    setTotalPages={setTotalPages}
    selectedEmployees={selectedEmployees}
    selectedShift={selectedShift}
    setSelectedEmployees={setSelectedEmployees}
    setSelectedShift={setSelectedShift}
    setSearchFilters={setSearchFilters}
    handleSubmit={handleSubmit}
    handleCancel={handleCancel}
    handleDelete={handleDelete}
    handleChangeSearch={handleChangeSearch}
    handleKeyUp={handleKeyUp}
    formikRef={formikRef}
  />

}

export default TimetableHistory