import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getReasonLeave } from "api/reasonsLeave";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/reasonsLeave/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reasonLeave, setReasonLeave] = useState({});
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/reasons_leave", {
        onCompleted: () => {
            if (!toast.isActive("reasonLeave-updated"))
                toast.success("Motivo de baja actualizado", { toastId: "reasonLeave-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(err.status, "reason_leave", "motivos de baja", "edit")
        }
    })

    useEffect(() => {

        getReasonLeave(id).then((response) => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);
                setReasonLeave(response.data);
            }
        });
        formikRef.current.setValues({});

    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            reasonLeave={reasonLeave}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
