import React from 'react';
import FileField from 'helpers/FileField';
import { Formik, Form, Field } from 'formik';
import { SERVER_URL } from 'settings';

function FormView({ 
    formikRef = () => { }, 
    edit = false, 
    company = {}, 
    onSubmit, 
    error, 
    setError, 
    onCancel,
    uploadedFiles = null,
    setUploadedFiles = () => { }
}) {

    return (
        <div className='container mt-3'>

            <Formik
                innerRef={formikRef}
                initialValues={company}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>

                        <div class="mb-3">
                            <h5>Empresa</h5>
                        </div>

                        {/* Key */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="key" class="form-control input__field" placeholder=" " required />
                                    <span class="input__label">
                                        Clave de la empresa
                                        <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Name */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="name" class="form-control input__field" placeholder=" " required />
                                    <span class="input__label">
                                        Nombre de la empresa
                                        <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* RFC */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="rfc" class="form-control input__field" placeholder=" " required />
                                    <span class="input__label">
                                        RFC de la empresa
                                        <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Image */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <FileField className="form-control" name="logo" setFieldValue={setFieldValue} required />
                                    <span class="input__label" style={{ backgroundColor: "#e9ecef" }}>
                                        Logo de empresa
                                        {!edit ? (<>{" "} <span className='text-danger fw-bold'>*</span></>) : <>{"..."}</>}
                                    </span>
                                </label>
                            </div>
                        </div>

                        {
                            !edit ? (
                                <>
                                    <div class="mb-3">
                                        <h5>Usuario inicial</h5>
                                    </div>

                                    <div class="mb-3 d-flex align-items-center" style={{ gap: "10px" }}>

                                        {/* Username */}
                                        <div class="form-group w-50 mr-5">
                                            <label class="input">
                                                <Field type="text" name="username" class="form-control input__field" placeholder=" " required />
                                                <span class="input__label">
                                                    Usuario <span className='text-danger fw-bold'>*</span>
                                                </span>
                                            </label>
                                        </div>

                                        {/* Email */}
                                        <div class="form-group w-50">
                                            <label class="input">
                                                <Field type="text" name="email" class="form-control input__field" placeholder=" " />
                                                <span class="input__label">Correo</span>
                                            </label>
                                        </div>

                                    </div>

                                    <div class="mb-3 d-flex align-items-center" style={{ gap: "10px" }}>

                                        {/* User's name  */}
                                        <div class="w-50">
                                            <div class="form-group">
                                                <label class="input">
                                                    <Field type="text" name="firstname" class="form-control input__field" placeholder=" " required />
                                                    <span class="input__label">
                                                        Nombre(s) del usuario <span className='text-danger fw-bold'>*</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                        {/* User's lastname  */}
                                        <div class="w-50">
                                            <div class="form-group">
                                                <label class="input">
                                                    <Field type="text" name="lastname" class="form-control input__field" placeholder=" " required />
                                                    <span class="input__label">
                                                        Apellidos del usuario <span className='text-danger fw-bold'>*</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    {/* User's password  */}
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label class="input">
                                                <Field type="password" name="password" class="form-control input__field" placeholder=" " required />
                                                <span class="input__label">
                                                    Contraseña <span className='text-danger fw-bold'>*</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }

                        {
                            !edit ? (
                                <>

                                    <div class="mb-3">
                                        <h5>Importar datos</h5>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label class="input">
                                                <FileField className="form-control" name="import_file" setFieldValue={setFieldValue} setUploadedFiles={setUploadedFiles} required multiple={false} />
                                                <span class="input__label" style={{ backgroundColor: "#e9ecef" }}>
                                                    Archivo de respaldo
                                                </span>
                                            </label>
                                        </div>
                                        {
                                            uploadedFiles != null 
                                                ?   <div className='mt-1'> 
                                                        { 
                                                            uploadedFiles === 0 
                                                                ? <div className='d-flex align-items-center'>
                                                                    <div
                                                                        class="spinner-border text-primary me-2" 
                                                                        role="status" 
                                                                        style={{ position: "relative", width: "20px", height: "20px" }}
                                                                    >
                                                                        <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                    Subiendo archivo...
                                                                </div>
                                                                : null
                                                        } 
                                                    </div>
                                                :   null
                                        }
                                    </div>

                                </>
                            ) : null
                        }

                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <span aria-hidden="true">&times;</span>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar entorno" : "Guardar entorno"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div>
    )
}

export default FormView;