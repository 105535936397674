import React from "react";
import "resources/styles/Home.scss";

function AttendanceRankingView({
    employees,
    ranking,
    loading
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="w-100 py-1 mb-0 px-3">
                <span className="h6 fw-bold text-primary">Ranking de asistencia</span>
            </div>

            <div className="w-100 h6 py-2 border-bottom border-top d-flex mb-0 px-3" style={{ fontSize: "0.9rem" }}>
                <div className="" style={{ width: "75%" }}>
                    Empleado
                </div>
                <div className="" style={{ width: "25%" }}>
                    Racha
                </div>
            </div>

            {
                ranking.map((attendanceRanking, index) => (
                    index < 15 
                        ?	<div className="w-100 h6 py-2 border-bottom d-flex align-items-center mb-0 px-3">
                                <div style={{ width: "13%" }}>
                                    {
                                        function(){
                                            if(employees[attendanceRanking.employee_id]?.photo && employees[attendanceRanking.employee_id]?.photo?.url) 
                                                return <img 
                                                    className="rounded-circle me-2" 
                                                    src={employees[attendanceRanking.employee_id]?.photo.url}
                                                    alt="avatar" 
                                                    style={{ width: "25px", height: "25px" }}
                                                />
                                            return <i className="bi bi-person-circle text-secondary ms-1" style={{ fontSize: "25px" }}></i>
                                        }()
                                    }
                                </div>
                                <div className="d-flex align-items-center ms-3 px-2" style={{ fontSize: "0.7rem", width: "60%" }}>
                                    <div className="d-flex flex-column" style={{ fontSize: "0.7rem" }}>
                                        <span className="text-dark">
                                            {employees[attendanceRanking.employee_id]?.firstname} {employees[attendanceRanking.employee_id]?.dad_lastname} {employees[attendanceRanking.employee_id]?.mom_firstname}
                                        </span>
                                        <span className="text-muted">
                                            {employees[attendanceRanking.employee_id]?.job?.description || "Sin puesto"}
                                        </span>
                                        <span className="text-muted">
                                            {employees[attendanceRanking.employee_id]?.department?.description || "Sin departamento"}
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center" style={{ width: "27%", fontSize: "0.7rem" }}>
                                    <i className="bi bi-chevron-double-up fw-bold text-success me-1" style={{ fontSize: "1rem" }}></i>
                                    <div>
                                        <span className="fw-bold">{ attendanceRanking.count }</span>	
                                        <small className="ms-1 text-muted">días</small>
                                    </div>
                                </div>
                            </div>
                        : null
                ))
            }
        </>
    )
}

export default AttendanceRankingView