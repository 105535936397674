import React, { useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/landing/ContactForm.view";

function ContactForm({ setIsVisible }) {

    const formikRef = useRef(null);
    const [callContact, reqContact] = usePost('/landing/request_price', {
        onCompleted: (response) => {
            if (formikRef.current) {
                formikRef.current.resetForm({values: ''});
            }
            if (!toast.isActive("contact-created")) {
                toast.success("Gracias su preferencia, en unos momentos un asesor se pondrá en contacto contigo",
                    { toastId: "contact-created" });
            }

            setIsVisible(false);
        },
        onError: (err) => {
            console.error(err);
            if (!toast.isActive("contact-error")) {
                toast.error("Ha ocurrido un error, por favor intente de nuevo mas tarde", { toastId: "contact-error" });
            }
        },
        includeAuth: false
    })

    const onSubmit = (values) => {

        const { email, firstname } = values;

        if (!email || !firstname ) {
            toast.error("Debe rellenar los campos obligatorios");
            return;
        }

        callContact({...values});
    }

    const onCancel = () => {
        if (formikRef.current) {
            formikRef.current.resetForm({values: ''});
        }
        setIsVisible(false);

    }

    return <View formikRef={formikRef} onSubmit={onSubmit} onCancel={onCancel} />;

}

export default ContactForm;