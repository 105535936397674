import React from "react";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import FileField from 'helpers/FileField';
import dayjs from 'dayjs';
const format = 'HH:mm';


const showHours = (values, incidencesWithHour, incidences) => {

	if (values.incidence) {
		const incidence = incidences.find((item) => item.id === parseInt(values.incidence.id));
		if (incidencesWithHour.find(item => item === incidence?.type))
			return true;
		else
			return false;
	} else {
		return false;
	}
}

const showInsertLog = (values, incidences) => {

	if (values.incidence) {
		const incidence = incidences.find((item) => item.id === parseInt(values.incidence.id));
		return incidence?.type === "INSERT_MARKINGS";
	} else {
		return false;
	}

}


function RequestFormView({
	formikRef,
	devices,
	incidences,
	onSubmit,
	onCancel,
	setKeyEmployee,
	onSearchEmployee,
	incidencesWithHour,
	setStartHour,
	setEndHour,
	endHour,
	startHour,
	incidenceRequest,
	setIsValidEmployee
}) {

	return (
		<div className='container mt-3'>
			<Formik
				initialValues={incidenceRequest}
				innerRef={formikRef}
				onSubmit={onSubmit}>
				{({ values, setFieldValue, resetForm }) =>
					<Form>
						{/* Search employee */}
						<div class="row mb-3">
							<div class="form-group col-md-8">
								<label class="input">
									<Field type="text" name="employee.key" class="form-control input__field" placeholder=" " required value={values.employee ? values.employee.key : ''}
										onChange={(e) => {
											setIsValidEmployee(false);
											setKeyEmployee(e.target.value)
											setFieldValue("employee.key", e.target.value)
										}} />
									<span class="input__label">
										ID de empleado <span className='text-danger fw-bold'>*</span>
									</span>
								</label>
							</div>
							<div class="form-group col-md-4">
								<button type="button" class="btn btn-primary" onClick={onSearchEmployee}>Buscar empleado</button>
							</div>
						</div>

						{/* Incidence */}
						<div class="mb-3">
							<div class="form-group">
								<label class="input">
									<Field as="select" name="incidence.id" class="form-control input__field" placeholder=" " required value={values.incidence ? values.incidence.id : ''}>
										<option value="">Selecciona una incidencia</option>
										{
											incidences.map((incidence) =>
												<option key={incidence.id} value={incidence.id}>{incidence.description}</option>
											)}
									</Field>
									<span class="input__label">
										Incidencia <span className='text-danger fw-bold'>*</span>
									</span>
								</label>
							</div>
						</div>


						{/* Date range */}
						{
							!showInsertLog(values, incidences) ? (
								<div class="mb-3">
									<div class="form-group text-center">
										<div class="row">
											<div class="col-md-4">
												<h5 style={{ padding: "5px 0" }}>Rango de fechas<span className='text-danger fw-bold'>*</span></h5>
											</div>

											<div class="col-md-4">
												<Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
											</div>

											<div class="col-md-4">
												<Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
											</div>
										</div>
									</div>
								</div>
							) : null
						}

						{/* Hour range */}
						{
							showHours(values, incidencesWithHour, incidences) ? (
								<div class="mb-3">
									<div class="form-group text-center">
										<div class="row">
											<div class="col-md-4">
												<h5 style={{ padding: "5px 0" }}>Rango de horas<span className='text-danger fw-bold'>*</span></h5>
											</div>

											<div class="col-md-4">
												<TimePicker format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
											</div>

											<div class="col-md-4">
												<TimePicker format={format} onChange={time => setEndHour(time.format(format))} value={endHour ? dayjs(endHour, format) : ''} />
											</div>
										</div>
									</div>
								</div>
							) : null
						}

						{
							showInsertLog(values, incidences) ? (
								<>
									<div class="mb-3">
										<div class="form-group">
											<div class="row">
												<div class="col-md-2">
													<h5 style={{ padding: "5px 0" }}>Fecha<span className='text-danger fw-bold'>*</span></h5>
												</div>
												<div class="col-md-4">
													<Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
												</div>
												<div class="col-md-2">
													<h5 style={{ padding: "5px 0" }}>Hora<span className='text-danger fw-bold'>*</span></h5>
												</div>
												<div class="col-md-4">
													<TimePicker style={{ width: "100%" }} format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
												</div>
											</div>
										</div>
									</div>
									<div class="mb-3">
										<div class="form-group">
											<label class="input">
												<Field as="select" name="device_id" class="form-control input__field" placeholder=" " required value={values.device_id || ''}>
													<option value="">Selecciona un lector</option>
													{
														devices.map((device) =>
															<option key={device.id} value={device.id}>{device.description}</option>
														)}
												</Field>
												<span class="input__label">
													Lector <span className='text-danger fw-bold'>*</span>
												</span>
											</label>
										</div>
									</div>
								</>
							) : null
						}

						{/* File */}
						<div class="mb-3">
							<div class="form-group">
								{
									incidenceRequest?.file ? (
										<div class="row">

											<div class="col-md-10">
												<label class="input">
													<FileField className="form-control" name="file" setFieldValue={setFieldValue} />
													<span class="input__label" style={{ backgroundColor: "#e9ecef" }}>
														Archivo incidencia
													</span>
												</label>
											</div>

											<div class="col-md-2">
												<a target="_blank" rel="noreferrer" href={incidenceRequest?.file?.url} class="btn btn-primary">Ver archivo</a>
											</div>

										</div>
									) : (
										<label class="input">
											<FileField className="form-control" name="file" setFieldValue={setFieldValue} />
											<span class="input__label" style={{ backgroundColor: "#e9ecef" }}>
												Archivo incidencia
											</span>
										</label>
									)

								}
							</div>
						</div>

						{/* Observations */}
						<div class="mb-3">
							<div class="form-group">
								<label class="input">
									<Field type="text" name="observations" class="form-control input__field" placeholder=" " value={values.observations || ''} />
									<span class="input__label">
										Observaciones
									</span>
								</label>
							</div>
						</div>

						<div class="pt-3">
							<button type="submit" class="btn btn-primary">
								<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
								Editar solicitud
							</button>

							<button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
								<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
								Cancelar
							</button>
						</div>

					</Form>
				}</Formik>

		</div >
	);
}

export default RequestFormView;