import React, { useState, useEffect, useRef } from "react";
import View from "components/crh/employees/Filters.view";
import { toast } from "react-toastify";
import { getOffices } from "api/offices";
import { getJobs } from "api/jobs";
import { getDepartments } from "api/departments";
import { getPayrolls } from "api/payrolls";
import { getShifts } from "api/shifts";

function Filters({ setLoading, company, filters, setFilters, hideModal }) {

	const formikRef = useRef();

	const [offices, setOffices] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [payrolls, setPayrolls] = useState([]);
	const [shifts, setShifts] = useState([]);

	useEffect(() => {

		getOffices({ company_id: company.id }).then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-offices-eror"))
					toast.error("Error al obtener las sucursales", { toastId: "toast-jobs-eror" })
			} else {
				setOffices(response.data)
			}
		})

		getJobs({ company_id: company.id }).then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-jobs-eror"))
					toast.error("Error al obtener los puestos", { toastId: "toast-jobs-eror" })
			} else {
				setJobs(response.data)
			}
		})

		getDepartments({ company_id: company.id }).then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-departments-eror"))
					toast.error("Error al obtener los departamentos", { toastId: "toast-departments-eror" })
			} else {
				setDepartments(response.data)
			}
		})

		getPayrolls({ company_id: company.id }).then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-payrolls-eror"))
					toast.error("Error al obtener los tipos de nómina", { toastId: "toast-payrolls-eror" })
			} else {
				setPayrolls(response.data)
			}
		})

		getShifts({ company_id: company.id }).then((response) => {
			if (response.errors.length > 0) {
				if (!toast.isActive("toast-shifts-eror"))
					toast.error("Error al obtener los tipos de nómina", { toastId: "toast-shifts-eror" })
			} else {
				setShifts(response.data)
			}
		})

	}, [])

	const onSubmit = (values) => {
		setFilters(values)
		// setLoading(true);
		hideModal()
	}

	return (
		<View
			offices={offices}
			jobs={jobs}
			shifts={shifts}
			departments={departments}
			payrolls={payrolls}
			filters={filters}
			onSubmit={onSubmit}
			formikRef={formikRef}
		/>
	);

}

export default Filters;