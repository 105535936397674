import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Field, Form } from "formik";
import "resources/styles/Login.scss";


function RecoverView({ onSubmit, superAdmin = false }) {

	return (
		<div className="login">
			<div className="container-all">
				<div className="container-fluid ctn-text">
					<h1 className="title mt-3">Bienvenido</h1>
				</div>
				<div className="container-fluid ctn-form">

					<div className="row w-100 align-items-center justify-content-center">
						<h1 class="my-4">Recuperar cuenta</h1>

						<Formik initialValues={{}} onSubmit={onSubmit}>
							{() => (
								<Form>
									{/* Client */}
									{
										!superAdmin ? (
											<div class="mb-4">
												<label class="input">

													<Field
														type="text"
														name="client"
														className="form-control input__field"
														tabindex="1"
														placeholder=" "
														required
														autofocus="1"
													/>
													<span class="input__label">
														Cliente <span className='text-danger fw-bold'>*</span>
													</span>
												</label>
											</div>
										) : null
									}

									{/* User */}
									<div class="mb-4">
										<label class="input">
											<Field
												type="text"
												name="user"
												className="form-control input__field"
												tabindex="1"
												placeholder=" "
												required
												autofocus="1"
											/>
											<span class="input__label">
												Usuario <span className='text-danger fw-bold'>*</span>
											</span>
										</label>
									</div>

									<div class="mb-4">
										<Link to={superAdmin ? "/admin/login" : "/login"} className="restaurar">
                      Iniciar sesión
                    </Link>
									</div>

									<button type="submit" class="btn btn-primary w-100 btn-block mb-4">
										Enviar correo
									</button>

								</Form>
							)}
						</Formik>

						<span className="text-footer">
							<a 
								href="https://controlhr.com.mx/terms"
								target='_blank'
								rel='noreferrer'
							>
								Aviso de privacidad</a> | Atención a clientes (55) 85397050
						</span>

					</div>

				</div>
			</div>

		</div>
	)

}

export default RecoverView;