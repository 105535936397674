import React, { useState, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/admin/users/Form.view";

function FormSave({ setIsVisible }) {

    const formikRef = useRef()

    const [callSave, reqSave] = usePost("/users/admin", {
        onCompleted: () => {

            if (!toast.isActive("user-created"))
                toast.success("Super usuario creado correctamente", { toastId: "user-created" });

            setIsVisible(false);
            formikRef.current.setValues({});
        },
        onError: (err) => {
            console.error(err)
            if (!toast.isActive("user-error"))
                toast.error("Error interno del servidor", { toastId: "user-error" });
        }
    })

    const onSubmit = (values) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { username, firstname, lastname, password } = values
        if (!username || !firstname || !lastname || !password) {
            toast.error("Llene todos los campos obligatorios");
            return;
        }

        callSave(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )
}

export default FormSave;