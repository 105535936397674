import React from "react";
import ScheduleList from "components/crh/schedules/List";
import Modal from "helpers/Modal";

function SchedulesView({
    search,
	onChangeSearch,
	handleKeyUp,
    isVisible,
    setIsVisible,
    modalTitle,
    modalContent,
    addModal,
    addEditModal,
    modalTitleEdit,
    isVisibleEdit,
    setIsVisibleEdit,
    modalContentEdit
}) {
    return (
        <>
            <div className="content container-fluid p-5">

                <div className="page-header mb-3">
                    <div className="row align-items-end">

                        <div className="col-sm mb-2 mb-sm-0">
                            <h1 className="page-header-title">Horarios</h1>
                        </div>

                        <div className="col-sm-auto">
                            <button type="button" className="btn btn-primary" onClick={addModal}>
                                <i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
                                Agregar horario
                            </button>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header card-header-content-md-between">
                        <div class="mb-2 mb-md-0">
                            <div class="input-group input-group-merge input-group-flush" style={{ width: "50%" }}>
                                <div class="input-group-prepend input-group-text" >
                                    <i class="bi-search"></i>
                                </div>
                                <input 
                                    type="search" 
                                    class="form-control" 
                                    placeholder="Buscar horario" 
									onChange={onChangeSearch}
									onKeyUp={handleKeyUp}
                                />
                            </div>
                        </div>
                    </div>

                    <ScheduleList
                        search={search}
                        refresh={Math.random()}
                        addEditModal={addEditModal}
                    />

                </div>
            </div>

            <Modal
                title={modalTitle}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            >
                {modalContent}
            </Modal>

            <Modal
                title={modalTitleEdit}
                isVisible={isVisibleEdit}
                setIsVisible={setIsVisibleEdit}
            >
                {modalContentEdit}
            </Modal>

        </>
    );

}

export default SchedulesView;