import React from 'react';
import { Formik, Form, Field } from 'formik';


function FormView({
    user = {},
    employee,
    formikRef = () => { },
    edit = false,
    jobs = [],
    departments = [],
    groups = [],
    onSubmit,
    error,
    setError,
    onCancel,
    setKeyEmployee,
    onSearchEmployee
}) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={user}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>

                        {/* Username */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="username" class="form-control input__field" placeholder=" " required value={values.username || ''} />
                                    <span class="input__label">
                                        Usuario <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Id employee */}
                        <div class="row mb-3">
                            <div class="form-group col-md-8">
                                <label class="input">
                                    <Field type="text" name="employee.key" class="form-control input__field" placeholder=" " value={values.employee ? values.employee.key : ''}
                                        onChange={(e) => {
                                            setKeyEmployee(e.target.value)
                                            setFieldValue("employee.key", e.target.value)
                                        }} />
                                    <span class="input__label">ID de empleado</span>
                                </label>
                            </div>
                            <div class="form-group col-md-4">
                                <button type="button" class="btn btn-primary" onClick={onSearchEmployee}>Buscar empleado</button>
                            </div>
                        </div>

                        {/* Name's user */}
                        <div className='row mb-3'>
                            <div class="col-md-6 form-group">
                                <label class="input">
                                    <Field type="text" name="firstname" class="form-control input__field" placeholder=" " required value={values.firstname || ''} disabled={employee?.firstname} />
                                    <span class="input__label">
                                        Nombre(s) <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>

                            {/* Last name's user */}
                            <div class="col-md-6 form-group">
                                <label class="input">
                                    <Field type="text" name="lastname" class="form-control input__field" placeholder=" " required value={values.lastname || ''} disabled={employee?.lastname} />
                                    <span class="input__label">
                                        Apellidos <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>


                        <div className='row mb-3'>
                            {/* Email's user */}
                            <div class="col-md-6 form-group">
                                <label class="input">
                                    <Field type="text" name="email" class="form-control input__field" placeholder=" " value={values.email || ''} disabled={employee?.email} />
                                    <span class="input__label">Correo</span>
                                </label>
                            </div>

                            {/* Password's user */}
                            <div class="col-md-6 form-group">
                                <label class="input">
                                    <Field type="password" name="password" class="form-control input__field" placeholder=" " value={values.password || ''} />
                                    <span class="input__label">
                                        Contraseña <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Jobs */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="job.id" class="form-control input__field" placeholder=" " value={values.job ? values.job.id : ''} disabled={employee && values?.job != null}>
                                        <option value="">Selecciona un puesto</option>
                                        {
                                            jobs.map((job) =>
                                                <option key={job.id} value={job.id}>{job.abbreviation} - {job.description}</option>
                                            )
                                        }
                                    </Field>
                                    <span class="input__label">Puesto</span>
                                </label>
                            </div>
                        </div>

                        {/* Departments */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="department.id" class="form-control input__field" placeholder=" " value={values.department ? values.department.id : ''} disabled={employee && values?.department != null}>
                                        <option value="">Selecciona un departamento</option>
                                        {
                                            departments.map((department) =>
                                                <option key={department.id} value={department.id}>{department.abbreviation} - {department.description}</option>
                                            )
                                        }
                                    </Field>
                                    <span class="input__label">Departamento</span>
                                </label>
                            </div>
                        </div>

                        {/* User's profile */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="profile" class="form-control input__field" placeholder=" " value={values.profile || ''}>
                                        <option value="">Selecciona un perfil</option>
                                        <option value="BOSS">Jefe</option>
                                        <option value="RH">RH</option>
                                    </Field>
                                    <span class="input__label">Perfil de autorización</span>
                                </label>
                            </div>
                        </div>

                        {/* User's group */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                <Field as="select" name="group.id" class="form-control input__field" placeholder=" " value={values.group ? values.group.id : ''}>
                                        <option value="">Selecciona un perfil</option>
                                        {
                                            groups.map((groups) =>
                                                <option key={groups.id} value={groups.id}>{groups.description}</option>
                                            )
                                        }
                                    </Field>
                                    <span class="input__label">Grupo de trabajo</span>
                                </label>
                            </div>
                        </div>

                        {/* User's type */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="type" class="form-control input__field" placeholder=" " required value={values.type || ''}>
                                        <option value="">Selecciona un tipo</option>
                                        <option value="ADMIN">Administrador</option>
                                        <option value="NORMAL">Estándar</option>
                                    </Field>
                                    <span class="input__label">
                                        Tipo de usuario <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar usuario" : "Guardar usuario"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;