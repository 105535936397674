import React from "react";
// import Tab from "@material-ui/core/Tab";
// import Tabs from "@material-ui/core/Tabs";
import StyleTag from "helpers/StyleTag";
import Tabs from "react-draggable-tabs";
import "resources/styles/Tabs.scss"

function TabsView({
	activeTabId,
	theme,
	tabs,
	onSelectTab,
	onCloseTab,
	loadedTabs,
	name
}) {

	return (
		<>

			<div className="pt-5" style={{ zIndex: 99 }}>

				<div
					className="d-flex align-items-end justify-content-start mt-4 pt-3"
					style={{ 
						zIndex: 99,
						backgroundColor: "#EEE",
						overflowX: "auto",
						overflowY: "hidden",
						width: "100%",
						height: "65px",
					}}
				>
					{tabs.map((tab) => <div
						className={`px-2 py-1 d-flex justify-content-between ${activeTabId === tab.id ? "active" : ""}`}
						style={{
							backgroundColor: activeTabId === tab.id ? "#FFF" : "#EEE",
							color: activeTabId === tab.id ? "#000" : "#666",
							border: activeTabId === tab.id ? "1px solid #CCC" : "1px solid #CCC",
							borderBottom: activeTabId === tab.id ? "none" : "1px solid #CCC",
							borderRadius: "9px 9px 0 0",
							cursor: "pointer",
							height: "40px",
						}}
					>
						<div 
							className="text-truncate px-2"
							onClick={() => onSelectTab(tab.id)}
						>
							{tab.content}
						</div>
						<span
							className="close ms-2"
							onClick={() => onCloseTab(tab.id)}
						>
							&times; 
						</span>
					</div>
					)}
				</div>

				<div className="mt-3">
					{loadedTabs.map((tab) => <div style={{ display: activeTabId === tab.id ? "block" : "none" }}>
						{tab.component}
					</div>
					)}
				</div>

			</div>
		</>
	)
}

export default TabsView;