import React from "react";
import View from "components/home/tables/ListAttendanceChart.view";
import moment from "moment";
import { months } from "utils/constants";

function List({ employees, incidences = [], setIsVisible }) {

    const length = 2
    const currentMonths = []
    let daysLength = 0

    const currentMonth = moment().month()
    for(let i = 0; i < length; i++) 
        currentMonths.push(months[(currentMonth - i + 12) % 12])
    
    currentMonths.reverse()
    daysLength = incidences.reduce((acc, item) => acc < item.length ? item.length : acc, 0)

	return (
		<View 
            employees={employees}
            daysLength={daysLength}
            currentMonths={currentMonths}
            incidences={incidences} 
            setIsVisible={setIsVisible} 
        />
	);

}

export default List;