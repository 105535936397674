import React, { useState } from "react";
import FormSet from "components/crh/timetables/FormSet";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/timetables/Timetables.view";

function Timetables() {

    const [isVisibleEdit, setIsVisibleEdit] = useState(false);
    const [modalTitleEdit, setModalTitleEdit] = useState("");
    const [modalContentEdit, setModalContentEdit] = useState(null);

    const [search, setSearch] = useState("");

    const addEditModal = (id) => {

        fetchPost("/timetables/check_permission", { types: ["edit"] }).then((response) => {
            if (response.data) {

                const data = response.data;
                if (data.isAllowed === true) {
                    setIsVisibleEdit(true);
                    setModalTitleEdit("Turnos - Horarios");
                    setModalContentEdit(
                        <FormSet
                            shiftId={id}
                            refresh={Math.random()}
                            setIsVisible={setIsVisibleEdit}
                        />
                    );
                } else {
                    if (!toast.isActive("toast-create-timetable-unauthorized")) {
                        toast.error("Error, no tienes los permisos para editar turnos/horarios", { toastId: "toast-create-timetable-unauthorized" })
                    }
                }
            }
        })
    }

    const onChangeSearch = (event) => {
        if (event.target.value === '') {
            setSearch(event.target.value);
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            setSearch(event.target.value);
        }
    }

    return (
        <View
            search={search}
            onChangeSearch={onChangeSearch}
            handleKeyUp={handleKeyUp}
            addEditModal={addEditModal}
            modalTitleEdit={modalTitleEdit}
            isVisibleEdit={isVisibleEdit}
            setIsVisibleEdit={setIsVisibleEdit}
            modalContentEdit={modalContentEdit}
        />
    );
}

export default Timetables;