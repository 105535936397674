import React, { useState, useEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/EntriesExits.view"
import { useSocketIO } from "utils/io"
import { getJSON } from "utils/sessionStorage";

function EntriesExits({ employees, filters, refreshValue, globalLoading, setGlobalLoading }) {

	const token = sessionStorage.getItem("token")
	const [loading, setLoading] = useState(false)
	const [attendanceLogs, setAttendanceLogs] = useState(getJSON("attendanceTag", []))
	const { data } = useSocketIO({ query: { token, type: "ATTENDANCE_LOG" } })

	const [callDashboard, reqDashboard] = useGetCall("/statistics/attendance_tag", {
		onCompleted: (response) => {
			setAttendanceLogs(response?.data?.attendanceLogs??[])
			setLoading(false)
			setGlobalLoading({ ...globalLoading, entriesExits: true })
		},
		onError: (error) => {
			setLoading(false);
			setGlobalLoading({ ...globalLoading, entriesExits: true })
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	useEffect(() => {
		try{ 
			sessionStorage.setItem("attendanceTag", JSON.stringify(attendanceLogs)) 
			sessionStorage.setItem("attendanceEntriesExitsEmployees", JSON.stringify(employees))
		}
		catch(e) { console.log(e) }
	}, [attendanceLogs])

	useEffect(() => {
		if(!globalLoading.map) return;
		if(globalLoading.entriesExits) return;
		setLoading(true)
		callDashboard({ ...filters })
	}, [refreshValue, data, globalLoading])

	return (
		<View
			loading={loading}
			attendanceLogs={attendanceLogs}
			employees={employees}
		/>
	)

}

export default EntriesExits