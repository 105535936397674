import React from 'react';
import FileField from 'helpers/FileField';
import { Formik, Form, Field } from 'formik';
import LoadCsvField from "helpers/LoadCsvFIeld";

function AttendanceLoadView({ 
    inputFileRef,
    csvData = {},
    companies, 
    devices,
    formikRef = () => { }, 
    edit = false, 
    company = {}, 
    onSubmit, 
    error, 
    setError, 
    onCancel,
    validations,
    uploadedFiles = null,
    setUploadedFiles = () => { },
    setCompany = () => { },
    onLoadFile = () => { },
    onDownloadExampleFile = () => { },
    onReset = () => { },
    onChangeCompany = () => { },
    onChangeDevice = () => { },
}) {
    return (
        <div className='container mt-3'>

            <Formik
                innerRef={formikRef}
                initialValues={company}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, resetForm }) =>
                    <Form>

                        <div class="mb-3">
                            <h5>Empresa</h5>
                        </div>

                        {/* Company */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field 
                                        as="select" 
                                        name="company_id" 
                                        class="input__field form-control" 
                                        required 
                                        value={values.company_id || ''}
                                        onChange={(e) => {
                                            onChangeCompany(companies.find((company) => company.id == e.target.value))
                                            setFieldValue("company_id", e.target.value)
                                        }}
                                    >
                                        <option value="">Seleccione una opción</option>
                                        {
                                            companies.map((company) => (
                                                <option value={company.id}>{company.key} - {company.name}</option>
                                            ))
                                        }
                                    </Field>
                                    <span class="input__label">
                                        Entorno
                                    <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Device */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field 
                                        as="select" 
                                        name="device_id" 
                                        class="input__field form-control" 
                                        value={values.device_id || ''}
                                        onChange={(e) => {
                                            onChangeDevice(devices.find((device) => device.id == e.target.value))
                                            setFieldValue("device_id", e.target.value)
                                        }}
                                    >
                                        <option value="">Seleccione una opción</option>
                                        {
                                            devices.map((device) => (
                                                <option value={device.id}>{device.serial_number} - {device.model}</option>
                                            ))
                                        }
                                    </Field>
                                    <span class="input__label">
                                        Lector
                                    <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Device */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <LoadCsvField
                                        inputRef={inputFileRef}
                                        type="file"
                                        name="file"
                                        className="form-control input__field"
                                        onLoad={onLoadFile}
                                        required
                                    />
                                    <span class="input__label">
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className='d-flex mb-3'>
                            <button
                                className="btn btn-primary"
                                type='button'
                                onClick={onDownloadExampleFile}
                                style={{ marginRight: "10px" }}
                            >
                                Descargar plantilla
                            </button>
                            <button
                                className="btn btn-warning"
                                type='button'
                                onClick={onReset}
                            >
                                Reestablecer datos
                            </button>
                        </div>

                        <div class="table-responsive datatable-custom position-relative">
                            <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "30vh" }}>
                                <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                                    {
                                        csvData.headers && csvData.rows && !error
                                            ? <>
                                                <thead>
                                                    <tr role="row">
                                                        {
                                                            csvData.headers.map(header =>
                                                                <th>{header}</th>
                                                            )
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        csvData.rows.map((cols, indexRow) => {
                                                            const error = validations.find((validation) => validation.index === indexRow);
                                                            if (error) {
                                                                if (error.status === "INVALID") {
                                                                    return (
                                                                        <tr role="row" class="bg-secondary">
                                                                            {cols.map(col => (<td>{col}</td>))}
                                                                        </tr>
                                                                    )
                                                                }
                                                                if (error.status === "DUPLICATED") {
                                                                    return (
                                                                        <tr role="row" class="bg-warning">
                                                                            {cols.map(col => (<td>{col}</td>))}
                                                                        </tr>
                                                                    )
                                                                }
                                                                if (error.status === "EMPTY") {
                                                                    const errorCols = error.cols;
                                                                    return (
                                                                        <tr role="row">
                                                                            {cols.map((col, idx) => {
                                                                                if (errorCols.findIndex((errorCol) => errorCol === idx) !== -1) {
                                                                                    return (<td class="bg-danger">{col}</td>)
                                                                                }
                                                                                return (<td>{col}</td>)
                                                                            })}
                                                                        </tr>
                                                                    )
                                                                }
                                                                if (error.status === "NOT_FOUND") {
                                                                    const errorCols = error.cols;
                                                                    return (
                                                                        <tr role="row">
                                                                            {cols.map((col, idx) => {
                                                                                if (errorCols.findIndex((errorCol) => errorCol === idx) !== -1) {
                                                                                    return (<td class="bg-primary" style={{ color: "white" }}>{col}</td>)
                                                                                }
                                                                                return (<td>{col}</td>)
                                                                            })}
                                                                        </tr>
                                                                    )
                                                                }
                                                            }
                                                            return (
                                                                <tr role="row" class="bg-success" style={{ color: "white" }}>
                                                                    {cols.map(col => (<td>{col}</td>))}
                                                                </tr>
                                                            )

                                                        })
                                                    }
                                                </tbody>
                                            </>
                                            : <thead>
                                                <tr>
                                                    <td>
                                                        La previsualización no está disponible
                                                    </td>
                                                </tr>
                                            </thead>
                                    }
                                </table>
                            </div>
                        </div>

                        <div class="d-flex pt-3 justify-content-between">
                            <div>
                                <button type="submit" class="btn btn-primary" onClick={onSubmit}>
                                    <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                    Insertar datos
                                </button>
                                <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
                                    <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                    Cancelar
                                </button>
                            </div>
                            <table class="w-50">
                                <tr>
                                    <td><div class="bg-secondary" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                                    <td>Fila de datos inválidos</td>
                                    <td><div class="bg-warning" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                                    <td>Fila duplicada en el sistema</td>
                                </tr>
                                <tr>
                                    <td><div class="bg-danger" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                                    <td>Datos obligatorios faltantes</td>
                                    <td><div class="bg-success" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                                    <td>Fila correcta y lista para insertarse</td>
                                </tr>
                                <tr>
                                    <td><div class="bg-primary" style={{ width: "15px", height: "15px", borderRadius: "50%" }}></div></td>
                                    <td>Clave no encontrada en el sistema</td>
                                </tr>
                                
                            </table>
                        </div>

                    </Form>
                }
            </Formik>

        </div>
    )
}

export default AttendanceLoadView;