import React from "react";
import Modal from "helpers/Modal";
import Chart from "chart.js/auto";
import ListPeriodAttendance from "components/home/tables/ListAttendanceChart.js";
import { ArcElement, Tooltip, Legend, CategoryScale } from 'chart.js';
import { Line } from "react-chartjs-2";
import "resources/styles/Home.scss";

Chart.register(ArcElement, Tooltip, Legend, CategoryScale);

function AttendanceChartView({
    employees,
    attendanceData,
    attendanceChart,
    isPeriodAttendanceVisible,
    setIsPeriodAttendanceVisible,
    loading
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div className="d-flex justify-content-between w-100 px-3 py-1 mb-0 pe-5">
                <span className="h6 fw-bold text-primary">Asistencia</span>
                <div>
                    <span className="badge mx-1" style={{ backgroundColor: "rgba(54, 162, 235, 0.7)" }}>Corriendo</span>
                    <span className="badge mx-1" style={{ backgroundColor: "rgba(140, 140, 140, 0.7)" }}>Anterior</span>
                </div>
            </div>

            <div className="w-100 h-100 px-3 mb-4 d-flex justify-content-center" onClick={() => setIsPeriodAttendanceVisible(true)} style={{ cursor: "pointer" }}>
                <Line 
                    style={{ cursor: "pointer" }}
                    data={attendanceChart}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                            line: {
                                tension: 0.4
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                enabled: false
                            }
                        }
                    }}
                />
            </div>

            <Modal
                title={"Asistencia"}
                isVisible={isPeriodAttendanceVisible}
                setIsVisible={setIsPeriodAttendanceVisible}
            >
                <ListPeriodAttendance
                    employees={employees??[]}
                    incidences={attendanceData??[]}
                    setIsVisible={setIsPeriodAttendanceVisible}
                />
            </Modal>

        </>
    )
}

export default AttendanceChartView