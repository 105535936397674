import React, { useState, useEffect, createContext } from 'react';
import jwtDecode from 'jwt-decode';

export const AuthContextUser = createContext();

export default function AuthProvider(props) {
    const { children } = props;
    const [user, setUser] = useState({
        user: null,
        isLoading: true
    });

    useEffect(() => {
        checkUserLogin(setUser)
    }, [])

    return (
        <AuthContextUser.Provider value={user}>
            {children}
        </AuthContextUser.Provider>
    )
}

function checkUserLogin(setUser) {
    const token = sessionStorage.getItem("token");

    if (!token) {

        sessionStorage.clear();
        localStorage.clear();

        setUser({
            user: null,
            isLoading: false
        });

    } else {
        setUser({
            isLoading: false,
            user: jwtDecode(token)
        });
    }

}