import React from "react";

function AttendanceLogsView({ 
  closeModal = () => {},
  attendanceLogs = [], 
  parameters = {} 
}) {
  return (
    <>
      {
        attendanceLogs && attendanceLogs.map((report) => (
          report.attendanceLogs.length > 0 ? (
            <>
              <div class="table-responsive datatable-custom position-relative report-content">
                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3">

                  <div className="mx-3">
                    <table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                      <thead>
                        <tr role="row" className="bg-dark">
                          <th>Fecha</th>
                          <th>Descripción</th>
                          {parameters.show_location && <th>Ubicación</th>}
                          <th>Hora</th>
                          <th>Estatus</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          report.attendanceLogs.map((attendanceLog) => (
                            <tr>
                              <td>{attendanceLog.log.date}</td>
                              <td>
                                {
                                  attendanceLog.log.type === "LECTOR"
                                    ? attendanceLog.device.description
                                    : "Geolocalización"
                                }
                              </td>
                              {parameters.show_location &&
                                <td>
                                  {
                                    attendanceLog.log.type === "LECTOR"
                                      ? attendanceLog.device.location
                                      : attendanceLog.log.location ?? (parseFloat(attendanceLog.log.lat).toFixed(3) + ", " + parseFloat(attendanceLog.log.lng).toFixed(3))
                                  }
                                </td>
                              }
                              <td>{attendanceLog.log.time}</td>
                              <td>
                                {function () {
                                  if (attendanceLog.log.status === "CREATED") return "Sin procesar"
                                  else if (attendanceLog.log.status === "PROCESSED") return "Procesado"
                                  else if (attendanceLog.log.status === "DUPLICATED") return "Duplicado"
                                  else if (attendanceLog.log.status === "OUT_OF_RANGE") return "Fuera de rango"
                                }()}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </>
          ) : <div className="text-center h6">No hay registros</div>
        ))
      }
      <div class="pt-3">
        <button type="button" class="btn btn-primary" onClick={closeModal}>
          <i class="bi bi-x" style={{marginRight: "10px"}}></i>
          Cerrar
        </button>
      </div>
    </>
  );
}

export default AttendanceLogsView;