import React, { useState, useEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/AttendanceMap.view"
import { getJSON } from "utils/sessionStorage";

function AttendanceMap({ employees, filters, refreshValue, globalLoading, setGlobalLoading }) {

	const [loading, setLoading] = useState(false)
	const [attendanceLogs, setAttendanceLogs] = useState(getJSON("attendanceLogs", []))
	const [isMapAttendanceLogVisible, setIsMapAttendanceLogVisible] = useState(false)
	const [circleSize, setCircleSize] = useState(15)

	const [callDashboard, reqDashboard] = useGetCall("/statistics/attendance_tag", {
		onCompleted: (response) => {
			setAttendanceLogs(response?.data?.attendanceLogs??[])
			setLoading(false)
			setGlobalLoading({ ...globalLoading, map: true })
		},
		onError: (error) => {
			setLoading(false);
			setGlobalLoading({ ...globalLoading, map: true })
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	useEffect(() => {
		try{ 
			sessionStorage.setItem("attendanceLogs", JSON.stringify(attendanceLogs)) 
			sessionStorage.setItem("attendanceMapEmployees", JSON.stringify(employees))
		}
		catch(e) { console.log(e) }
	}, [attendanceLogs])

    useEffect(() => {
			if(!globalLoading.summary) return;
			if(globalLoading.map) return;
			setLoading(true)
			callDashboard({ ...filters })
    }, [refreshValue, globalLoading])

	return (
		<View
			circleSize={circleSize}
			setCircleSize={setCircleSize}
			loading={loading}
			isMapAttendanceLogVisible={isMapAttendanceLogVisible}
			setIsMapAttendanceLogVisible={setIsMapAttendanceLogVisible}
			employees={employees}
			attendanceLogs={attendanceLogs}
		/>
	)

}

export default AttendanceMap