import React, { useState } from "react";
import FormSave from "components/crh/visits/colors/FormSave";
import FormSet from "components/crh/visits/colors/FormSet";
import { fetchPost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/visits/colors/Colors.view";

function Colors() {

  const [isVisible, setIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [modalTitleEdit, setModalTitleEdit] = useState("");
  const [modalContentEdit, setModalContentEdit] = useState(null);

  const [search, setSearch] = useState("");

  const addCreateModal = () => {
    fetchPost("/permissions/check", { table: "COLORS", permission: "create" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisible(true);
          setModalTitle("Agregar color");
          setModalContent(
            <FormSave setIsVisible={setIsVisible} refresh={Math.random()} />
          );
        } else {
          if (!toast.isActive("toast-create-color-unauthorized")) {
            toast.error("Error, no tienes los permisos para crear colores", { toastId: "toast-create-color-unauthorized" })
          }
        }
      }
    })
  }

  const addEditModal = (id) => {
    fetchPost("/permissions/check", { table: "COLORS", permission: "edit" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {
          setIsVisibleEdit(true);
          setModalTitleEdit("Editar color");
          setModalContentEdit(
            <FormSet
              id={id}
              refresh={Math.random()}
              setIsVisible={setIsVisibleEdit}
            />
          );
        } else {
          if (!toast.isActive("toast-edit-color-unauthorized")) {
            toast.error("Error, no tienes los permisos para editar colores", { toastId: "toast-edit-color-unauthorized" })
          }
        }
      }
    })
  }

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  return (
    <View
      search={search}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isVisibleEdit={isVisibleEdit}
      setIsVisibleEdit={setIsVisibleEdit}
      modalContent={modalContent}
      modalContentEdit={modalContentEdit}
      modalTitle={modalTitle}
      modalTitleEdit={modalTitleEdit}
      addCreateModal={addCreateModal}
      addEditModal={addEditModal}
    />
  );
}

export default Colors;