import React, { useState, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/landing/ScheduleZoom.view";

const format = 'HH:mm';

function ScheduleZoom({ setIsVisible }) {

    const formikRef = useRef(null);
    const [time, setTime] = useState(null);

    const [callScheduleMeeting, reqScheduleMeeting] = usePost('/landing/schedule_meeting', {
        onCompleted: (response) => {
            if (formikRef.current) {
                formikRef.current.resetForm();
            }
            setTime(null);

            if (!toast.isActive("meeting-created")) {
                toast.success("Se ha agendado correctamente la cita, le hemos enviado un correo con mas detalles, gracias por su elección",
                    { toastId: "meeting-created" });
            }

            setIsVisible(false);
        },
        onError: (err) => {
            console.error(err);
            if (!toast.isActive("meeting-error")) {
                toast.success("Ha ocurrido un error para crear la reunión, por favor intente de nuevo mas tarde", { toastId: "meeting-error" });
            }
        },
        includeAuth: false
    })

    const handleOnChangeTime = (newTime) => {
        setTime(newTime.format(format));
    }

    const onSubmit = (values) => {

        const { email, date } = values;

        if (!email || !date || !time) {
            toast.error("Debe rellenar los campos obligatorios");
            return;
        }

        let now = new Date();
        let schedule = new Date(date + " 00:00:00");

        if (schedule < now) {
            toast.error("La fecha de la reunción no puede ser menor o igual a la fecha actual");
            return;
        }

        callScheduleMeeting({
            email: values.email,
            date: values.date,
            time: time + ":00"
        });
    }

    const onCancel = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setTime(null);
        setIsVisible(false);

    }

    return <View time={time} formikRef={formikRef} onSubmit={onSubmit} onCancel={onCancel} handleOnChangeTime={handleOnChangeTime} />;

}

export default ScheduleZoom;