import React from "react";
import moment from "moment";

function ListView({ employees, incidences, currentMonths, setIsVisible }) {
	return (
		<>
			<div class="table-responsive datatable-custom" style={{ overflow: "auto", maxHeight: "70vh" }} >
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead style={{ position: "sticky", top: "-3px", zIndex: 1 }}>
						<tr role="row">
							<th>Incidencias</th>
                            {
                                currentMonths.map((item) => (
                                    <th key={item}>{item}</th>
                                ))
                            }
						</tr>
					</thead>
					<tbody>

						<tr>
                            <td>Faltas</td>
                            {
                                incidences.foulsCount.map((item) => (
                                    <td key={item}>{item}</td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td>Incapacidades</td>
                            {
                                incidences.licenseCount.map((item) => (
                                    <td key={item}>{item}</td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td>Vacaciones</td>
                            {
                                incidences.holidaysCount.map((item) => (
                                    <td key={item}>{item}</td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td>Retardos</td>
                            {
                                incidences.retardmentCount.map((item) => (
                                    <td key={item}>{item}</td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td>Tiempo extra</td>
                            {
                                incidences.extraTimeCount.map((item) => (
                                    <td key={item}>{item}</td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td>PCG</td>
                            {
                                incidences.permissionWithPayCount.map((item) => (
                                    <td key={item}>{item}</td>
                                ))
                            }
                        </tr>

                        <tr>
                            <td>PSG</td>
                            {
                                incidences.permissionWithoutPayCount.map((item) => (
                                    <td key={item}>{item}</td>
                                ))
                            }
                        </tr>

					</tbody>
				</table>
			</div>
			<div class="mt-3">
				<button type="button" class="btn btn-primary" style={{ marginLeft: "20px" }} onClick={() => setIsVisible(false)}>
					<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
					Cerrar
				</button>
			</div >
		</>

	);
}

export default ListView;