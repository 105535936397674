import React, { useEffect, useState } from 'react';
import { logout } from 'api/auth';
import { getUser } from 'api/users';
import View from "components/nav/Navbar.view";
import { getNotifications } from 'api/notifications';
import { toast } from "react-toastify";
import { useSocketIO } from "utils/io";
import { usePut } from 'utils/api';
import { IS_TEST } from "settings";

function Navbar({ theme, setTheme, button, setButton, onAddTab }) {
	
	const id = sessionStorage.getItem("id");
	const type = sessionStorage.getItem("type");
	const token = sessionStorage.getItem("token");
	const [menuOpen, setMenuOpen] = useState(false);
	const [notificationsOpen, setNotificationsOpen] = useState(false);
	const [user, setUser] = useState(true);
	const [notifications, setNotifications] = useState([]);
	const [notificationIds, setNotificationIds] = useState({});
	const { data } = useSocketIO({ query: { token, type: "NOTIFICATION" }});

	const [callSeen, qSeen] = usePut("/notifications/seen");

	if(data.id && !notificationIds[data.id]) {

		if(!toast.isActive("toast-notification")) 
			toast.info(
				({ closeToast, toastProps }) =>
					<>
						<div>
							{ data.title }
						</div>
						<small>
							{ data.content }
						</small>
					</>, 
				{ toastId: "toast-notification" }
			);

		setNotificationIds({ ...notificationIds, [data.id]: true });
		getNotifications().then((response) => setNotifications(response.data));

	}

	useEffect(() => {
		getNotifications().then((response) => {
			let receivedNotifications = response.data;
			if(receivedNotifications && receivedNotifications.length > 0)
				setNotifications(response.data);
		});
	}, []);

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
	}, [id])

	useEffect(() => {
		if(!notificationsOpen)
			getNotifications().then((response) => {
				setNotifications(response.data);
			});
	}, [notificationsOpen])

	const onOpenNotifications = () => {

		let status = !notificationsOpen;
		setNotificationsOpen(status)
		setMenuOpen(false)

		getNotifications().then((response) => {
			
			let receivedNotifications = response.data;
			if(receivedNotifications && receivedNotifications.length > 0) {
				setNotifications(receivedNotifications);
				if(status) callSeen({ notifications: receivedNotifications.map((receivedNotification) => receivedNotification.id) });
			}
		});

	}

	const onLogout = () => {

		let pathname = window.location.pathname;

		if(pathname.includes("geo")) {
			logout();
			window.location.href = (IS_TEST ? "/test" : "") + "/geo/login";
		}
		else {
			logout();
			window.location.href = (IS_TEST ? "/test" : "") + "/login";
		}

		
	}

	const navToggle = () => {
		button === false ? setButton(true) : setButton(false)
	}

	return (
		<View 
			notificationsOpen={notificationsOpen}
			setNotificationsOpen={setNotificationsOpen}
			onOpenNotifications={onOpenNotifications}
			onAddTab={onAddTab}	
			type={type}
			theme={theme}
			setTheme={setTheme}
			user={user}
			navToggle={navToggle}
			menuOpen={menuOpen}
			setMenuOpen={setMenuOpen}
			onLogout={onLogout}
			notifications={notifications}
		/>
	)

}


export default Navbar;

