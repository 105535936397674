import React from "react";
import PropTypes from 'prop-types';
import { API_URL } from "settings";

class FileField extends React.Component {

  render() {
    const { className = "", accept, multiple = false, inputRef = null, style = {} } = this.props;
    return (
      <form encType="multipart/form-data">
        <input name="file" type="file" className={className} style={style}
          accept={accept} onChange={this.onFileChange} multiple={multiple} ref={inputRef}></input>
      </form>
    );
  }

  constructor(props) {
    super(props);
    this.onFileChange = this.onFileChange.bind(this);
    this.chargedFiles = [];
  }

  async onFileChange(e) {

    const { setFieldValue, name, multiple, setTotalFiles = () => {}, setUploadedFiles = () => {}, onChange = () => {}, accept } = this.props;
    const files = e.target.files;

    setTotalFiles(files.length);
    setUploadedFiles(0);

    // Delete previous files
    if (this.chargedFiles && this.chargedFiles.length > 0)
      for (let file of this.chargedFiles)
        await deleteFile(file.id);

    if(accept) {
      for(let fileIdx in files) {

        if(fileIdx === "length") break;
  
        let file = files[fileIdx];
  
        let fileExtension = file.name.split(".").pop();
        let allowedExtensions = accept.split(",");
        let valid = false;
  
        for(let extIdx of allowedExtensions) {
          if(extIdx === "." + fileExtension) {
            valid = true;
            break;
          }
        }
  
        if(!valid) {
          alert(`El archivo ${file.name} no tiene la extensión correcta, debe ser ${allowedExtensions.join(", ")}, vuelva a intentarlo`);
          return;
        }
  
      }
    }

    const it = uploadFiles(files);
    const uploadedFiles = [];
    let running = true;

    while (running) {

      let it_value = it.next()
      if (it_value.done) break;

      let res = await it_value.value;

      if (!res.ok) {
        alert("Error al subir el archivo " + files[res.index].name);
        break;
      }

      uploadedFiles.push(res.body);
      setUploadedFiles(uploadedFiles.length);

    }

    this.chargedFiles = uploadedFiles;

    onChange(uploadedFiles);

    if(!setFieldValue) return;
    
    if (multiple) {
      setFieldValue(name, uploadedFiles);
      setFieldValue(name + "_ids", uploadedFiles.map(e => e.id));
    }
    else {
      setFieldValue(name, uploadedFiles[0]);
      setFieldValue(name + "_id", uploadedFiles[0].id);
    }

  }

}

function* uploadFiles(files) {
  for (let i = 0; i < files.length; i++)
    yield uploadFile(files[i]);
}

async function uploadFile(file) {

  let url = `${API_URL}/files/`;
  let formData = new FormData();
  formData.append("file", file);

  let res = await fetch(url, {
    method: "POST",
    headers: {
      "x-auth-token": sessionStorage.getItem("token"),
    },
    body: formData
  });

  let json = await res.json();

  return {
    body: json.data,
    ok: res.status === 200 || res.status === 201
  };

}

async function deleteFile(id) {

  let url = `${API_URL}/files/${id}`;
  let res = await fetch(url, {
    cache: "no-cache",
    headers: {
      "content-Type": "application/json",
      "x-auth-token": sessionStorage.getItem("token"),
    },
    method: "DELETE"
  });

  return {
    ok: res.status === 200 || res.status === 201
  };

}

FileField.propTypes = {
  className: PropTypes.string,
  accept: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  setUploadedFiles: PropTypes.func,
  setTotalFiles: PropTypes.func
};

export default FileField;