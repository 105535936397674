import React from "react";
import { ReactComponent as User } from "resources/svg/user.svg";
import FileField from 'helpers/FileField';
import { Formik, Form, Field } from 'formik';
import { SERVER_URL } from '../../../settings';

function ProfileView({ onSearchEmployee, formikRef, fileInputRef, onSubmit, user, setKeyEmployee, jobs, employee, departments }) {

	return (
		<div className="content container-fluid p-5">

			<div className="page-header mb-3">
				<div className="row align-items-end">

					<div className="col-sm mb-2 mb-sm-0">
						<h1 className="page-header-title">Mi perfil</h1>
					</div>

				</div>
			</div>

			<Formik
				innerRef={formikRef}
				initialValues={user}
				onSubmit={onSubmit}>
				{({ values, setFieldValue, resetForm }) =>
					<Form>
						<div class="row">

							<div class="col-lg-3">
								<div class="card mb-4">
									<div class="card-body text-center">
										{
											values.photo ? (
												<img crossorigin="anonymous" class="rounded-circle img-fluid" src={(values.photo.path ? (SERVER_URL + "/" + values.photo.path) : values.photo.url)} alt="Foto" style={{ width: "70%" }} />
											) : (
												<User style={{ width: "70%" }} />
											)
										}

										<h5 class="my-3">
											<p>{user.firstname}</p>
											<p>{user.lastname}</p>
										</h5>
										<div class="d-flex justify-content-center mb-2">
											<FileField
												style={{ display: "none" }}
												accept=".jpg,.jpeg,.png"
												inputRef={fileInputRef}
												name="photo"
												multiple={false}
												setFieldValue={setFieldValue}
												className="form-control"
												required
											/>
											<button type="button" class="btn btn-primary"
												onClick={() => { fileInputRef.current.click(); }}
											>Cambiar foto</button>
										</div>
									</div>
								</div>

							</div>


							<div class="col-lg-9">

								<div class="card mb-4">
									<div class="card-body">

										<div class="row">
											<div class="row col-md-6">
												<div class="col-sm-3 d-flex align-items-center">
													<p class="mb-0">Usuario</p>
												</div>
												<div class="col-sm-9">
													<Field type="text" name="username" class="form-control" placeholder=" " required value={values.username || ''} />
												</div>
											</div>

											<div class="row col-md-6">
												<div class="col-sm-3 d-flex align-items-center">
													<p class="mb-0">Correo</p>
												</div>
												<div class="col-sm-9">
													<Field type="text" name="email" class="form-control" placeholder=" " value={values.email || ''} disabled={employee?.email} />
												</div>
											</div>
										</div>
										<hr />
										<div class="row">

											<div class="row col-md-6">
												<div class="col-sm-3 d-flex align-items-center">
													<p class="mb-0">Nombre(s)</p>
												</div>
												<div class="col-sm-9">
													<Field type="text" name="firstname" class="form-control" placeholder=" " required value={values.firstname || ''} disabled={employee?.firstname} />
												</div>
											</div>

											<div class="row col-md-6">
												<div class="col-sm-3 d-flex align-items-center">
													<p class="mb-0">Apellidos</p>
												</div>
												<div class="col-sm-9">
													<Field type="text" name="lastname" class="form-control" placeholder=" " required value={values.lastname || ''} disabled={employee?.lastname} />
												</div>
											</div>

										</div>
										<hr />
										<div class="row">
											<div class="col-sm-3 d-flex align-items-center">
												<p class="mb-0">Contraseña</p>
											</div>
											<div class="col-sm-9">
												<Field type="password" name="password" class="form-control" placeholder=" " />
											</div>
										</div>
										{
											user.type !== "SUPERADMIN" && (
												<>
													<hr />
													<div class="row">
														<div class="col-sm-3">
															<p class="mb-0">ID de empleado</p>
														</div>
														<div class="col-sm-9 d-flex">
															<div style={{ width: "40%", marginRight: "40px" }}>
																<Field type="text" name="employee.key" class="form-control input__field" placeholder=" " value={values.employee ? values.employee.key : ''}
																	onChange={(e) => {
																		setKeyEmployee(e.target.value)
																		setFieldValue("employee.key", e.target.value)
																	}}
																/>
															</div>
															<div style={{ width: "60%" }}>
																<button type="button" class="btn btn-primary" onClick={onSearchEmployee}>Buscar empleado</button>
															</div>
														</div>
													</div>
													<hr />
													<div class="row">
														<div class="row col-md-6">
															<div class="col-sm-3 d-flex align-items-center">
																<p class="mb-0" style={{ fontSize: "15px" }}>Departamento</p>
															</div>
															<div class="col-sm-9">
																<Field as="select" name="department.id" class="form-control" placeholder=" " value={values.department ? values.department.id : ''} disabled={employee && values?.department != null}>
																	<option value="">Selecciona un departamento</option>
																	{
																		departments.map((department) =>
																			<option key={department.id} value={department.id}>{department.description}</option>
																		)
																	}
																</Field>
															</div>
														</div>

														<div class="row col-md-6">
															<div class="col-sm-3 d-flex align-items-center">
																<p class="mb-0">Puesto</p>
															</div>
															<div class="col-sm-9">
																<Field as="select" name="job.id" class="form-control" placeholder=" " value={values.job ? values.job.id : ''} disabled={employee && values?.job != null}>
																	<option value="">Selecciona un puesto</option>
																	{
																		jobs.map((job) =>
																			<option key={job.id} value={job.id}>{job.description}</option>
																		)
																	}
																</Field>
															</div>
														</div>
													</div>

												</>
											)
										}
										<hr />
										<div class="row">
											<button type="submit" class="btn btn-primary">
												<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
												Guardar cambios
											</button>
										</div>

									</div>
								</div>

							</div>

						</div>
					</Form>
				}
			</Formik>



		</div>

	)
}

export default ProfileView;