import React from "react";
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import FileField from 'helpers/FileField';
import dayjs from 'dayjs';
import { Typeahead } from "react-bootstrap-typeahead";
const format = 'HH:mm';

function RequestFormView({
	formikRef,
	devices,
	employees,
	incidences,
	onSubmit,
	setStartHour,
	setEndHour,
	endHour,
	startHour,
	setEmployee
}) {

	return (
		<div className='container mt-3'>
			<Formik
				initialValues={{}}
				innerRef={formikRef}
				onSubmit={onSubmit}>
				{({ values, setFieldValue, resetForm }) =>
					<Form>

						{/* Search employee */}
						<div class="row mb-3">
							<div class="form-group col-md-12">
								<label class="input">
									<Typeahead
										className="form-control input__field"
										options={employees}
										placeholder="Buscar empleado"
										onInputChange={(selected) => {
											console.log(selected)
										}}
										onChange={(selected) => {
											if (selected.length > 0) {
												setEmployee(selected[0])
												setFieldValue("employee.key", selected[0].key)
											}
										}}
										labelKey={(option) => `${option.key} - ${option.firstname} ${option.dad_lastname ?? ""} ${option.mom_lastname ?? ""}`}
									/>
									<span class="input__label">
										ID de empleado <span className='text-danger fw-bold'>*</span>
									</span>
								</label>
							</div>
						</div>

						{/* Incidence */}
						<div class="mb-3">
							<div class="form-group">
								<label class="input">
									<Field as="select" name="incidence.id" class="form-control input__field" placeholder=" " required value={values.incidence ? values.incidence.id : ''}>
										<option value="">Selecciona una incidencia</option>
										{
											incidences.map((incidence) =>
												<option key={incidence.id} value={incidence.id}>{incidence.description}</option>
											)}
									</Field>
									<span class="input__label">
										Incidencia <span className='text-danger fw-bold'>*</span>
									</span>
								</label>
							</div>
						</div>

						{
							function () {
								if (values.incidence) {
									const incidence = incidences.find((item) => item.id === parseInt(values.incidence.id));
									if (!incidence) return <></>;

									const incidences_dates_hours = [
										"PERMISSION_ARRIVE_LATE",
										"PERMISSION_LEAVE_HOURS",
										"PERMISSION_RELEASE_EARLY",

									];

									const incidences_dates = [
										"PERMISSION_WITHOUT_PAY",
										"JUSTIFY_ENTRY_OMISSION",
										"JUSTIFY_EXIT_OMISSION",
										"HOLIDAYS",
										"ECONOMIC_DAY",
										"FOUL",
										"RETARDMENT",
										"EARLY_DEPARTURE",
										"ENTRY_OMISSION",
										"EXIT_OMISSION",
										"EXTRA_TIME",
										"WORKED_HOLIDAYS",
										"WORKED_BANK_HOLIDAY",
										"VACATION_BONUS",
										"REST_DAY",
										"WORKED_REST_DAY",
										"BANK_HOLIDAY",
										"LICENSE",
										"PERMISSION_WITH_PAY"
									];

									if (incidences_dates_hours.includes(incidence.type)) {
										return (<>
											<div class="mb-3">
												<div class="form-group text-center">
													<div class="row">
														<div class="col-md-4">
															<h5 style={{ padding: "5px 0" }}>Rango de fechas<span className='text-danger fw-bold'>*</span></h5>
														</div>

														<div class="col-md-4">
															<Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
														</div>

														<div class="col-md-4">
															<Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
														</div>
													</div>
												</div>
											</div>

											<div class="mb-3">
												<div class="form-group text-center">
													<div class="row">
														<div class="col-md-4">
															<h5 style={{ padding: "5px 0" }}>Rango de horas<span className='text-danger fw-bold'>*</span></h5>
														</div>

														<div class="col-md-4">
															<TimePicker format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
														</div>

														<div class="col-md-4">
															<TimePicker format={format} onChange={time => setEndHour(time.format(format))} value={endHour ? dayjs(endHour, format) : ''} />
														</div>
													</div>
												</div>
											</div>
										</>);

									} else if (incidences_dates.includes(incidence.type)) {

										return (
											<div class="mb-3">
												<div class="form-group text-center">
													<div class="row">
														<div class="col-md-4">
															<h5 style={{ padding: "5px 0" }}>Rango de fechas<span className='text-danger fw-bold'>*</span></h5>
														</div>

														<div class="col-md-4">
															<Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
														</div>

														<div class="col-md-4">
															<Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
														</div>
													</div>
												</div>
											</div>
										);

									} else if (incidence.type === 'INSERT_MARKINGS') {

										return (
											<>
												<div class="mb-3">
													<div class="form-group">
														<div class="row">
															<div class="col-md-2">
																<h5 style={{ padding: "5px 0" }}>Fecha<span className='text-danger fw-bold'>*</span></h5>
															</div>
															<div class="col-md-4">
																<Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
															</div>
															<div class="col-md-2">
																<h5 style={{ padding: "5px 0" }}>Hora<span className='text-danger fw-bold'>*</span></h5>
															</div>
															<div class="col-md-4">
																<TimePicker style={{ width: "100%" }} format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
															</div>
														</div>
													</div>
												</div>

												<div class="mb-3">
													<div class="form-group">
														<label class="input">
															<Field as="select" name="device.id" class="form-control input__field" placeholder=" " required value={values.device ? values.device.id : ''}>
																<option value="">Selecciona un lector</option>
																{
																	devices.map((device) =>
																		<option key={device.id} value={device.id}>{device.description}</option>
																	)}
															</Field>
															<span class="input__label">
																Lector <span className='text-danger fw-bold'>*</span>
															</span>
														</label>
													</div>
												</div>
											</>
										);

									} else if (incidence.type === 'HOURLY_VACATIONS') {
										return (<>
											<div class="mb-3">
												<div class="form-group text-center">
													<div class="row">
														<div class="col-md-3">
															<h5 style={{ padding: "5px 0" }}>Fecha inicial<span className='text-danger fw-bold'>*</span></h5>
														</div>

														<div class="col-md-3">
															<Field type="date" name="start_date" class="form-control input__field" required value={values.start_date || ''} />
														</div>

														<div class="col-md-3">
															<h5 style={{ padding: "5px 0" }}>Fecha final<span className='text-danger fw-bold'>*</span></h5>
														</div>

														<div class="col-md-3">
															<Field type="date" name="end_date" class="form-control input__field" required value={values.end_date || ''} />
														</div>
													</div>
												</div>
											</div>

											<div class="mb-3">
												<div class="form-group text-center">
													<div class="row">
														<div class="col-md-3">
															<h5 style={{ padding: "5px 0" }}>Hora inicial<span className='text-danger fw-bold'>*</span></h5>
														</div>

														<div class="col-md-3">
															<TimePicker format={format} onChange={time => setStartHour(time.format(format))} value={startHour ? dayjs(startHour, format) : ''} />
														</div>

														<div class="col-md-3">
															<h5 style={{ padding: "5px 0" }}>Hora final<span className='text-danger fw-bold'>*</span></h5>
														</div>

														<div class="col-md-3">
															<TimePicker format={format} onChange={time => setEndHour(time.format(format))} value={endHour ? dayjs(endHour, format) : ''} />
														</div>
													</div>
												</div>
											</div>
										</>);

									}

								}
							}()
						}

						{/* File */}
						<div class="mb-3">
							<div class="form-group">
								<label class="input">
									<FileField className="form-control" name="file" setFieldValue={setFieldValue} />
									<span class="input__label" style={{ backgroundColor: "#e9ecef" }}>
										Archivo incidencia
									</span>
								</label>

							</div>
						</div>

						{/* Observations */}
						<div class="mb-3">
							<div class="form-group">
								<label class="input">
									<Field type="text" name="observations" class="form-control input__field" placeholder=" " value={values.observations || ''} />
									<span class="input__label">
										Observaciones
									</span>
								</label>
							</div>
						</div>

					</Form>
				}</Formik>

		</div >
	);
}

export default RequestFormView;