import React from 'react';
import { Formik, Form, Field } from 'formik';


function FormView({ formikRef = () => { }, edit = false, payrollPeriod = {}, payrolls = [], onSubmit, error, setError, onCancel }) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={payrollPeriod}
                onSubmit={onSubmit}>
                {({ values, setFieldValues, resetForm }) =>
                    <Form>
                        {/* Key */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="key" class="form-control input__field" placeholder=" " required value={values.key || ''} />
                                    <span class="input__label">
                                        Clave <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Description */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                                    <span class="input__label">
                                        Descripción <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Payroll */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field as="select" name="payroll.id" class="form-control input__field" value={values.payroll ? values.payroll.id : ''}>
                                        <option value="">Selecciona un tipo de nómina</option>
                                        {
                                            payrolls.map((payroll) =>
                                                <option key={payroll.id} value={payroll.id}>{payroll.description}</option>
                                            )}
                                    </Field>
                                    <span class="input__label">
                                        Tipo de nómina <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Start date */}
                        <div class="mb-3">
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <h5><label for="">Fecha inicial <span className='text-danger fw-bold'>*</span></label></h5>
                                </div>
                                <div class="col-md-8">
                                    <Field type="date" name="start" class="form-control input__field" required value={values.start || ''} />
                                </div>
                            </div>
                        </div>

                        {/* End date */}
                        <div class="mb-3">
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <h5><label for="">Fecha final <span className='text-danger fw-bold'>*</span></label></h5>
                                </div>
                                <div class="col-md-8">
                                    <Field type="date" name="end" class="form-control input__field" required value={values.end || ''} />
                                </div>
                            </div>
                        </div>

                        {/* Payment date */}
                        <div class="mb-3">
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <h5><label for="">Fecha de pago <span className='text-danger fw-bold'>*</span></label></h5>
                                </div>
                                <div class="col-md-8">
                                    <Field type="date" name="payment" class="form-control input__field" required value={values.payment || ''} />
                                </div>
                            </div>
                        </div>

                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                    <span class="input__label" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar periodo" : "Guardar periodo"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;