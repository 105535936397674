import React from "react";

function ConfigView({
    documents = [],
    onAddDocument,
    onRemoveDocument,
    onChangeDocument,
    onCancel,
    onSubmit,
}) {
  return <div className='container'>

    <div className="row my-2">
      <div className="col-md-6">
        <button type="button" className="btn btn-warning" onClick={onAddDocument}>
          <i className="bi bi-plus-circle" style={{marginRight: "7px"}}></i>
          Agregar documento
        </button>
      </div>
    </div>

    <div class="table-responsive datatable-custom position-relative">
      <div class="dataTables_wrapper no-footer" style={{maxHeight: "60vh"}}>
        <table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
          <thead>
            <tr role="row">
              <th style={{width: "70%"}}>Documento</th>
              <th style={{width: "30%"}} className="text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {
              documents.map((document, idx) => 
                <tr key={idx}>
                  <td>
                    <input type="text" className="form-control" value={document.name} onChange={(e) => onChangeDocument(idx, e.target.value)} />
                  </td>
                  <td className="text-center">
                    <button type="button" className="btn btn-danger" onClick={() => onRemoveDocument(idx)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              )
            }
            {
              documents.length === 0 &&
                <tr>
                  <td className="text-center" colSpan={2}>
                    No hay documentos configurados
                  </td>
                </tr>
            }
          </tbody>
        </table>
      </div>
    </div>

    <div class="pt-3">
      <button type="button" class="btn btn-primary" onClick={onSubmit}>
        <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
        Guardar
      </button>

      <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
        <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
        Cancelar
      </button>
    </div>
    
  </div>;
}

export default ConfigView;