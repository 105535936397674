import React, { useState, useEffect, useRef } from "react";
import { usePost, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import View from "components/crh/visits/visitRequests/Form.view";
import { toast } from "react-toastify";

function FormSave({
  setIsVisible = () => {},
}) {
  
  const formikRef = useRef();
  const [departments, setDepartments] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subBrands, setSubBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const [callCreateRequest, reqCreateRequest] = usePost("/visit_requests", {
    onCompleted: (data) => {
      if(!toast.isActive(`request-created`))
        toast.success("Visita creada con éxito", { toastId: `request-created` });
      setIsVisible(false);
    },
    onError: (error) => {
      showResponseMessage(error.status, "visit_requests", "solicitudes de visita", "create", "tarjeta");
    }
  });

  const [callDepartments, reqDepartments] = useGetCall("/departments", {
		onCompleted: (response) => {
			setDepartments(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "departments", "departamentos", "read")
		}
	})

  const [callBrands, reqBrands] = useGetCall("/brands", {
    onCompleted: (response) => {
      setBrands(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "brands", "marcas", "read")
    }
  })

  const [callSubBrands, reqSubBrands] = useGetCall("/sub_brands", {
    onCompleted: (response) => {
      setSubBrands(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "sub_brands", "sub marcas", "read")
    }
  })

  const [callColors, reqColors] = useGetCall("/colors", {
    onCompleted: (response) => {
      setColors(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "colors", "colores", "read")
    }
  })

  const [callDocumentTypes, reqDocumentTypes] = useGetCall("/document_types", {
    onCompleted: (response) => {
      setDocumentTypes(response.data)
    },
    onError: (error) => {
      showResponseMessage(error.status, "document_types", "tipos de documento", "read")
    }
  })

  const [callProfiles, reqProfiles] = useGetCall("/visit_profiles", {
		onCompleted: (response) => {
			setProfiles(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "visit_profiles", "perfiles", "read");
		}
	});

  const onSubmit = (values) => {

    if(!values.no_card) {
      if (!toast.isActive(`no-card`)) 
        toast.error(`Genere un QR para continuar`, { toastId: `no-card` });
      return;
    }

    callCreateRequest({
      ...values,
      status: "AUTH",
      origin: "CHR",
    });
  }

  useEffect(() => {
    callDepartments({ });
    callBrands({ });
    callSubBrands({ });
    callColors({ });
    callDocumentTypes({ });
    callProfiles({ });
  }, []);

  return <View
    profiles={profiles}
    departments={departments}
    brands={brands}
    subBrands={subBrands}
    colors={colors}
    documentTypes={documentTypes}
    formikRef={formikRef}
    setIsVisible={setIsVisible}
    onSubmit={onSubmit}
  />;

}

export default FormSave;