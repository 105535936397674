import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getShift } from "api/shifts";
import { toast } from "react-toastify";
import View from "components/crh/shifts/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [shift, setShift] = useState({});
    const [extra, setExtra] = useState(false);
    const [round, setRound] = useState(false);
    const [reset, setReset] = useState(() => { });
    const [automatic, setAutomatic] = useState(false);
    const [rotating, setRotating] = useState(false);
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/shifts", {
        onCompleted: () => {
            if (!toast.isActive("shift-updated"))
                toast.success("Turno actualizado", { toastId: "shift-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("shift-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "shift-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-edit-shift-unauthorized")) {
                    toast.error("Error, no tienes los permisos para editar turnos", { toastId: "toast-edit-shift-unauthorized" })
                }
            }  else if (err.status === 409) {
                if (!toast.isActive("shift-error-409")) {
                    toast.error("Ya existe un turno con esa clave, por favor ingrese otra", { toastId: "shift-error-409" });
                }
            } else {
                if (!toast.isActive("shift-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "shift-error-500" });
                }
            }
        }
    })

    useEffect(() => {
        getShift(id).then((response) => {
            if (response.data) {
                console.log(response.data);
                if (formikRef.current)
                    formikRef.current.setValues(response.data);

                setShift(response.data);
                setExtra(response.data.extra);
                setRound(response.data.round);
                setAutomatic(response.data.automatic);
                setRotating(response.data.rotating);
            }
        });
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        values.id = id;
        values.extra = extra;
        values.round = round;
        values.automatic = automatic;
        values.rotating = rotating;

        const { key, description, abbreviation, tolerance, type_extra, type_round, distribution_round } = values
        if (!key || !description || !abbreviation || !tolerance) {
            toast.error("Rellene los campos obligatorios");
            return;
        }

        if (extra && (!type_extra || type_extra === "")) {
            toast.error("Seleccione el tipo de extra");
            return;
        }

        if (round && (!type_round || type_round === "" || !distribution_round || distribution_round === "")) {
            toast.error("Seleccione el tipo de redondeo y la distribución");
            return;
        }

        if (rotating && (!values.start_date || values.start_date === "")) {
            toast.error("Para el turno rotativo se requiere ingresar una fecha de inicio");
            return;
        }

        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            shift={shift}
            onSubmit={onSubmit}
            onCancel={onCancel}
            extra={extra}
            round={round}
            automatic={automatic}
            rotating={rotating} 
            setRotating={setRotating}
            setExtra={setExtra}
            setRound={setRound}
            setAutomatic={setAutomatic}
        />
    )


}

export default FormSet;
