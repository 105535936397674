import React, { useState, useEffect, useRef } from 'react';
import { toast } from "react-toastify";
import View from "components/admin/enviroments/AttendanceLoad.view";
import { usePut, useDelete, useGetCall, usePost } from "utils/api";
import { downloadFile } from "utils/files";

function AttendanceLoad({ setIsVisible,  }) {

    const [company, setCompany] = useState(null);
    const [device, setDevice] = useState(null);

    const [companies, setCompanies] = useState([]);
    const [devices, setDevices] = useState([]);

    const [error, setError] = useState(null);

    const [csvData, setCsvData] = useState({});
    const [validations, setValidations] = useState([]);
    const inputFileRef = useRef(null);

    const [callValidate, reqValidate] = usePost("/attendance_logs/bulk_validate", {
        onCompleted: ({ data }) => {
            setError(false);
            setValidations(data.errors);
        },
        onError: (err) => {
            console.error(err)
            
            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-employees-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de empleados", { toastId: "toast-bulk-employees-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("El archivo se encuentra vacío, por favor rellene datos", { toastId: "toast-upload-error" });
                }
            }

        }
    });

    const [callSave, reqSave] = usePost("/attendance_logs/bulk", {
        onCompleted: () => {

            setError(false);
            if (!toast.isActive("toast-save-employees"));
            toast.success("Marcajes cargados correctamente", { toastId: "toast-save-employees" });

            setIsVisible(false);
            inputFileRef.current.value = "";
            setCsvData({});
            setValidations([]);

        },
        onError: (err) => {
            console.error(err)
            setError(true);

            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-employees-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de marcajes", { toastId: "toast-bulk-employees-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("Error al cargar los datos", { toastId: "toast-upload-error" });
                }
            }
        }
    });

    const [callCompanies, reqCompanies] = useGetCall("/companies", {
		onCompleted: (response) => {
			setCompanies(response.data)
		},
		onError: (error) => {
			// showResponseMessage(error.status, "devices", "lectores", "read")
		}
	});

    const [callDevices, reqDevices] = useGetCall("/devices", {
		onCompleted: (response) => {
			setDevices(response.data)
		},
		onError: (error) => {
			// showResponseMessage(error.status, "devices", "lectores", "read")
		}
	});

    const onDownloadExampleFile = () => {
        const exampleFile = "ID de Empleado, ID en Lector, Fecha de marcaje (DD/MM/YYYY), Hora de marcaje (HH:MM:SS)\n";
        downloadFile({ data: exampleFile, filename: "template_attendance.csv", type: ".csv" });
    }

    const onReset = () => {
        inputFileRef.current.value = "";
        setCsvData({});
        setValidations([]);
    }

    const onLoadFile = (data) => {
        if(data.length === 1) {
            const rows = data[0]?.rows?.map(row => { return row.slice(0, 4); });
            callValidate({ company_id: company?.id, device_id: device?.id, rows: rows, headers: data[0].headers });
            setCsvData({
                headers: data[0].headers,
                rows: rows
            });
        };
    }

    const onSubmit = (values, {resetForm}) => {
        callSave({
            company_id: company?.id,
            device_id: device?.id,
            rows: csvData.rows,
            headers: csvData.headers
        });
    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    const onChangeCompany = (company) => {
        setCompany(company);
        setDevice(null);
        setValidations([]);
        inputFileRef.current.value = "";
    }

    const onChangeDevice = (device) =>
        setDevice(device);

    useEffect(() => {
        callCompanies({ });
    }, []);

    useEffect(() => {
        callDevices({ company: company?.id });
    }, [company]);

    useEffect(() => {
        if(csvData.rows && csvData.rows.length > 0)
            callValidate({ company_id: company?.id, device_id: device?.id, rows: csvData.rows, headers: csvData.headers });
    }, [device, company, csvData]);

    return (
        <View 
            inputFileRef={inputFileRef}
            csvData={csvData}
            companies={companies}
            devices={devices}
            validations={validations}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            onLoadFile={onLoadFile}
            onReset={onReset}
            onDownloadExampleFile={onDownloadExampleFile}
            onChangeCompany={onChangeCompany}
            onChangeDevice={onChangeDevice}
            setIsVisible={setIsVisible}
            setError={setError}
            setCompany={setCompany}
        />
    )
}

export default AttendanceLoad;