import { API_URL } from "settings";
import { getUrlencoded } from "utils/api";

function logout() {
    sessionStorage.clear();
    localStorage.clear();
}

const validateAuth = async (payload, login_type = "CHR") => {

	try{

		payload.login_type = login_type;

		const url = `${API_URL}/auth/check`;
		const params = {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"x-auth-token": sessionStorage.getItem("token")
			},
			body: getUrlencoded(payload),
		}

		const response = await fetch(url, params);

		if(response.status !== 200) {
			logout();
			return false;
		}

		const body = await response.json();

		sessionStorage.setItem("employee_id", body.data.employee_id)
		sessionStorage.setItem("employee_key", body.data.employee_key)
		sessionStorage.setItem("geolocation", body.data.geolocation)
		sessionStorage.setItem("take_geolocation_photo", body.data.take_geolocation_photo??false)
		sessionStorage.setItem("take_register_photo", body.data.take_register_photo??false)
		sessionStorage.setItem("hour_holidays", body.data.hour_holidays);

		return true;

	} 
	catch(error) {
		console.error(error);
		logout();
		return false;
	}
}


export { logout, validateAuth }