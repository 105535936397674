import Home from "components/home/Home";
import HomeAdmin from "components/home/HomeAdmin";

import Employees from "components/crh/employees/Employees";
import Jobs from "components/crh/jobs/Jobs";
import Departments from "components/crh/departments/Departments";
import Offices from "components/crh/offices/Offices";
import CostCenters from "components/crh/costCenters/CostCenters";
import Areas from "components/crh/areas/Areas";
import SubAreas from "components/crh/subareas/Subareas";
import Bosses from "components/crh/bosses/Bosses";

import Shifts from "components/crh/shifts/Shifts";
import Schedules from "components/crh/schedules/Schedules";
import Timetables from "components/crh/timetables/Timetables";

import Payrolls from "components/crh/payrolls/Payrolls";
import PayrollPeriods from "components/crh/payrollPeriods/PayrollPeriods";
import Regimes from "components/crh/regimes/Regimes";
import Contracts from "components/crh/contracts/Contracts";
import ReasonsLeave from "components/crh/reasonsLeave/ReasonsLeave";

import Devices from "components/crh/devices/Devices";
import EmployeeDevices from "components/crh/employeeDevices/EmployeeDevices";
import DeviceEmployees from "components/crh/deviceEmployees/DeviceEmployees";

/* Reports */
import Attendance from "components/crh/reports/attendance/Attendance";
import AttendanceNotRegistered from "components/crh/reports/attendanceNotRegistered/AttendanceNotRegistered";
import IncidencesReport from "components/crh/reports/incidences/IncidencesReport";
import PuntualityReport from "components/crh/reports/puntuality/PuntualityReport";
import AttendanceReport from "components/crh/reports/attendanceReport/AttendanceReport";
import FoulsReport from "components/crh/reports/foul/FoulsReport";
import RetardmentsReport from "components/crh/reports/retardment/RetardmentsReport";
import EntryOmissionsReport from "components/crh/reports/entryOmission/EntryOmissionsReport";
import ExitOmissionsReport from "components/crh/reports/exitOmission/ExitOmissionsReport";
import EarlyDepartureReport from "components/crh/reports/earlyDeparture/EarlyDepartureReport";
import ExtraTimeReport from "components/crh/reports/extraTime/ExtraTimeReport";
import WorkedRestDayReport from "components/crh/reports/workedRestDay/WorkedRestDayReport";
import WorkedBankHolidayReport from "components/crh/reports/workedBankHoliday/WorkedBankHolidayReport";
import VacationBonusReport from "components/crh/reports/vacationBonus/VacationBonusReport";
import IncidenceRequestReport from "components/crh/reports/incidenceRequest/IncidenceRequestReport";

import Profile from "components/crh/profile/Profile";

/* Admin options */
import Users from "components/crh/users/Users";
import Series from "components/crh/series/Series";
import Permissions from "components/crh/permissions/Permissions";

// Incidences
import Incidences from "components/crh/incidences/Incidences";
import IncidenceRequests from "components/crh/incidenceRequests/IncidenceRequests";
import IncidencesAuth from "components/crh/incidencesAuth/IncidencesAuth";

// Super admin
import Enviroments from "components/admin/enviroments/Enviroments";
import SuperUsers from "components/admin/users/Users";

import DeviceManager from "components/crh/deviceManager/DeviceManager";
import AdminReport from "components/crh/adminReport/AdminReport";
import HolidaysTables from "components/crh/holidaysTables/HolidaysTables";
import HolidayLogs from "components/crh/holidayLogs/HolidayLogs";
import Parameters from "components/crh/parameters/Parameters";
import Processes from "components/crh/processes/Processes";
import BankHolidays from "components/crh/bankHolidays/BankHolidays";
import GroupGroups from "components/crh/groups/Groups";
import DocumentEmployees from "components/crh/documentEmployees/DocumentEmployees";
import TimetableHistory from "components/crh/timetableHistory/TimetableHistory";
import Plannings from "components/crh/plannings/Plannings";

// Visits
import Brands from "components/crh/visits/brands/Brands";
import Colors from "components/crh/visits/colors/Colors";
import SubBrands from "components/crh/visits/subBrands/SubBrands";
import DocumentTypes from "components/crh/visits/documentTypes/DocumentTypes";
import Profiles from "components/crh/visits/profiles/Profiles";
import Requests from "components/crh/visits/requests/Requests";
import VisitRequests from "components/crh/visits/visitRequests/Requests";
import VisitAttendanceLogs from "components/crh/visits/attendanceLogs/Attendance";

const getComponent = (componentId, props = {}) => {
    if (componentId === 0) return <Home {...props} />
    if (componentId === 1) return <Employees {...props} />
    if (componentId === 2) return <Jobs {...props} />
    if (componentId === 3) return <Departments {...props} />
    if (componentId === 4) return <Offices {...props} />
    if (componentId === 5) return <CostCenters {...props} />
    if (componentId === 6) return <Areas {...props} />
    if (componentId === 7) return <Bosses {...props} />
    if (componentId === 8) return <SubAreas {...props} />
    if (componentId === 9) return <Shifts {...props} />
    if (componentId === 10) return <Schedules {...props} />
    if (componentId === 11) return <Timetables {...props} />
    if (componentId === 12) return <Payrolls {...props} />
    if (componentId === 13) return <PayrollPeriods {...props} />
    if (componentId === 14) return <Regimes {...props} />
    if (componentId === 15) return <Contracts {...props} />
    if (componentId === 16) return <ReasonsLeave {...props} />
    if (componentId === 17) return <Devices {...props} />
    if (componentId === 18) return <EmployeeDevices {...props} />
    if (componentId === 19) return <DeviceEmployees {...props} />
    if (componentId === 20) return <AdminReport {...props} />
    if (componentId === 21) return <DeviceManager {...props} />
    if (componentId === 22) return <Attendance {...props} />
    if (componentId === 23) return <AttendanceNotRegistered {...props} />
    if (componentId === 24) return <Incidences {...props} />
    if (componentId === 25) return <IncidenceRequests {...props} />
    if (componentId === 26) return <IncidencesAuth {...props} />
    if (componentId === 27) return <HolidaysTables {...props} />
    if (componentId === 28) return <Users {...props} />
    if (componentId === 29) return <Enviroments {...props} />
    if (componentId === 30) return <HolidayLogs {...props} />
    if (componentId === 31) return <Series {...props} />
    if (componentId === 32) return <SuperUsers {...props} />
    if (componentId === 33) return <Profile {...props} />
    if (componentId === 34) return <Processes {...props} />
    if (componentId === 35) return <Parameters {...props} />
    if (componentId === 36) return <BankHolidays {...props} />
    if (componentId === 37) return <IncidencesReport {...props} />
    if (componentId === 38) return <PuntualityReport {...props} />
    if (componentId === 39) return <AttendanceReport {...props} />
    if (componentId === 40) return <GroupGroups {...props} />
    if (componentId === 41) return <DocumentEmployees {...props} />
    if (componentId === 42) return <TimetableHistory {...props} />
    if (componentId === 45) return <FoulsReport {...props} />
    if (componentId === 46) return <RetardmentsReport {...props} />
    if (componentId === 50) return <EntryOmissionsReport {...props} />
    if (componentId === 51) return <ExitOmissionsReport {...props} />
    if (componentId === 52) return <EarlyDepartureReport {...props} />
    if (componentId === 53) return <ExtraTimeReport {...props} />
    if (componentId === 54) return <WorkedRestDayReport {...props} />
    if (componentId === 55) return <WorkedBankHolidayReport {...props} />
    if (componentId === 56) return <VacationBonusReport {...props} />
    if (componentId === 57) return <IncidenceRequestReport {...props} />
    if (componentId === 58) return <Permissions {...props} />
    if (componentId === 60) return <HomeAdmin {...props} />
    if (componentId === 61) return <Plannings {...props} />
    if (componentId === 62) return <Brands {...props} />
    if (componentId === 63) return <Colors {...props} />
    if (componentId === 64) return <SubBrands {...props} />
    if (componentId === 65) return <DocumentTypes {...props} />
    if (componentId === 66) return <Profiles {...props} />
    if (componentId === 67) return <Requests {...props} />
    if (componentId === 68) return <VisitRequests {...props} />
    if (componentId === 69) return <VisitAttendanceLogs {...props} />

}

const getNameIncidence = (type) => {
    if (type === "FOUL") return "Falta";
    if (type === "RETARDMENT") return "Retardo";
    if (type === "EARLY_DEPARTURE") return "Salida Anticipada";
    if (type === "ENTRY_OMISSION") return "Omisión de entrada";
    if (type === "EXIT_OMISSION") return "Omisión de salida";
    if (type === "EXTRA_TIME") return "Tiempo extra";
    if (type === "WORKED_HOLIDAYS") return "Vacaciones trabajadas";
    if (type === "WORKED_BANK_HOLIDAY") return "Día Festivo trabajado";
    if (type === "VACATION_BONUS") return "Prima dominical";
    if (type === "REST_DAY") return "Día de descanso";
    if (type === "WORKED_REST_DAY") return "Día de descanso trabajado";
    if (type === "BANK_HOLIDAY") return "Día Festivo";
    if (type === "LICENSE") return "Licencia (Incapacidad)";
    if (type === "PERMISSION_WITH_PAY") return "Permiso con goce de sueldo";
    if (type === "PERMISSION_WITHOUT_PAY") return "Permiso sin goce de sueldo";
    if (type === "PERMISSION_RELEASE_EARLY") return "Permiso para salir temprano";
    if (type === "PERMISSION_ARRIVE_LATE") return "Permiso para llegar tarde";
    if (type === "PERMISSION_LEAVE_HOURS") return "Permiso para salir entre horas";
    if (type === "JUSTIFY_ENTRY_OMISSION") return "Justificar omisión de entrada";
    if (type === "JUSTIFY_EXIT_OMISSION") return "Justificar omisión de salida";
    if (type === "HOLIDAYS") return "Vacaciones";
    if (type === "ECONOMIC_DAY") return "Día Económico";
    if (type === "INSERT_MARKINGS") return "Insertar marcajes";
    if (type === "ATTENDANCE") return "Asistencia";
    if (type === "HOURLY_VACATIONS" ) return "Vacaciones por hora";
    if (type === "LEAVE") return "Baja";
    return "";
}

const automaticIncidences = [
    "FOUL",
    "RETARDMENT",
    "EARLY_DEPARTURE",
    "ENTRY_OMISSION",
    "EXIT_OMISSION",
    "EXTRA_TIME",
    "WORKED_HOLIDAYS",
    "WORKED_BANK_HOLIDAY",
    "VACATION_BONUS",
    "REST_DAY",
    "WORKED_REST_DAY",
    "BANK_HOLIDAY"
]

const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
]

const getWeekDayName = (weekDay) => {
    if (weekDay === "MONDAY") return "Lunes";
    if (weekDay === "TUESDAY") return "Martes";
    if (weekDay === "WEDNESDAY") return "Miércoles";
    if (weekDay === "THURSDAY") return "Jueves";
    if (weekDay === "FRIDAY") return "Viernes";
    if (weekDay === "SATURDAY") return "Sábado";
    if (weekDay === "SUNDAY") return "Domingo";
    return "";
}

const getAbbreviationIncidence = (type) => {
    if (type === "ATTENDANCE") return "A";
    if (type === "FOUL") return "F";
    if (type === "RETARDMENT") return "R";
    if (type === "EARLY_DEPARTURE") return "SA";
    if (type === "ENTRY_OMISSION") return "OE";
    if (type === "EXIT_OMISSION") return "OS";
    if (type === "EXTRA_TIME") return "TE";
    if (type === "WORKED_HOLIDAYS") return "VT";
    if (type === "WORKED_BANK_HOLIDAY") return "DFT";
    if (type === "VACATION_BONUS") return "PD";
    if (type === "REST_DAY") return "DD";
    if (type === "WORKED_REST_DAY") return "DDT";
    if (type === "BANK_HOLIDAY") return "DF";
    if (type === "LICENSE") return "L";
    if (type === "PERMISSION_WITH_PAY") return "PGS";
    if (type === "PERMISSION_WITHOUT_PAY") return "PSGS";
    if (type === "PERMISSION_RELEASE_EARLY") return "PST";
    if (type === "PERMISSION_ARRIVE_LATE") return "PLT";
    if (type === "PERMISSION_LEAVE_HOURS") return "PSEH";
    if (type === "JUSTIFY_ENTRY_OMISSION") return "JOE";
    if (type === "JUSTIFY_EXIT_OMISSION") return "JOS";
    if (type === "HOLIDAYS") return "V";
    if (type === "ECONOMIC_DAY") return "DE";
    if (type === "INSERT_MARKINGS") return "IM";
    if (type === "HOURLY_VACATIONS" ) return "VH";
    if (type === "LEAVE") return "B";
}

const permissionTables = [
    {
        category: "HOME",
        tables: ["HOME"]
    },
    {
        category: "CATALOGUES",
        tables: ["EMPLOYEE", "JOB", "DEPARTMENT", "OFFICE", "COST_CENTER", "AREA", "BOSS", "SUBAREA", "RECORD", "SHIFT",
            "SCHEDULE", "TIMETABLE", "TIMETABLE_HISTORY", "PLANNING", "PAYROLL", "PAYROLL_PERIOD", "REGIME", "CONTRACT", "REASON_LEAVE"]
    },
    {
        category: "DEVICES",
        tables: ["DEVICE", "EMPLOYEE_DEVICE", "DEVICE_EMPLOYEE", "ADMIN_REPORT", "DEVICE_MANAGER"]
    },
    {
        category: "PARAMETERS",
        tables: ["PARAMETERS"]
    },
    {
        category: "REPORTS",
        tables: ["ATTENDACE_REGISTER_REPORT", "ATTENDACE_NO_REGISTER_REPORT", "HOLIDAYS_MANAGE_REPORT", "INCIDENCES_REPORT", "ATTENDACE_REPORT",
            "PUNTUALITY_REPORT", "FOUL_REPORT", "RETARDMENT_REPORT", "ENTRY_OMISSION_REPORT", "EXIT_OMISSION_REPORT", "EARLY_DEPARTURE_REPORT",
            "EXTRA_TIME_REPORT", "WORKED_REST_DAYS_REPORT", "WORKED_BANK_HOLIDAYS_REPORT", "WORKED_SUNDAY_REPORT", "INCIDENCE_REQUESTS_REPORT"]
    },
    {
        category: "INCIDENCES",
        tables: ["INCIDENCES", "INCIDENCE_REQUESTS", "AUTH_INCIDENCE_REQUESTS", "HOLDAY_TABLE", "BANK_HOLIDAYS", "INCIDENCE_PROCESS"]
    },
    {
        category: "ADMIN",
        tables: ["SERIE", "USER", "PERMISSION", "WORK_GROUPS"]
    },
    {
        category: "VISITS",
        tables: ["BRANDS", "SUB_BRANDS", "COLORS", "DOCUMENT_TYPES", "VISIT_PROFILES", "VISIT_REGISTER", "VISIT_ATTENDACE_REGISTER_REPORT"]
    }
]

const permissionTablesArray = ["HOME", "EMPLOYEE", "JOB", "DEPARTMENT", "OFFICE", "COST_CENTER", "AREA", "BOSS", "SUBAREA", "RECORD", "SHIFT",
    "SCHEDULE", "TIMETABLE", "TIMETABLE_HISTORY", "PLANNING", "PAYROLL", "PAYROLL_PERIOD", "REGIME", "CONTRACT", "REASON_LEAVE", "DEVICE", "EMPLOYEE_DEVICE",
    "DEVICE_EMPLOYEE", "ADMIN_REPORT", "DEVICE_MANAGER", "PARAMETERS", "ATTENDACE_REGISTER_REPORT", "ATTENDACE_NO_REGISTER_REPORT", "HOLIDAYS_MANAGE_REPORT",
    "INCIDENCES_REPORT", "ATTENDACE_REPORT", "PUNTUALITY_REPORT", "FOUL_REPORT", "RETARDMENT_REPORT", "ENTRY_OMISSION_REPORT", "EXIT_OMISSION_REPORT",
    "EARLY_DEPARTURE_REPORT", "EXTRA_TIME_REPORT", "WORKED_REST_DAYS_REPORT", "WORKED_BANK_HOLIDAYS_REPORT", "WORKED_SUNDAY_REPORT", "INCIDENCE_REQUESTS_REPORT",
    "INCIDENCES", "INCIDENCE_REQUESTS", "AUTH_INCIDENCE_REQUESTS", "HOLDAY_TABLE", "BANK_HOLIDAYS", "INCIDENCE_PROCESS", "SERIE", "USER", "PERMISSION", "WORK_GROUPS",
    "VISITS", "BRANDS", "SUB_BRANDS", "COLORS", "DOCUMENT_TYPES", "VISIT_PROFILES", "VISIT_REGISTER", "VISIT_ATTENDACE_REGISTER_REPORT"]

const permissionTypes = ["access", "create", "edit", "delete", "read", "excel", "pdf", "load"]

const getPermissionTableName = (table) => {

    if (table === "HOME") return "Tablero";

    if (table === "EMPLOYEE") return "Empleados";
    if (table === "JOB") return "Puestos";
    if (table === "DEPARTMENT") return "Departamentos";
    if (table === "OFFICE") return "Sucursal";
    if (table === "COST_CENTER") return "Centro de costos";
    if (table === "AREA") return "Áreas";
    if (table === "BOSS") return "Jefe directo";
    if (table === "SUBAREA") return "Subáreas";
    if (table === "RECORD") return "Expediente";

    if (table === "SHIFT") return "Turnos";
    if (table === "SCHEDULE") return "Horarios";
    if (table === "TIMETABLE") return "Turnos/Horarios";
    if (table === "TIMETABLE_HISTORY") return "Histórico de turnos";
    if (table === "PLANNING") return "Planeación semanal";

    if (table === "PAYROLL") return "Tipo de nómina";
    if (table === "PAYROLL_PERIOD") return "Periodo de nómina";
    if (table === "REGIME") return "Tipos de régimen";
    if (table === "CONTRACT") return "Tipos de contrato";
    if (table === "REASON_LEAVE") return "Motivos de baja";

    if (table === "DEVICE") return "Lectores";
    if (table === "EMPLOYEE_DEVICE") return "Empleados a lectores";
    if (table === "DEVICE_EMPLOYEE") return "Lectores a empleados";
    if (table === "ADMIN_REPORT") return "Reporte de administradores";
    if (table === "DEVICE_MANAGER") return "Gestión de lectores";

    if (table === "PARAMETERS") return "Parametros";

    if (table === "ATTENDACE_REGISTER_REPORT") return "Asistencia empleados registrados";
    if (table === "ATTENDACE_NO_REGISTER_REPORT") return "Asistencia empleados no registrados";
    if (table === "HOLIDAYS_MANAGE_REPORT") return "Estado de cuenta de vacaciones";
    if (table === "INCIDENCES_REPORT") return "Reporte de incidences";
    if (table === "ATTENDACE_REPORT") return "Reporte de asistencia";
    if (table === "PUNTUALITY_REPORT") return "Reporte de puntualidad";
    if (table === "FOUL_REPORT") return "Reporte de faltas";
    if (table === "RETARDMENT_REPORT") return "Reporte de retardos";
    if (table === "ENTRY_OMISSION_REPORT") return "Reporte de omisión de entrada";
    if (table === "EXIT_OMISSION_REPORT") return "Reporte de omisión de salida";
    if (table === "EARLY_DEPARTURE_REPORT") return "Reporte de salida anticipada";
    if (table === "EXTRA_TIME_REPORT") return "Reporte de tiempo extra";
    if (table === "WORKED_REST_DAYS_REPORT") return "Reporte de descansos trabajados";
    if (table === "WORKED_BANK_HOLIDAYS_REPORT") return "Reporte de días festivos trabajados";
    if (table === "WORKED_SUNDAY_REPORT") return "Reporte de domingos trabajados";
    if (table === "INCIDENCE_REQUESTS_REPORT") return "Reporte de solicitud de incidencias";

    if (table === "INCIDENCES") return "Incidencias";
    if (table === "INCIDENCE_REQUESTS") return "Solicitud de incidencias";
    if (table === "AUTH_INCIDENCE_REQUESTS") return "Autorización de incidencias";
    if (table === "HOLDAY_TABLE") return "Tablas de vacaciones";
    if (table === "BANK_HOLIDAYS") return "Días festivos";
    if (table === "INCIDENCE_PROCESS") return "Proceso de incidencias";

    if (table === "SERIE") return "Series";
    if (table === "USER") return "Usuarios";
    if (table === "PERMISSION") return "Permisos de usuarios";
    if (table === "WORK_GROUPS") return "Grupos de trabajo";

    if (table === "VISITS") return "Visitas";
    if (table === "BRANDS") return "Marcas";
    if (table === "SUB_BRANDS") return "Submarcas";
    if (table === "COLORS") return "Colores";
    if (table === "DOCUMENT_TYPES") return "Tipos de documento";
    if (table === "VISIT_PROFILES") return "Perfiles de visita";
    if (table === "VISIT_REGISTER") return "Registro de visitas";
    if (table === "VISIT_ATTENDACE_REGISTER_REPORT") return "Reporte de visitas";

    return "";
}

const getPermissionTypeName = (type) => {
    if (type === "access") return "Habilitar";
    if (type === "create") return "Añadir";
    if (type === "edit") return "Editar";
    if (type === "delete") return "Borrar";
    if (type === "read") return "Ver";
    if (type === "excel") return "Generar Excel";
    if (type === "pdf") return "Generar PDF";
    if (type === "load") return "Carga Masiva";
    return "";
}

const getPermissionCategoryName = (category) => {
    if (category === "HOME") return "Tablero";
    if (category === "CATALOGUES") return "Catálogos";
    if (category === "DEVICES") return "Lectores";
    if (category === "PARAMETERS") return "Parámetros";
    if (category === "REPORTS") return "Reportes";
    if (category === "INCIDENCES") return "Incidencias";
    if (category === "ADMIN") return "Administración";
    if (category === "VISITS") return "Visitas";
    return "";
}

const hexToDecimal = hex => parseInt(hex, 16);

function getColorIncidence(background) {
	const color = background.replace("#", "");

	const red = hexToDecimal(color.substring(0, 2));
	const green = hexToDecimal(color.substring(2, 4));
	const blue = hexToDecimal(color.substring(4, 6));

	const maxNorm = Math.sqrt(Math.pow(255, 2) + Math.pow(255, 2) + Math.pow(255, 2))
	const norm = Math.sqrt(Math.pow(red, 2) + Math.pow(green, 2) + Math.pow(blue, 2));

	if (norm / maxNorm >= 0.7) {
		return "#000000";
	} else {
		return "#FFFFFF";
	}

}

let weekDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]

export {
    weekDays,
    months,
    getComponent,
    getNameIncidence,
    automaticIncidences,
    getWeekDayName,
    getAbbreviationIncidence,
    permissionTables,
    permissionTypes,
    getPermissionTableName,
    getPermissionTypeName,
    getPermissionCategoryName,
    permissionTablesArray,
    getColorIncidence
}