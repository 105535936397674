import React from "react";
import moment from "moment";
import "moment/locale/es-mx";
moment.locale('es');

const getEmployeeName = (employee) => {
	if (employee && employee.firstname && employee.mom_lastname && employee.dad_lastname) {
		return `${employee.firstname} ${employee.dad_lastname} ${employee.mom_lastname}`;
	} else {
		return ""
	}
}

function DetailsView({ employee, onSubmit, onCancel }) {

	return (
		<div className='card'>

			<div class="card-body">

				<h3 class="card-title">Detalles de la búsqueda</h3>

				<ul class="list-group list-group-flush">

					<li class="list-group-item">
						Nombre Empleado: {"  "}
						<span style={{ color: "#04b5fc" }}>{getEmployeeName(employee)}</span>
					</li>
					<li class="list-group-item">
						RFC: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee?.rfc ? employee.rfc : "Sin registro"}</span>
					</li>
					<li class="list-group-item">
						CURP: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee?.curp ? employee.curp : "Sin registro"}</span>
					</li>
					<li class="list-group-item">
						Turno Asignado: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee?.shift ? employee.shift.description : ""}</span>
					</li>
					<li class="list-group-item">
						Puesto: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee?.job ? employee.job.description : ""}</span>
					</li>
					<li class="list-group-item">
						Departamento: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee?.department ? employee.department.description : ""}</span>
					</li>
					<li class="list-group-item">
						Tipo de nómina: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee?.payroll ? employee.payroll.description : ""}</span>
					</li>
					<li class="list-group-item">
						Fecha de ingreso: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee?.admission ? moment(employee.admission).format("DD/MM/YYYY") : ""}</span>
					</li>
					<li class="list-group-item">
						Grupo de trabajo: {"  "}
						<span style={{ color: "#04b5fc" }}>{employee ? "Sin grupo de trabajo" : ""}</span>
					</li>
				</ul>

				<div class="pt-5">
					<button type="submit" class="btn btn-primary" onClick={onSubmit}>
						<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
						Guardar solicitud
					</button>

					<button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
						<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
						Cancelar
					</button>
				</div>

			</div>
		</div>
	);
}

export default DetailsView;