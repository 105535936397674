import React, { useEffect, useState } from "react";
import { usePost, useGetCall } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/visits/profiles/Form.view";

function FormSave({ setIsVisible }) {

  const [error, setError] = useState(null);
  const [reset, setReset] = useState(() => { });

  const [profileDevices, setProfileDevices] = useState({});
  const [devices, setDevices] = useState([]);

  const [callDevices, reqDevices] = useGetCall("/devices", {
		onCompleted: (response) => {
			setDevices(response.data)
		},
		onError: (error) => {
			showResponseMessage(error.status, "devices", "lectores", "read")
		}
	})

  const [callSave, reqSave] = usePost("/visit_profiles", {
    onCompleted: () => {
      if (!toast.isActive("profile-created"))
        toast.success("Perfil creado correctamente", { toastId: "profile-created" });

      setIsVisible(false);
      reset();
    },
    onError: (error) => {
      console.error(error)
      showResponseMessage(error.status, "visitProfile", "perfiles", "create")
    }
  })

  const onSubmit = (values, { resetForm }) => {

    for (let key in values)
      if (values[key] === "")
        values[key] = null

    const { key, description, abbreviation } = values
    if (!key || !description || !abbreviation) {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    setReset(() => () => resetForm({}));
    callSave({ ...values, devices: profileDevices });

  }

  const onCancel = (resetForm) => {
    setIsVisible(false);
    resetForm({});
  }

  useEffect(() => {
    callDevices({ });
  }, [])

  return (
    <View
      onSubmit={onSubmit}
      onCancel={onCancel}
      error={error}
      devices={devices}
      profileDevices={profileDevices}
      setProfileDevices={setProfileDevices}
      setIsVisible={setIsVisible}
      setError={setError}
    />
  )
}

export default FormSave;