import React from "react";
import List from "components/crh/processes/List";
import SelectList from "helpers/SelectList";
import 'resources/styles/Incidences.scss';

function ProcessesView({ 
	offices, 
	payrolls, 
	payrollPeriods, 
	selectedOffices,
	selectedPayroll,
	selectedPayrollPeriod,
	onChangePayroll,
	onCheckOffice,
	setSelectedPayroll,
	setSelectedPayrollPeriod,
	onExecute
}) {
	return (
		<>
			<div className="content container-fluid pt-5 px-5">
				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Proceso de incidencias</h1>
						</div>
						<div className="col-sm-auto">
							<button type="button" className="btn btn-primary" onClick={onExecute}>
								<i className="bi bi-plus-circle" style={{marginRight: "7px"}}></i>
								Ejecutar proceso
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="row mx-5">
				<div className="col">
					
					<div class="table-responsive datatable-custom position-relative">
						<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
							<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
								<thead>
									<tr role="row">
										<th style={{width: "90%"}}>Seleccionar sucursal</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										offices && offices.map((office) =>
											<tr>
												<td>
													{office.description}
												</td>
												<td>
													<div className="custom-control custom-checkbox">
														<input 
															type="checkbox" 
															className="custom-control-input" 
															id={office.id} 
															onChange={() => onCheckOffice(office.id)} 
															checked={selectedOffices[office.id]}
														/>
													</div>
												</td>
											</tr>
										)
									}
								</tbody>
							</table>
						</div>
					</div>

					<SelectList 
						title="Seleccionar tipo de nómina" 
						options={payrolls.map(payroll => ({label: payroll.description, value: payroll.id}))}
						values={payrolls}
						onChange={onChangePayroll} 
						multiple={false}
					/>

					<SelectList
						title="Seleccionar periodo de nómina"
						options={payrollPeriods.map(payrollPeriod => ({label: payrollPeriod.description, value: payrollPeriod.id}))}
						onChange={setSelectedPayrollPeriod}
						values={payrollPeriods}
						multiple={false}
					/>

					{/* <div class="table-responsive datatable-custom position-relative">
						<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
							<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
								<thead>
									<tr role="row">
										<th style={{width: "90%"}}>Seleccionar tipo de nómina</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										payrolls && payrolls.map((payroll) =>
											<tr>
												<td>
													{payroll.description}
												</td>
												<td>
													<div className="custom-control custom-checkbox">
														<input 
															type="checkbox" 
															className="custom-control-input" 
															id={payrolls.id} 
															onChange={() => onChangePayroll(payroll)}
															checked={payroll.id === selectedPayroll.id}
														/>
													</div>
												</td>
											</tr>
										)
									}
								</tbody>
							</table>
						</div>
					</div> */}

					{/* <div class="table-responsive datatable-custom position-relative">
						<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
							<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
								<thead>
									<tr role="row">
										<th style={{width: "90%"}}>Seleccionar periodo</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										payrollPeriods && payrollPeriods.map((payrollPeriod) =>
											<tr>
												<td>
													{payrollPeriod.description}
												</td>
												<td>
													<div className="custom-control custom-checkbox">
														<input 
															type="checkbox" 
															className="custom-control-input" 
															id={payrolls.id} 
															onChange={() => setSelectedPayrollPeriod(payrollPeriod)}
															checked={payrollPeriod.id === selectedPayrollPeriod.id}
														/>
													</div>
												</td>
											</tr>
										)
									}
								</tbody>
							</table>
						</div>
					</div> */}

				</div>	
				<div className="col">
					<List refresh={Math.random()} />
				</div>
			</div>
		</>
	);
}

export default ProcessesView;