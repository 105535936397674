import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getSubarea } from "api/subareas";
import { getAreas } from "api/areas";
import { toast } from "react-toastify";
import View from "components/crh/subareas/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [subarea, setSubarea] = useState({});
    const [areas, setAreas] = useState([]);
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/subareas", {
        onCompleted: () => {
            if (!toast.isActive("subarea-updated"))
                toast.success("Subarea actualizada", { toastId: "subarea-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("subarea-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "subarea-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-edit-subarea-unauthorized")) {
                    toast.error("Error, no tienes los permisos para editar subareas", { toastId: "toast-edit-subarea-unauthorized" })
                }
            } else if (err.status === 409) {
                if (!toast.isActive("subarea-error-409")) {
                    toast.error("Ya existe una subarea con esa clave, por favor ingrese otra", { toastId: "subarea-error-409" });
                }
            } else {
                if (!toast.isActive("subarea-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "subarea-error-500" });
                }
            }
        }
    })

    useEffect(() => {
        getAreas().then(response => {
            if (response.data)
                setAreas(response.data);
        });

        getSubarea(id).then((response) => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);
                setSubarea(response.data);
            }
        });
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation, area } = values
        if (!key || !description || !abbreviation || !area) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            areas={areas}
            formikRef={formikRef}
            error={error}
            edit={true}
            subarea={subarea}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
