import React, { useState, useRef, useEffect } from "react";
import { usePost, useGetCall } from "utils/api";
import { toast } from "react-toastify";
import { downloadFile } from "utils/files";
import { showResponseMessage } from "utils/message";
import View from "components/crh/plannings/BulkLoad.view";

function BulkLoad({ title, isVisible, setIsVisible }) {

    const [error, setError] = useState(true);
    const [csvData, setCsvData] = useState([]);
    const [validations, setValidations] = useState([]);
    const [payrollPeriods, setPayrollPeriods] = useState([]);
    const [planningValues, setPlanningValues] = useState({});
    const inputFileRef = useRef(null);

    const [callValidate, reqValidate] = usePost("/plannings/bulk_validate", {
        onCompleted: ({ data }) => {
            setError(false);
            setValidations(data.errors);
        },
        onError: (err) => {
            console.error(err)
            
            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-planning-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de planeación", { toastId: "toast-bulk-planning-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("El archivo se encuentra vacío, por favor rellene datos", { toastId: "toast-upload-error" });
                }
            }

        }
    });

    const [callSave, reqSave] = usePost("/plannings/bulk", {
        onCompleted: () => {

            setError(false);
            if (!toast.isActive("toast-save-planning"));
            toast.success("Planeación cargada correctamente", { toastId: "toast-save-planning" });

            setIsVisible(false);
            inputFileRef.current.value = "";
            setCsvData([]);
            setValidations([]);

        },
        onError: (err) => {
            console.error(err)
            setError(true);

            if (err.status === 400) {
                if (!toast.isActive("toast-upload-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "toast-upload-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-bulk-planning-unauthorized")) {
                    toast.error("Error, no tienes los permisos para hacer carga masiva de planeación", { toastId: "toast-bulk-planning-unauthorized" })
                }
            } else {
                if (!toast.isActive("toast-upload-error")) {
                    toast.error("Error al cargar los datos", { toastId: "toast-upload-error" });
                }
            }
        }
    });

    const [callGetPayrollPeriods, reqPayrollPeriods] = useGetCall("/payroll_periods", {
      onCompleted: (response) => {
          setPayrollPeriods(response.data)
      },
      onError: (error) => {
        toast.error("Error al obtener los periodos de nómina");
      }
  });

  const onCancel = () => {
    inputFileRef.current.value = "";
    setCsvData([]);
    setValidations([]);
    setIsVisible(false);
  }

  const onReset = () => {
    inputFileRef.current.value = "";
    setCsvData([]);
    setValidations([]);
  }

  const onDownloadExampleFile = () => {
    const exampleFile = "ID de Empleado, Lunes, Martes, Miércoles, Jueves, Viernes, Sábado, Domingo\n";
    downloadFile({ data: exampleFile, filename: "template_planning.csv", type: ".csv" });
  }

  const onLoadFile = (data) => {
    if(data.length === 1) {
      callValidate({
        rows: data[0].rows,
        headers: data[0].headers
      })
      setCsvData(data[0])
    };
  }

  const onSubmit = () => {
    callSave({
      ...planningValues,
      rows: csvData.rows,
      headers: csvData.headers,
    })
  }

  useEffect(() => {
    callGetPayrollPeriods();
  }, []);

  return (
    <View
      planningValues={planningValues}
      setPlanningValues={setPlanningValues}
      payrollPeriods={payrollPeriods}
      error={error}
      onDownloadExampleFile={onDownloadExampleFile}
      inputFileRef={inputFileRef}
      onLoadFile={onLoadFile}
      data={csvData}
      validations={validations}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onReset={onReset}
    />
  );

}

export default BulkLoad;