import React, { useState, useEffect, useRef } from "react";
import { usePut, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import { toast } from "react-toastify";
import View from "components/crh/offices/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [office, setOffice] = useState({});
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/offices", {
        onCompleted: () => {
            if (!toast.isActive("office-updated"))
                toast.success("Sucursal actualizada", { toastId: "office-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "offices", "sucursales", "edit")
        }
    })

    const [callOffice, reqOffice] = useGetCall(`/offices/${id}`, {
        onCompleted: (response) => {
            if (formikRef.current)
                formikRef.current.setValues(response.data);
            setOffice(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "offices", "sucursales", "read")
        }
    })

    useEffect(() => {

        callOffice();
        formikRef.current.setValues({});

    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            office={office}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
