import React from 'react';

function DetailGroupView({ group, groupEmployees, groupGroups }) {
    return <div className='container mt-3'>
        {
            group && group.type === "EMPLOYEE" 
                ? <div className=''>
                    {
                        groupEmployees && groupEmployees.length > 0 
                            ? <div class={"table-responsive datatable-custom position-relative"}>
                                <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
                                    <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                                        <thead>
                                        <tr role="row">
                                            <th>ID Empleado</th>
                                            <th>Nombre</th>
                                            <th>Sucursal</th>
                                            <th>Departamento</th>
                                            <th>Puesto</th>
                                            <th>Jefe directo</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            groupEmployees.map((groupEmployee) => (
                                            <tr key={groupEmployee.employee.id} role="row">
                                                <td>
                                                {groupEmployee.employee.key}
                                                </td>
                                                <td>
                                                {groupEmployee.employee.firstname} {groupEmployee.employee.dad_lastname} {groupEmployee.employee.mom_lastname}
                                                </td>
                                                <td>
                                                {groupEmployee.employee.office ? groupEmployee.employee.office.description : ""}
                                                </td>
                                                <td>
                                                {groupEmployee.employee.department ? groupEmployee.employee.department.description : ""}
                                                </td>
                                                <td>
                                                {groupEmployee.employee.job ? groupEmployee.employee.job.description : ""}
                                                </td>
                                                <td>
                                                {groupEmployee.employee.boss ? groupEmployee.employee.boss.description : ""}
                                                </td>
                                            </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : <div className='row'>
                                <div className='col-12 col-md-6 d-flex justify-content-center'>
                                    <h5 className='text-center'>No hay empleados registrados</h5>
                                </div>
                            </div>
                    }
                </div>
                : <div>
                    {
                        groupGroups && groupGroups.length > 0 
                            ?   <div class={"table-responsive datatable-custom position-relative"}>
                            <div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
                              <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                                <thead>
                                  <tr role="row">
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th>Correo</th>
                                    <th>Tipo de grupo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    groupGroups.map((groupGroup) => (
                                      <tr key={groupGroup.child.id} role="row">
                                        <td>
                                          {groupGroup.child.key}
                                        </td>
                                        <td>
                                          {groupGroup.child.description}
                                        </td>
                                        <td>
                                          {groupGroup.child.group_email}
                                        </td>
                                        <td>
                                          {groupGroup.child.type === 'EMPLOYEE' ? 'Grupo de empleados' : 'Grupo de grupos'}
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        : <div className='row'>
                            <div className='col-12 col-md-6 d-flex justify-content-center'>
                                <h5 className='text-center'>No hay grupos registrados</h5>
                            </div>
                        </div>
                    }
                </div>
        }
    </div>;
}

export default DetailGroupView;