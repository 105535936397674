import React, { useState, useEffect, useRef } from "react";
import { usePut, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";
import { toast } from "react-toastify";
import View from "components/crh/jobs/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [job, setJob] = useState({});
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refreshValue !== refresh) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/jobs", {
        onCompleted: () => {
            if (!toast.isActive("job-updated"))
                toast.success("Puesto actualizado", { toastId: "job-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "jobs", "puestos", "edit")
        }
    })

    const [callJob, reqJob] = useGetCall(`/jobs/${id}`, {
        onCompleted: (response) => {
            if (formikRef.current)
                formikRef.current.setValues(response.data);
            setJob(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "jobs", "puestos", "read")
        }
    })

    useEffect(() => {
        callJob();
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            job={job}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
