import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { getBankHolidays } from "api/bankHolidays";
import View from "components/crh/bankHolidays/List.view";

function List({ addEditModal, refresh, search }) {
	
	const [bankHolidays, setBankHolidays] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if(refresh !== refreshValue) setRefreshValue(refresh);

	const [callBankHolidays, reqBankHolidays] = useGetCall("/bank_holidays", {
		onCompleted: (response) => {
			setBankHolidays(response.data)
		},
		onError: (error) => {
			console.error(error);
			showResponseMessage(error.status, "bank_holidays", "dias festivos", "read");
		}
	})

	const [callDelete, reqDelete] = useDelete("/bank_holidays", {
		onCompleted: () => {		
            if(!toast.isActive("bank-holiday-deleted"))
				toast.success("Día festivo eliminada", { toastId: "bank-holiday-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "bank_holidays", "dias festivos", "delete");
		}
	});

	useEffect(() => {
		callBankHolidays({ search });
	}, [refreshValue, refreshDelete, search]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "BANK_HOLIDAYS", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar la subarea?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-bank_holidays-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar dias festivos", { toastId: "toast-delete-bank_holidays-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	return (
		<View 
            bankHolidays={bankHolidays}
            onDelete={onDelete} 
            onEdit={onEdit}
        />
	);
}

export default List;