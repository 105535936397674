import React, { useState, useEffect } from "react"
import { useMap } from "react-leaflet"
import {useMapEvents} from "react-leaflet";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
import "resources/styles/Home.scss";

const AutoCircleSize = ({ setCircleSize }) => {

    const map = useMap()
    const mapEvents = useMapEvents({
        zoomend: () => {
            const zoom = map.getZoom()
            setCircleSize(Math.pow(2, 22 - zoom))
        }
    });

    return null

}

const AutoZoom = ({ attendanceLogs }) => {
		
    const map = useMap()

    useEffect(() => { 
        
        const bounds = attendanceLogs.map((attendanceLog) => [(attendanceLog?.lat??attendanceLog?.device?.lat)??19.432650, (attendanceLog?.lng??attendanceLog?.device?.lng)??-99.133268])
        
        if(bounds.length === 0) return
        map.fitBounds(bounds)

    }, [attendanceLogs])

    return null
    
}

const RecenterAutomatically = ({ attendanceLogs }) => {
    
    const map = useMap()
    
    useEffect(() => { 

        const center = attendanceLogs.reduce((c, attendanceLog) => {
            c[0] += (attendanceLog?.lat??attendanceLog?.device?.lat)??19.432650
            c[1] += (attendanceLog?.lng??attendanceLog?.device?.lng)??-99.133268
            return c
        }, [0, 0])

        center[0] /= attendanceLogs.length
        center[1] /= attendanceLogs.length

        if(isNaN(center[0]) || isNaN(center[1])) return
        map.setView(center)

    }, [attendanceLogs])

    return null;

}

function AttendanceMapView({
    circleSize,
    setCircleSize,
    isMapAttendanceLogVisible,
    setIsMapAttendanceLogVisible,
    employees,
    attendanceLogs = [],
    loading,
}) {
    return (
        <>

            <div style={{ position: "absolute", top: "5px", right: "10px", zIndex: "999" }}>
                { loading && <div className="spinner-border spinner-border-sm text-primary" role="status"></div> }
            </div>

            <div style={{ position: "relative", height: "100%", width: "100%", }}>
                <MapContainer center={[19.475281, -99.179209]} zoom={17} scrollWheelZoom={true} style={{ height: "100%", width: "100%", zIndex: 0 }}>
                    <TileLayer
                        // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key="
                        // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key="
                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        attendanceLogs.map((attendanceLog, index) => (
                            <Circle 
                                center={[(attendanceLog?.lat??attendanceLog?.device?.lat)??19.432650, (attendanceLog?.lng??attendanceLog?.device?.lng)??-99.133268]}
                                radius={circleSize}
                                color={attendanceLog.type === "LECTOR" ? "rgba(54, 162, 235, 0.3)" : ""} 
                                stroke={false}
                                eventHandlers={{
                                    click: () => {
                                        setIsMapAttendanceLogVisible(true)
                                    }
                                }}
                            />
                        ))
                    }
                    <AutoZoom attendanceLogs={attendanceLogs} />
                    <RecenterAutomatically attendanceLogs={attendanceLogs} />
                    <AutoCircleSize setCircleSize={setCircleSize} />
                </MapContainer>
                {
                    isMapAttendanceLogVisible && (
                        <div className="card card-body h-100 w-50 p-0" style={{ position: "absolute", top: "0", right: "0", overflowY: "auto" }}>
                    
                            <div className="d-flex justify-content-end px-2 w-100 my-0 py-0 border-bottom">
                                <i className="bi bi-x text-primary" style={{ fontSize: "1.2rem", cursor: "pointer" }} onClick={() => setIsMapAttendanceLogVisible(false)}></i>
                            </div>

                            {
                                attendanceLogs.map((attendanceLog, index) => (
                                    <div className="w-100 h6 px-2 py-2 border-bottom d-flex align-items-center mb-0" style={{ fontSize: "0.7rem" }}>
                                        <div className="d-flex me-2">
                                            {
                                                function(){
                                                    if(employees[attendanceLog.employee_id]?.photo && employees[attendanceLog.employee_id]?.photo.url) 
                                                        return <img 
                                                            className="rounded-circle me-2" 
                                                            src={employees[attendanceLog.employee_id]?.photo.url}
                                                            alt="avatar" 
                                                            style={{ width: "25px", height: "25px" }}
                                                        />
                                                    return <i className="bi bi-person-circle text-secondary ms-1" style={{ fontSize: "25px" }}></i>
                                                }()
                                            }
                                        </div>
                                        <div className="d-flex flex-fill">
                                            <div className="d-flex flex-column">
                                                <div className="pe-3">
                                                    {employees[attendanceLog.employee_id]?.firstname} {employees[attendanceLog.employee_id]?.dad_lastname} {employees[attendanceLog.employee_id]?.mom_firstname}
                                                </div>
                                                <div className="text-muted">
                                                    {employees[attendanceLog.employee_id]?.job?.description || "Sin puesto"}
                                                </div>
                                                <div className="text-muted">
                                                    {employees[attendanceLog.employee_id]?.department?.description || "Sin departamento"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    )
                }
                
            </div>
        </>
    )
}

export default AttendanceMapView