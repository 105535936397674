import React, { useState, useEffect, useLayoutEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/AttendanceChart.view"
import { getJSON } from "utils/sessionStorage";

function AttendanceChart({ employees, filters, refreshValue, globalLoading, setGlobalLoading }) {

	const [loading, setLoading] = useState(false)
	const [attendanceData, setAttendanceData] = useState(getJSON("attendanceData", {}))
	const [attendanceChart, setAttendanceChart] = useState({ datasets: [], })

	const [isPeriodAttendanceVisible, setIsPeriodAttendanceVisible] = useState(false)

	const [callDashboard, reqDashboard] = useGetCall("/statistics/attendance_chart", {
		onCompleted: (response) => {
			setAttendanceData(response?.data??{})
			setLoading(false)
			setGlobalLoading({ ...globalLoading, attendanceChart: true })
		},
		onError: (error) => {
			setLoading(false);
			setGlobalLoading({ ...globalLoading, attendanceChart: true })
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	const loadAttendanceChart = () => {

		if(attendanceData) {

			const colors = [
				// 'rgba(0, 0, 0, 0.4)',
				'rgba(140, 140, 140, 0.4)',
				'rgba(54, 162, 235, 0.4)',

			]

			if(attendanceData.length > 0)
				setAttendanceChart({
					labels: attendanceData[0].map((_, idx) => idx + 1),
					datasets: attendanceData.map((data, idx) => ({
						fill: true,
						tension: 0.4,
						data: data,
						backgroundColor: colors[idx],
						borderWidth: 0,
					}))
				})
			
		}
			
	}

	useLayoutEffect(() => {
		loadAttendanceChart()
	}, [])

	useEffect(() => {
		loadAttendanceChart()
		try{ sessionStorage.setItem("attendanceData", JSON.stringify(attendanceData)) }
		catch(e) { console.log(e) }
	}, [attendanceData])

    useEffect(() => {
			if(!globalLoading.attendanceRanking) return;
			if(globalLoading.attendanceChart) return;
			setLoading(true)
			callDashboard({ ...filters })
    }, [refreshValue, globalLoading])

	return (
		<View
			employees={employees}
			attendanceData={attendanceData}
			attendanceChart={attendanceChart}
			isPeriodAttendanceVisible={isPeriodAttendanceVisible}
			setIsPeriodAttendanceVisible={setIsPeriodAttendanceVisible}
			loading={loading}
		/>
	)

}

export default AttendanceChart