import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useGetCall, usePut } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/plannings/Form.view";
import { weekDays } from 'utils/constants';

function FormSet({
	theme,
  id,
	refresh,
  setIsVisible,
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [planning, setPlanning] = useState([]);
  const [rawPlanning, setRawPlanning] = useState({});
  const [planningValues, setPlanningValues] = useState({});
  const [filters, setFilters] = useState({});

  const [error, setError] = useState(null);
  
  const [payrollPeriods, setPayrollPeriods] = useState([]);
  const [offices, setOffices] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [departments, setDepartments] = useState([]);
  
  const [employees, setEmployees] = useState([]);
  const [schedules, setSchedules] = useState([]);
  
  const [visibleModal, setVisibleModal] = useState(null);
  const [refreshValue, setRefreshValue] = useState(null);

  if(refreshValue !== refresh) {
      setRefreshValue(refresh);
  }

	const [callPlanning, reqRegimens] = useGetCall(`/plannings/${id}`, {
		onCompleted: (response) => {
			setLoading(false);
			setRawPlanning(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "regimes", "regimens", "read");
		}
	});

  const [callSet, reqSet] = usePut(`/plannings/${id}`, {
    onCompleted: () => {
        
        if (!toast.isActive("planning-updated"))
            toast.success("Planeación editada correctamente", { toastId: "planning-updated" });

        setIsVisible(false);

    },
    onError: (err) => {

        // check 400, 404, 409, 500

        if(!toast.isActive("planning-error")) 
            toast.error("Error al crear la planeación", { toastId: "planning-error" });
    }
  })

  const [callGetPayrollPeriods, reqPayrollPeriods] = useGetCall("/payroll_periods", {
      onCompleted: (response) => {
          setPayrollPeriods(response.data)
      },
      onError: (error) => {
        toast.error("Error al obtener los periodos de nómina");
      }
  });

  const [callGetEmployees, reqEmployees] = useGetCall("/employees", {
      onCompleted: (response) => {
          setEmployees(response.data)
      },
      onError: (error) => {
        toast.error("Error al obtener las sucursales");
      }
  });

  const [callGetSchedules, reqSchedules] = useGetCall("/schedules", {
      onCompleted: (response) => {
          setSchedules(response.data)
      },
      onError: (error) => {
        toast.error("Error al obtener las sucursales");
      }
  });

  const [callGetOffices, reqOffices] = useGetCall("/offices", {
      onCompleted: (response) => {
          setOffices(response.data)
      },
      onError: (error) => {
        toast.error("Error al obtener las sucursales");
      }
  });

  const [callGetJobs, reqJobs] = useGetCall("/jobs", {
      onCompleted: (response) => {
          setJobs(response.data)
      },
      onError: (error) => {
        toast.error("Error al obtener las sucursales");
      }
  });

  const [callGetDepartments, reqDepartments] = useGetCall("/departments", {
      onCompleted: (response) => {
          setDepartments(response.data)
      },
      onError: (error) => {
        toast.error("Error al obtener las sucursales");
      }
  });

  useEffect(() => {
    callGetPayrollPeriods();
    callGetEmployees({ has_planning: true });
    callGetSchedules();
    callGetOffices();
    callGetJobs();
    callGetDepartments();
}, [refreshValue]);

	useEffect(() => {
		setLoading(true);
		callPlanning({ id });
	}, [refreshValue, id]);

  useEffect(() => {

    let metadata = rawPlanning?.metadata??"{}";
    metadata = JSON.parse(metadata);

    let sample = null;
    (rawPlanning.personal_planning??[]).forEach(planning => {
      sample = planning.day_planning.find(day => day.schedule !== null);
      if(sample) return;
    });

    setPlanningValues({
      payrollPeriod: rawPlanning.payroll_period,
      tolerance: ((sample?.shift?.tolerance ?? metadata.tolerance) ?? 0),
      extra: (sample?.shift?.extra ?? metadata.extra) ?? false,
      type_extra: (sample?.shift?.type_extra ?? metadata.type_extra),
      round: (sample?.shift?.round ?? metadata.round) ?? false,
      type_round: (sample?.shift?.type_round ?? metadata.type_round),
      distribution_round: (sample?.shift?.distribution_round ?? metadata.distribution_round),
    });

    let newPlannings = [];
    employees.forEach(employee => {

      let planning = rawPlanning.personal_planning.find(planning => planning.employee.id === employee.id);
      if(!planning) {

        planning = {
          employee: employee,
          params: weekDays.reduce((acc, day) => {
            acc[day] = {
              schedule: null,
              incidenceRequest: null
            };
            return acc;
          }, {})
        }

        newPlannings.push(planning);

      }

      

    });

    setPlanning(
      [
        ...(rawPlanning?.personal_planning?.map(planning => ({
          employee: planning.employee,
          params: planning.day_planning.reduce((acc, day) => {
            acc[day.week_day] = {
              schedule: day.schedule,
              incidenceRequest: day.incidence_request
            };
            return acc;
          }, {})
        }))??[]),
        ...(newPlannings??[])
      ]
    );

  }, [rawPlanning, employees]);

  const onSubmit = () => {

    if(!planningValues.payrollPeriod)
        return toast.error("El periodo de nómina es requerido");

    if(!planningValues.tolerance)
        return toast.error("La tolerancia es requerida");

    callSet({
        planning,
        ...planningValues
    })
    
  }

  const onCancel = () => {
      setIsVisible(false);
  }

  const onChangePlanning = (employeePlanning, weekDay, schedule) => {

      let params = employeePlanning.params;
      params[weekDay].schedule = schedule;

      let previousPlanning = planning;
      let index = previousPlanning.findIndex(planning => planning.employee.id === employeePlanning.employee.id);
      previousPlanning[index].params = params;

      setPlanning(previousPlanning);
      setVisibleModal(null);

  }

  const onAddIncidence = (employeePlanning, weekDay, incidence) => {
          
      let params = employeePlanning.params;
      params[weekDay].incidenceRequest = incidence;

      let previousPlanning = planning;
      let index = previousPlanning.findIndex(planning => planning.employee.id === employeePlanning.employee.id);
      previousPlanning[index].params = params;

      setPlanning(previousPlanning);
      setVisibleModal(null);

  }

	if (loading) return <Loading />

	return (
		<View 
      planning={planning}
      payrollPeriods={payrollPeriods}
      jobs={jobs}
      departments={departments}
      offices={offices}
      schedules={schedules}
      filters={filters}
      setFilters={setFilters}
      planningValues={planningValues}
      setPlanningValues={setPlanningValues}
      setPlanning={setPlanning}
      visibleModal={visibleModal}
      setVisibleModal={setVisibleModal}
      onChangePlanning={onChangePlanning}
      onAddIncidence={onAddIncidence}
      onSubmit={onSubmit}
      onCancel={onCancel}
      error={error}
      setIsVisible={setIsVisible}
      setError={setError}
		/>
	);

}

export default FormSet;