import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
const format = 'HH:mm';

function FormView({
    formikRef = () => { },
    edit = false,
    schedule = {},
    onSubmit,
    error,
    setError,
    onCancel,
    hasBreak,
    nightly,
    totalHours,
    nextDay,
    hours,
    setHasBreak,
    setNightly,
    setTotalHours,
    setNextDay,
    handleOnChange
}) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={schedule}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>
                        {/* Key */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="key" class="form-control input__field" placeholder=" " required value={values.key || ''} />
                                    <span class="input__label">Clave <span className='text-danger fw-bold'>*</span></span>
                                </label>
                            </div>
                        </div>

                        {/* Description */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                                    <span class="input__label">Descripción <span className='text-danger fw-bold'>*</span></span>
                                </label>
                            </div>
                        </div>

                        {/* Hours and break */}
                        <div class="mb-3">
                            <div class="row form-group">
                                <div class="col-md-6 d-flex">
                                    <h5 style={{ width: "50%" }}><label for="hours">Horas</label></h5>
                                    <TimePicker style={{ width: "50%" }} format={format} onChange={time => handleOnChange(time, "hours")} value={hours?.hours ? dayjs(hours.hours, format) : ''}/>
                                </div>
                                <div class="col-md-6 d-flex">
                                    <h5 style={{ width: "50%" }}><label for="tolerance">Descanso</label></h5>
                                    <div className="form-check form-switch pt-1" id="break-switch" style={{ width: "50%" }}>
                                        <input class="form-check-input" type="checkbox" id="switchBreak"
                                            onChange={() => { setHasBreak(!hasBreak) }} checked={hasBreak} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Nightly turn and total hours */}
                        <div class="mb-3">
                            <div class="row form-group">
                                <div class="col-md-6 d-flex">
                                    <h5 style={{ width: "50%" }}><label for="switchNightly">Turno nocturno</label></h5>
                                    <div className="form-check form-switch pt-1" style={{ width: "50%" }}>
                                        <input class="form-check-input" type="checkbox" id="switchNightly"
                                            onChange={() => { setNightly(!nightly) }} checked={nightly} />
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex">
                                    <h5 style={{ width: "50%" }}><label for="switchTotalHours">Cálculo total hrs.</label></h5>
                                    <div className="form-check form-switch pt-1" style={{ width: "50%" }}>
                                        <input class="form-check-input" type="checkbox" id="switchTotalHours"
                                            onChange={() => { setTotalHours(!totalHours) }} checked={totalHours} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Entry time */}
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <h5 class="bg-crh" style={{ padding: "5px 0" }}>Horario de entrada</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5><label for="entry_from">Desde <span className='text-danger fw-bold'>*</span></label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "entry_from")} value={hours?.entry_from ? dayjs(hours.entry_from, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="entry_start">Entrada <span className='text-danger fw-bold'>*</span></label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "entry_start")} value={hours?.entry_start ? dayjs(hours.entry_start, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="entry_until">Hasta <span className='text-danger fw-bold'>*</span></label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "entry_until")} value={hours?.entry_until ? dayjs(hours.entry_until, format) : ''}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Break time */}
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <h5 class="bg-crh" style={{ padding: "5px 0" }}>Salida a comer</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5><label for="break_from">Desde</label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "break_from")} value={hours?.break_from ? dayjs(hours.break_from, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="break_start">Entrada</label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "break_start")} value={hours?.break_start ? dayjs(hours.break_start, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="break_until">Hasta</label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "break_until")} value={hours?.break_until ? dayjs(hours.break_until, format) : ''}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Return break time */}
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <h5 class="bg-crh" style={{ padding: "5px 0" }}>Regreso de comer</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5><label for="return_from">Desde</label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "return_from")} value={hours?.return_from ? dayjs(hours.return_from, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="return_start">Entrada</label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "return_start")} value={hours?.return_start ? dayjs(hours.return_start, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="return_until">Hasta</label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "return_until")} value={hours?.return_until ? dayjs(hours.return_until, format) : ''}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Exit time */}
                        <div class="mb-3">
                            <div class="form-group text-center">
                                <h5 class="bg-crh" style={{ padding: "5px 0" }}>Horario de salida</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5><label for="exit_from">Desde <span className='text-danger fw-bold'>*</span></label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "exit_from")} value={hours?.exit_from ? dayjs(hours.exit_from, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="exit_start">Entrada <span className='text-danger fw-bold'>*</span></label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "exit_start")} value={hours?.exit_start ? dayjs(hours.exit_start, format) : ''}/>
                                    </div>

                                    <div class="col-md-4">
                                        <h5><label for="exit_until">Hasta <span className='text-danger fw-bold'>*</span></label></h5>
                                        <TimePicker style={{ width: "100%" }} format={format} onChange={time => handleOnChange(time, "exit_until")} value={hours?.exit_until ? dayjs(hours.exit_until, format) : ''}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Next day */}
                        <div class="mb-3">
                            <div class="form-group d-flex">
                                <h5 style={{ marginRight: "10px" }}><label for="switchNextDay">Calcular siguiente día</label></h5>
                                <div className="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="switchNextDay"
                                        onChange={() => { setNextDay(!nextDay) }} checked={nextDay} />
                                </div>
                            </div>
                        </div>


                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar horario" : "Guardar horario"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;