import React from 'react';
import { toast } from 'react-toastify';
import { usePost } from 'utils/api';
import { IS_TEST } from "settings";
import View from "components/auth/Login.view";


function Login() {

	const [callLogin, reqLogin] = usePost("/auth/login", {
		onCompleted: ({ data }) => {

			if(!toast.isActive("login-success"))
				toast.success("Login correcto.", { toastId: "login-success" });
			
			sessionStorage.setItem("id", data.id);
			sessionStorage.setItem("token", data.token);
			sessionStorage.setItem("type", data.type);
			
			if (data.type == "SUPERADMIN") {
				window.location.href = (IS_TEST ? "/test" : "") + "/admin";
			} else {
				window.location.href = (IS_TEST ? "/test" : "") + "/crh";
			}	
		},
		onError: (error) => {
			console.error(error);
			if(!toast.isActive("login-error"))
				toast.error("Usuario o contraseña incorrectos.", { toastId: "login-error" });
		},
		includeAuth: false
	});

	const onSubmit = (values) => {

		const user = values.user;
		const password = values.password;

		if (!user || !password) {
			toast.error("Todos los campos son obligatorios.");
			return;
		}

		callLogin({
			username: user,
			password: password,
			type: "SUPERADMIN"
		})

	}

	return <View onSubmit={onSubmit} superAdmin={true} />
}

export default Login;