import React, {useState} from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/incidences/Form.view";

function FormSave({ setIsVisible }) {

    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [color, setColor] = useState("#000000");
    const [status, setStatus] = useState(false);
    const [requestRh, setRequestRh] = useState(false);
    const [requestGeo, setRequestGeo] = useState(false);


    const [callSave, reqSave] = usePost("/incidences", {
        onCompleted: () => {
            if (!toast.isActive("incidence-created"))
                toast.success("Incidencia creada correctamente", {toastId : "incidence-created"});
            
            setIsVisible(false);
            setColor("#000000");
            setStatus(false);
            setRequestRh(false);
            setRequestGeo(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "incidences", "incidencias", "create");
        }
    })

    const onSubmit = (values, {resetForm}) => {

        for(let key in values)
            if(values[key] === "")
                values[key] = null
    
        const {key, description, type, preference} = values
        if(!key || !description || !type || type === "" || !preference || preference === "") {
            toast.error("Rellena los campos obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave({
            ...values,
            status: status,
            request_rh: requestRh,
            request_geo: requestGeo,
            color: color
        });
        
    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    const onChangeColor = (background) => setColor(background.hex);
    const onClickShow = () => setDisplayColorPicker(!displayColorPicker);
    const onCloseColorPicker = () => setDisplayColorPicker(false);

    return (
        <View 
            displayColorPicker={displayColorPicker}
            color={color}
            error={error}
            status={status}
            requestRh={requestRh}
            requestGeo={requestGeo}
            setStatus={setStatus}
            setRequestRh={setRequestRh}
            setRequestGeo={setRequestGeo}
            setIsVisible={setIsVisible} 
            setError={setError}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onChangeColor={onChangeColor}
            onClickShow={onClickShow}
            onCloseColorPicker={onCloseColorPicker}
        />
    )
}

export default FormSave;