import React, { useState, useEffect, useRef } from "react";
import { usePut } from "utils/api";
import { getPayroll } from "api/payrolls";
import { toast } from "react-toastify";
import View from "components/crh/payrolls/Form.view";

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [payroll, setPayroll] = useState({});
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callUpdate, reqUpdate] = usePut("/payrolls", {
        onCompleted: () => {
            if (!toast.isActive("payroll-updated"))
                toast.success("Nómina actualizada", { toastId: "payroll-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("payroll-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "payroll-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-edit-payroll-unauthorized")) {
                    toast.error("Error, no tienes los permisos para editar nóminas", { toastId: "toast-create-edit-unauthorized" })
                }
            }  else if (err.status === 409) {
                if (!toast.isActive("payroll-error-409")) {
                    toast.error("Ya existe una nómina con esa clave, por favor ingrese otra", { toastId: "payroll-error-409" });
                }
            } else {
                if (!toast.isActive("payroll-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "payroll-error-500" });
                }
            }
        }
    })

    useEffect(() => {
        getPayroll(id).then((response) => {
            if (response.data) {
                if (formikRef.current)
                    formikRef.current.setValues(response.data);
                setPayroll(response.data);
            }
        });
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            error={error}
            edit={true}
            payroll={payroll}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )


}

export default FormSet;
