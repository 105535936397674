import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

const useGeolocation = () => {
  
    const [coords, setCoords] = useState(null)
  
    useEffect(() => {
  
      const id = navigator.geolocation.watchPosition((position) => {
        setCoords(position.coords)
      }, (error) => {
        console.log(error)
        if(!toast.isActive("geolocation-error"))
          toast.error("No ha sido posible obtener la ubicación, inténtelo de nuevo", { toastId: "geolocation-error" });
      }, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      })
  
      return () => {
        navigator.geolocation.clearWatch(id)
      }
  
    }, [])
  
    return coords

}

export {
    useGeolocation
}