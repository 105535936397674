import React, { useState, useEffect } from "react"
import { useGetCall } from "utils/api";
import { toast } from "react-toastify"
import View from "components/home/dashboard/AttendanceRanking.view"
import { getJSON } from "utils/sessionStorage";

function AttendanceRanking({ employees, filters, refreshValue, globalLoading, setGlobalLoading }) {

	const [loading, setLoading] = useState(false)
	const [ranking, setRanking] = useState(getJSON("ranking", []))

	const [callDashboard, reqDashboard] = useGetCall("/statistics/attendance_ranking", {
		onCompleted: (response) => {
			setRanking(response?.data?.ranking??[])
			setLoading(false)
			setGlobalLoading({ ...globalLoading, attendanceRanking: true })
		},
		onError: (error) => {
			setLoading(false);
			setGlobalLoading({ ...globalLoading, attendanceRanking: true })
			if (error.status === 403) {
				if (!toast.isActive("toast-read-xxxxx-unauthorized")) {
					toast.error("Error, no tienes los permisos para consultar xxxxxxxx", { toastId: "toast-read-xxxxx-unauthorized" })
				}
			} else {
				if (!toast.isActive("toast-xxxxx-error")) {
					toast.error("Error al obtener ", { toastId: "toast-xxxxx-error" })
				}
			}
		}
	})

	useEffect(() => {
		try{ 
			sessionStorage.setItem("ranking", JSON.stringify(ranking))
			sessionStorage.setItem("rankingEmployees", JSON.stringify(employees))
		}
		catch(e) { console.log(e) }
	}, [ranking])

    useEffect(() => {
			if(!globalLoading.incidencesChart) return;
			if(globalLoading.attendanceRanking) return;
			setLoading(true)
			callDashboard({ ...filters })
    }, [refreshValue, globalLoading])

	return (
		<View
			loading={loading}
			employees={employees}
			ranking={ranking}
		/>
	)

}

export default AttendanceRanking