import React from 'react';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';

const getNameDay = (day) => {
	if (day === "MONDAY") return "Lunes";
	if (day === "TUESDAY") return "Martes";
	if (day === "WEDNESDAY") return "Miércoles";
	if (day === "THURSDAY") return "Jueves";
	if (day === "FRIDAY") return "Viernes";
	if (day === "SATURDAY") return "Sábado";
	if (day === "SUNDAY") return "Domingo";
	return "";
}

const days = [
	"MONDAY",
	"TUESDAY",
	"WEDNESDAY",
	"THURSDAY",
	"FRIDAY",
	"SATURDAY",
	"SUNDAY"
]

const getActiveIndexWeek = (date, maxWeeks) => {

    const countSundays = (startDate, endDate) => {
        let sundays = 0;
        const actualDate = startDate.clone()
        while (actualDate <= endDate) {
            if (actualDate.format("dddd").toUpperCase() === "DOMINGO") {
                sundays += 1;
            }
            actualDate.add(1, 'days')
        }
        return sundays;
    }

	const startDate = moment.utc(date);
	const endDate = moment();

	const weeks = countSundays(startDate, endDate);
	return weeks % maxWeeks;

}

function FormView({
	maxWeek,
	weeksNum,
	weeks,
	shift,
	formikRef = () => { },
	onSubmit,
	onCancel,
	schedules,
	onReset,
	onAddWeek,
	onRemoveWeek,
	handleOnChange
}) {

	return (
		<div className='container mt-3'>

			{
				shift.rotating && (
					<div className="d-flex justify-content-between">
						<h5>Fecha inicial: {moment.utc(shift.start_date).format("DD/MM/YYYY")}</h5>
						<button type="button" class="btn btn-warning" style={{ marginLeft: "20px" }} onClick={onAddWeek}>
							<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
							Agregar semana
						</button>
					</div>
				)
			}

			<Formik
				innerRef={formikRef}
				initialValues={{}}
				onSubmit={onSubmit}>
				{({ values, setFieldValue }) =>
					<Form>

						{
							shift.rotating ? (
								<div class="accordion my-3" id="accordionWeeks" >
									{
										Array.from(Array(weeksNum).keys()).map((idx) => (
											<div class="accordion-item" key={idx}>

												<div class="accordion-header" id={`heading_${idx + 1}`}>
													<div class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${idx + 1}`} aria-expanded="true" aria-controls={`collapse_${idx + 1}`}>
														Semana {idx + 1}
														{
															function () {
																if (maxWeek !== 0 && getActiveIndexWeek(shift.start_date, maxWeek) === idx)
																	return (<span class="badge bg-crh" style={{ marginLeft: "20px" }}>Activo</span>)
																return <></>;
															}()
														}
													</div>

												</div>

												<div id={`collapse_${idx + 1}`} class="accordion-collapse collapse" aria-labelledby={`heading_${idx + 1}`} data-bs-parent="#accordionWeeks">
													<div class="accordion-body">

														<div className="d-flex justify-content-end">
															<button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={() => onRemoveWeek(idx + 1)}>
																<i class="bi bi-trash-fill" style={{ marginRight: "10px" }}></i>
																Eliminar semana
															</button>
														</div>

														<table class="table">
															<thead>
																<tr>
																	<th scope="col">Día</th>
																	<th scope="col">Horario</th>
																	<th scope="col">Hora entrada</th>
																	<th scope="col">Hora Salida</th>
																</tr>
															</thead>
															<tbody>
																{
																	days.map((day, index) => (
																		<tr key={index}>
																			<td>{getNameDay(day)}</td>
																			<td>
																				<Field as="select" class="form-control"
																					required onChange={(event) => handleOnChange(day, idx + 1, event)}
																					value={function () {
																						const res = weeks.find((item) => item.day === day && item.num === idx + 1);
																						if (res && res.schedule != null) {
																							return res.schedule.id;
																						}
																						return '';
																					}()} >

																					<option value="">Selecciona un horario</option>
																					{
																						schedules.map((schedule, index) => (
																							<option key={index} value={schedule.id}>{schedule.description}</option>
																						))
																					}
																				</Field>
																			</td>
																			<td>
																				{function () {
																					const res = weeks.find((item) => item.day === day && item.num === idx + 1);
																					if (res && res.schedule != null) {
																						if (res.schedule.entry_start != null) {
																							return res.schedule.entry_start;
																						}
																					}
																					return "--:--";
																				}()}
																			</td>
																			<td>
																				{function () {
																					const res = weeks.find((item) => item.day === day && item.num === idx + 1);
																					if (res && res.schedule != null) {
																						if (res.schedule.exit_start != null) {
																							return res.schedule.exit_start;
																						}
																					}
																					return "--:--";
																				}()}
																			</td>
																		</tr>
																	))
																}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										))
									}
								</div>
							) : (
								<table class="table">
									<thead>
										<tr>
											<th scope="col">Día</th>
											<th scope="col">Horario</th>
											<th scope="col">Hora entrada</th>
											<th scope="col">Hora Salida</th>
										</tr>
									</thead>
									<tbody>
										{
											days.map((day, index) => (
												<tr key={index}>
													<td>{getNameDay(day)}</td>
													<td>
														<Field as="select" class="form-control"
															required onChange={(event) => handleOnChange(day, 1, event)}
															value={function () {
																const res = weeks.find((item) => item.day === day && item.num === 1);
																if (res && res.schedule != null) {
																	return res.schedule.id;
																}
																return '';
															}()} >
															<option value="">Selecciona un horario</option>
															{
																schedules.map((schedule, index) => (
																	<option key={index} value={schedule.id}>{schedule.description}</option>
																))
															}
														</Field>
													</td>
													<td>
														{function () {
															const res = weeks.find((item) => item.day === day && item.num === 1);
															if (res && res.schedule != null) {
																if (res.schedule.entry_start != null) {
																	return res.schedule.entry_start;
																}
															}
															return "--:--";
														}()}
													</td>
													<td>
														{function () {
															const res = weeks.find((item) => item.day === day && item.num === 1);
															if (res && res.schedule != null) {
																if (res.schedule.exit_start != null) {
																	return res.schedule.exit_start;
																}
															}
															return "--:--";
														}()}
													</td>
												</tr>
											))
										}
									</tbody>
								</table>
							)
						}

						<div class="pt-3">
							<button type="submit" class="btn btn-primary">
								<i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
								Guardar
							</button>

							<button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel}>
								<i class="bi bi-x" style={{ marginRight: "10px" }}></i>
								Cancelar
							</button>

							<button type="button" class="btn btn-warning" style={{ marginLeft: "20px" }} onClick={onReset}>
								<i class="bi bi-arrow-repeat" style={{ marginRight: "10px" }}></i>
								Reestablecer
							</button>
						</div>

					</Form>
				}</Formik>

		</div >
	)
}

export default FormView;