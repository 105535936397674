import React, { useState } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import View from "components/crh/payrolls/Form.view";

function FormSave({ setIsVisible }) {

    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });

    const [callSave, reqSave] = usePost("/payrolls", {
        onCompleted: () => {
            if (!toast.isActive("payroll-created"))
                toast.success("Nómina creada correctamente", { toastId: "payroll-created" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            if (err.status === 400) {
                if (!toast.isActive("payroll-error-400")) {
                    toast.error("Campos obligatorios requeridos", { toastId: "payroll-error-400" });
                }
            } else if (err.status === 403) {
                if (!toast.isActive("toast-create-payroll-unauthorized")) {
                    toast.error("Error, no tienes los permisos para crear nóminas", { toastId: "toast-create-payroll-unauthorized" })
                }
            } else if (err.status === 409) {
                if (!toast.isActive("payroll-error-409")) {
                    toast.error("Ya existe una nómina con esa clave, por favor ingrese otra", { toastId: "payroll-error-409" });
                }
            } else {
                if (!toast.isActive("payroll-error-500")) {
                    toast.error("Error interno del servidor", { toastId: "payroll-error-500" });
                }
            }

        }
    })

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }


    return (
        <View
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            setIsVisible={setIsVisible}
            setError={setError}
        />
    )
}

export default FormSave;