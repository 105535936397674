import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/jobs/List.view";

function Jobs({
	search,
	refresh,
	addEditJobModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [jobs, setJobs] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refreshValue !== refresh) {
		setRefreshValue(refresh);
	}

	const [callJobs, reqJobs] = useGetCall("/jobs", {
		onCompleted: (response) => {
			setLoading(false);
			setJobs(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "jobs", "puestos", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/jobs", {
		onCompleted: () => {
			if (!toast.isActive("job-deleted"))
				toast.success("Puesto eliminado", { toastId: "job-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "jobs", "puestos", "delete")
		}
	});

	useEffect(() => {
		setLoading(true);
		callJobs({ search });
	}, [search, refreshValue, refreshDelete]);

	const onDelete = (id) => {
		fetchPost("/jobs/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el puesto?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-job-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar puestos", { toastId: "toast-delete-job-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditJobModal(id);
	}

	if (loading) return <Loading />

	return (
		<View jobs={jobs} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default Jobs;