import React, { useState, useEffect } from 'react';
import { validateAuth } from "api/auth";
import { LoadingContext } from 'helpers/LoadingContext';
import { fetchPost } from 'utils/api';
import { IS_TEST } from "settings";
import View from "components/layout/LayoutCrh.view";

function LayoutCrh() {

	let savedLayout = JSON.parse(sessionStorage.getItem("layout"));
	const [theme, setTheme] = useState(savedLayout ? savedLayout.theme : "light");
	const [button, setButton] = useState(savedLayout ? savedLayout.button : false);
	const [btnTab, setBtnTab] = useState(savedLayout ? savedLayout.btnTab : true);
	const [loading, setLoading] = useState(false);

	const [tabs, setTabs] = useState(savedLayout ? savedLayout.tabs : []);
	const [activeTabId, setActiveTabId] = useState(savedLayout ? savedLayout.activeTabId : 0);

	const onAddTab = (id, name, props = {}) => {

		if(loading) return;

		const result = tabs.findIndex(tab => tab.content === name && tab.componentId === id);
		const newTabs = [...tabs];

		if(result !== -1) return setActiveTabId(newTabs[result].id);

		const tabId = new Date().getTime().toString().substring(7) + Math.random().toString().substring(2, 5);

		newTabs.push({
			id: tabId,
			componentId: id,
			props: props,
			content: name
		});

		setTabs(newTabs);
		setActiveTabId(tabId);

	}

	savedLayout = { tabs, theme, button, btnTab, activeTabId };
	sessionStorage.setItem("layout", JSON.stringify(savedLayout));

	useEffect(() => {
		validateAuth({
			id: sessionStorage.getItem("id"),
			type: sessionStorage.getItem("type"),
		}, "ADMIN").then(response => {
			if (!response)
				window.location.href = (IS_TEST ? "/test" : "") + "/login";
		})
	}, [tabs, setTabs]);

	useEffect(() => {
		fetchPost("/permissions/check", { table: "HOME", permission: "access" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === false) {
					const indexTabHome = tabs.findIndex((item) => item.componentId === 0);
					if(indexTabHome !== -1) {
						const newTabs = [...tabs];
						newTabs.splice(indexTabHome, 1);
						setTabs(newTabs);
					}
				}
				else if(tabs.length === 0) {
					setTabs([
						{
							id: 1,
							componentId: 0,
							props: {},
							active: true,
							content: "Home"
						}
					]);
				}
			}
		})
	}, []);

	return (
		<LoadingContext.Provider value={{ loading, setLoading }}>
			<View
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
				button={button}
				setButton={setButton}
				theme={theme}
				setTheme={setTheme}
				btnTab={btnTab}
				setBtnTab={setBtnTab}
				tabs={tabs.map(tab => ({
					...tab,
					props: {
						...tab.props,
						onAddTab
					}
				}))}
				setTabs={setTabs}
				onAddTab={onAddTab}
			/>
		</LoadingContext.Provider>
	)

}

export default LayoutCrh;