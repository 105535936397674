import React, { useState, useEffect, useRef } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { getJobs } from "api/jobs";
import { getDepartments } from "api/departments";
import { getEmployees } from "api/employees";
import { getGroups } from "api/groups"
import { showResponseMessage } from "utils/message";
import View from "components/crh/users/Form.view";

function FormSave({ setIsVisible, refresh, company }) {

    const [jobs, setJob] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [groups, setGroups] = useState([]);
    const [employee, setEmployee] = useState(null);
    const [keyEmployee, setKeyEmployee] = useState("");
    const [refreshValue, setRefreshValue] = useState(null);

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callSave, reqSave] = usePost("/users", {
        onCompleted: () => {
            if (!toast.isActive("user-created")) toast.success("Usuario creado correctamente", { toastId: "user-created" });
            setIsVisible(false);
            reset();
            setEmployee(null);
            setKeyEmployee("");
        },
        onError: (error) => {
            console.error(error)
            switch (error.status) {
                case 401:
                    if (!toast.isActive("user-employee-taken"))
                        toast.error("El empleado ya tiene un usuario asociado", { toastId: "user-employee-taken" });
                    break;

                case 403:
                    if (!toast.isActive("toast-create-users-unauthorized"))
                        toast.error("Error, no tiene permisos para crear usuarios", { toastId: "toast-create-users-unauthorized" });
                    break;

                case 409:
                    if (!toast.isActive("user-username-taken"))
                        toast.error("Ya existe un usuario con ese nombre de usuario, ingrese otro", { toastId: "user-username-taken" });
                    break;

                case 410:
                    if (!toast.isActive("user-email-taken"))
                        toast.error("Ya existe un usuario con ese correo, ingrese otro", { toastId: "user-email-taken" });

                default:
                    if (!toast.isActive("user-error"))
                        toast.error("Error interno del servidor.", { toastId: "user-error" });
                    break;
            }
        }
    })

    useEffect(() => {

        getGroups().then((data) => {
            if (data.errors.length > 0) {
                if (!toast.isActive("toast-areas-eror"))
                    toast.error("Error al obtener los grupos de trabajo", { toastId: "toast-groups-eror" });
            }
            else {
                setGroups(data.data);
            }
        });

        getJobs({ company }).then(response => {
            if (response.data)
                setJob(response.data);
        });

        getDepartments({ company }).then(response => {
            if (response.data)
                setDepartments(response.data);
        });

    }, [refreshValue])

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { firstname, lastname, username, password, type } = values
        if (!firstname || !lastname || !username || !password || !type) {
            toast.error("Rellene los campos que son obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave({ ...values, company });

    }

    const onSearchEmployee = () => {

        getEmployees({ key: keyEmployee }, { company_id: company }).then(response => {
            if (response.data) {
                const data = response.data;
                if (data.length === 1) {
                    toast.success("Empleado encontrado");
                    data[0].lastname = `${data[0].dad_lastname} ${data[0].mom_lastname}`
                    setEmployee(data[0])

                    const { email, firstname, lastname, job, department } = data[0];
                    if (formikRef.current) {
                        formikRef.current.setFieldValue("email", email)
                        formikRef.current.setFieldValue("firstname", firstname)
                        formikRef.current.setFieldValue("lastname", lastname)
                        formikRef.current.setFieldValue("job", job)
                        formikRef.current.setFieldValue("department", department)
                    }
                } else {
                    if (formikRef.current) {
                        formikRef.current.setFieldValue("email", null)
                        formikRef.current.setFieldValue("firstname", null)
                        formikRef.current.setFieldValue("lastname", null)
                        formikRef.current.setFieldValue("job", null)
                        formikRef.current.setFieldValue("department", null)
                    }
                    setEmployee(null)
                    toast.error("No se encontró el empleado");
                }
            } else {
                toast.error("Error del servidor");
            }

        });

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        setEmployee(null);
        setKeyEmployee("");
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}

            jobs={jobs}
            departments={departments}
            groups={groups}
            employee={employee}

            setIsVisible={setIsVisible}
            setError={setError}
            setKeyEmployee={setKeyEmployee}
            onSearchEmployee={onSearchEmployee}
        />
    )
}

export default FormSave;