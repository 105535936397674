import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/areas/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [areas, setAreas] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callAreas, reqAreas] = useGetCall("/areas", {
		onCompleted: (response) => {
			setLoading(false);
			setAreas(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "areas", "areas", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/areas", {
		onCompleted: () => {
			if (!toast.isActive("area-deleted"))
				toast.success("Area eliminada", { toastId: "area-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "areas", "areas", "create")
		}
	});

	useEffect(() => {
		setLoading(true);
		callAreas({ search });
	}, [refreshDelete, refreshValue, search]);

	const onDelete = (id) => {
		fetchPost("/areas/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el area?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-area-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar areas", { toastId: "toast-delete-area-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View areas={areas} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;