import React, { useState, useRef, useEffect, useContext } from "react";
import View from "components/crh/reports/incidenceRequest/IncidenceRequestReport.view";
import { getUser } from "api/users";
import Loading from "helpers/Loading";
import moment from "moment";
import { useGetCall, fetchPost } from "utils/api";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import { downloadCSV, downloadExcel } from "utils/files";

function IncidenceRequestReport() {

	const { loading, setLoading } = useContext(LoadingContext);
	const reportRef = useRef(null);
	const id = sessionStorage.getItem("id");
	const [searchFilters, setSearchFilters] = useState({});

	const [filterModal, setFilterModal] = useState(false);
	const [reports, setReports] = useState([]);
	const [user, setUser] = useState({});

	const [permissionExcel, setPermissionExcel] = useState(false);
	const [permissionPdf, setPermissionPdf] = useState(false);
	const [permissionRead, setPermissionRead] = useState(false);

	const [callReports, reqReports] = useGetCall("/reports/incidence_request", {
		onCompleted: (response) => {
			setLoading(false);
			setReports(response.data);
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "incidence_request_reports", "reportes de solicitudes de incidencias", "read");
		}
	})

	useEffect(() => {
		callReports(searchFilters);
	}, [searchFilters])

	const setFilters = (values) => {
		setSearchFilters(values);
	}

	const showFilterModal = () => setFilterModal(true);
	const hideFilterModal = () => setFilterModal(false);

	const generateCells = (format = "csv") => {

		let csv = [];

		csv.push([
			`Folio`,
			`Solicitante`,
			`ID empleado`,
			`Nombre empleado`,
			`Incidencia`,
			`Fecha inicio`,
			`Fecha fin`,
			`Hora inicio`,
			`Hora fin`,
			`Auth. Jef`,
			`Usuario Jefe`,
			`Auth. R`,
			`Usuario RH`
		]);

		reports.forEach((incidenceRequest) => {

			let employee = incidenceRequest.employee;
			if(employee.leave && moment.utc(employee.leave).startOf('day') < moment.utc(searchFilters.start_date).startOf('day')) return;

			csv.push([
				`${incidenceRequest?.folio}`,
				`${incidenceRequest?.applicant?.firstname} ${incidenceRequest?.applicant?.lastname}`,
				`${incidenceRequest?.employee?.key}`,
				`${incidenceRequest?.employee?.firstname} ${incidenceRequest?.employee?.dad_lastname} ${incidenceRequest?.employee?.mom_lastname}`,
				`${incidenceRequest?.incidence?.description ?? ""}`,
				`${incidenceRequest?.start_date ? moment.utc(incidenceRequest?.start_date).format("DD/MM/YYYY") : "-"}`,
				`${function () {
					const incidence = incidenceRequest?.incidence?.type;
					if (incidence === "INSERT_MARKINGS") {
						return "-";
					} else {
						return moment.utc(incidenceRequest?.end_date).format("DD/MM/YYYY");
					}
				}()}`,
				`${incidenceRequest?.start_hour ?? "-"}`,
				`${incidenceRequest?.end_hour ?? "-"}`,
				`${incidenceRequest?.date_auth_boss ? moment.utc(incidenceRequest?.date_auth_boss).format("DD/MM/YYYY HH:mm:ss") : "-"}`,
				`${incidenceRequest?.authorizer_boss?.username ?? "-"}`,
				`${incidenceRequest?.date_auth_rh ? moment.utc(incidenceRequest?.date_auth_rh).format("DD/MM/YYYY HH:mm:ss") : "-"}`,
				`${incidenceRequest?.authorizer_rh?.username ?? "-"}`
			]);

			csv.push([
				`Observaciones`,
				`${incidenceRequest?.observations ?? "-"}`,
				``,
				``,
				``,
				``,
				``,
				``,
				``,
				``,
				``,
				`Archivo`,
				`${incidenceRequest?.file?.url ?? "-"}`
			])

			csv.push([]);
			csv.push([]);

		});

		csv.push(["Total:", `${reports.length} registros`, "", "", "", "", "", "", "", "", "", "", ""]);

		return csv;

	}

	const onDownloadCSV = () => {
		downloadCSV({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells(),
			report: "solicitudes de incidencias",
		});
	}

	const onDownloadXlsx = async () => {
		downloadExcel({
			user,
			start_date: searchFilters.start_date,
			end_date: searchFilters.end_date,
			cells: generateCells("xlsx"),
			report: "solicitudes de incidencias",
		})
	}

	const onChangeSearch = (event) => {
		if (event.target.value === '') {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			setFilters({ ...searchFilters, search: event.target.value });
		}
	}

	useEffect(() => {
		getUser(id).then((response) => {
			setUser(response.data)
		});
	}, [id]);

	useEffect(() => {
		fetchPost("/permissions/check", { table: "INCIDENCE_REQUESTS_REPORT", permission: "excel" }).then((response) => {
			if (response.data) setPermissionExcel(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "INCIDENCE_REQUESTS_REPORT", permission: "pdf" }).then((response) => {
			if (response.data) setPermissionPdf(response.data.isAllowed);
		});
		fetchPost("/permissions/check", { table: "INCIDENCE_REQUESTS_REPORT", permission: "read" }).then((response) => {
			if (response.data) setPermissionRead(response.data.isAllowed);
		});
	}, []);

	if (loading) return <Loading />;

	return (
		<View
			user={user}
			permissionExcel={permissionExcel}
			permissionPdf={permissionPdf}
			permissionRead={permissionRead}
			setLoading={setLoading}
			onDownloadXlsx={onDownloadXlsx}
			onDownloadCSV={onDownloadCSV}
			reportRef={reportRef}
			reports={reports}
			filterModal={filterModal}
			filters={searchFilters}
			onChangeSearch={onChangeSearch}
			handleKeyUp={handleKeyUp}
			setFilters={setFilters}
			showFilterModal={showFilterModal}
			hideFilterModal={hideFilterModal}
		/>
	)

}

export default IncidenceRequestReport;