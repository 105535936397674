import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import { getRegimes } from "api/regimes";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/regimes/List.view";

function List({
	search,
	refresh,
	addEditJobModal
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [regimes, setRegimes] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callRegimens, reqRegimens] = useGetCall("/regimes", {
		onCompleted: (response) => {
			setLoading(false);
			setRegimes(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "regimes", "regimens", "read");
		}
	})

	const [callDelete, reqDelete] = useDelete("/regimes", {
		onCompleted: () => {
			if (!toast.isActive("regime-deleted"))
				toast.success("Régimen eliminado", { toastId: "regime-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			showResponseMessage(error.status, "regimes", "régimen", "delete");
		}
	});

	useEffect(() => {
		setLoading(true);
		callRegimens({ search });
	}, [refreshValue, refreshDelete, search]);

	const onDelete = (id) => {
		fetchPost("/permissions/check", { table: "REGIME", permission: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar el régimen?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-regime-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar el régimen", { toastId: "toast-delete-regime-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditJobModal(id);
	}

	if (loading) return <Loading />

	return (
		<View regimes={regimes} onDelete={onDelete} onEdit={onEdit} />
	);
}

export default List;