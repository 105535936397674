import React from 'react';
import { Formik, Form, Field } from 'formik';
import { MapContainer, TileLayer, Circle, Marker } from "react-leaflet";

function FormView({
  formikRef = () => { },
  edit = false,
  offices = [],
  device = {},
  onSubmit,
  error,
  setError,
  onCancel,
  callTestConnection,
  callGetCoordinates,
  setCoordinates,
  coordinates,
  locations,
  connected,
  RecenterAutomatically
}) {
  return (
    <div className='container mt-3'>
      <Formik
        innerRef={formikRef}
        initialValues={device}
        onSubmit={onSubmit}>
        {({ values, setFieldValues, setFieldValue, resetForm }) =>
          <Form>

            {/* Serial Number */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field type="text" name="serial_number" class="form-control input__field" placeholder=" " required value={values.serial_number || ''} />
                  <span class="input__label">Número de serie</span>
                </label>
              </div>
            </div>

            {/* Description */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field type="text" name="description" class="form-control input__field" placeholder=" " value={values.description || ''} />
                  <span class="input__label">Descripción</span>
                </label>
              </div>
            </div>

            {/* Model */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field type="text" name="model" class="form-control input__field" placeholder=" " value={values.model || ''} />
                  <span class="input__label">Modelo</span>
                </label>
              </div>
            </div>

            {/* Timezone */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field as="select" placeholder=" " name="time_zone" class="input__field form-control" required value={values.time_zone || ''}>
                    <option value="">Seleccione una opción</option>
                    <option value="-1200">GMT-12:00</option>
                    <option value="-1100">GMT-11:00</option>
                    <option value="-1000">GMT-10:00</option>
                    <option value="-0900">GMT-09:00</option>
                    <option value="-0800">GMT-08:00</option>
                    <option value="-0700">GMT-07:00</option>
                    <option value="-0600">GMT-06:00</option>
                    <option value="-0500">GMT-05:00</option>
                    <option value="-0400">GMT-04:00</option>
                    <option value="-0300">GMT-03:00</option>
                    <option value="-0200">GMT-02:00</option>
                    <option value="-0100">GMT-01:00</option>
                    <option value="+0000">GMT</option>
                    <option value="+0100">GMT+01:00</option>
                    <option value="+0200">GMT+02:00</option>
                    <option value="+0300">GMT+03:00</option>
                    <option value="+0400">GMT+04:00</option>
                    <option value="+0500">GMT+05:00</option>
                    <option value="+0600">GMT+06:00</option>
                    <option value="+0700">GMT+07:00</option>
                    <option value="+0800">GMT+08:00</option>
                    <option value="+0900">GMT+09:00</option>
                    <option value="+1000">GMT+10:00</option>
                    <option value="+1100">GMT+11:00</option>
                    <option value="+1200">GMT+12:00</option>
                    <option value="+1300">GMT+13:00</option>
                  </Field>
                  <span class="input__label">Zona horaria</span>
                </label>
              </div>
            </div>

            {/* Office */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field as="select" placeholder=" " name="office_id" class="input__field form-control" value={values.office_id || ''}>
                    <option value="">Seleccione una opción</option>
                    {
                      offices.map((office) =>
                        <option value={office.id}>{office.description}</option>
                      )
                    }
                  </Field>
                  <span class="input__label">Sucursal</span>
                </label>
              </div>
            </div>

            {/* Location */}
            <div class="mb-3">
              <div class="form-group">
                <label class="input">
                  <Field type="text" name="location" class="form-control input__field" placeholder=" " value={values.location || ''} />
                  <span class="input__label">Ubicación</span>
                </label>
              </div>
            </div>

            {/* Check coordinates */}
            <div class="mb-3">
              <div class="form-group">
                <div class="d-grid gap-2">
                  <button type="button" class="btn btn-info text-white" onClick={() => callGetCoordinates(values.location)}>
                    Buscar dirección
                  </button>
                </div>
              </div>
            </div>

            <div className='w-100 mb-3' style={{ height: "40vh", position: "relative" }}>
              <div className='' style={{ position: "absolute", zIndex: "999", top: "10px", right: "10px", width: "300px" }}>
                {
                  locations.length > 1 
                    && <select className='form-control' onChange={(e) => {
                      setCoordinates(locations[e.target.value].geometry.location)
                      setFieldValue("location", locations[e.target.value].formatted_address)
                    }}>
                      {
                        locations.map((location, index) =>
                          <option value={index}>{location.formatted_address}</option>
                        )
                      }
                    </select>
                }
              </div>
              <div className='bg-white p-1 px-2 rounded' style={{ position: "absolute", zIndex: "999", bottom: "10px", left: "10px" }}>
                {(coordinates.lat??19.432650).toFixed(6)}, {(coordinates.lng??-99.133268).toFixed(6)}
              </div>
              <MapContainer 
                center={[coordinates.lat??19.432650, coordinates.lng??-99.133268]} 
                zoom={17} 
                scrollWheelZoom={true} 
                style={{ height: "100%", width: "100%", zIndex: 0, position: "absolute", top: "0", left: "0" }}
              >
                <TileLayer
                  // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key="
                  // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key="
                  url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[coordinates.lat??19.432650, coordinates.lng??-99.133268]} />
                <RecenterAutomatically lat={coordinates.lat??19.432650} lng={coordinates.lng??-99.133268} />
              </MapContainer>
            </div>

            {/* Test connection */}
            <div class="mb-3">
              <div class="form-group">
                <div class="d-grid gap-2">
                  <button type="button" class={
                    function () {
                      let classToReturn = "btn"
                      if (connected === true) classToReturn += " btn-success"
                      else if (connected === false) classToReturn += " btn-danger"
                      else classToReturn += " btn-warning"
                      return classToReturn
                    }()
                  } onClick={() => callTestConnection(values.serial_number)}>
                    Validar conexión
                  </button>
                </div>
              </div>
            </div>

            {error ? (
              <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                <p className='text-justify' style={{ width: "90%" }}>
                  {error}
                </p>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}

            <div class="pt-3">
              <button type="submit" class="btn btn-primary">
                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                {edit ? "Editar dispositivo" : "Guardar dispositivo"}
              </button>

              <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                Cancelar
              </button>
            </div>
          </Form >
        }
      </Formik >
    </div >
  )
}

export default FormView;