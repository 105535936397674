import React from "react";
import Modal from "helpers/Modal";
import Filters from "./Filters";
import moment from "moment";

function AttendanceNotRegisteredView({
	permissionRead,
	filters,
	setFilters,
	hideModal,
	showModal,
	filterModal,
	attendanceLogs,
	officeDetails,
	statusDetails,
}) {
	return (
		<>
			<div className="content container-fluid p-5">

				<div className="page-header mb-3">
					<div className="row align-items-end">
						<div className="col-sm mb-2 mb-sm-0">
							<h1 className="page-header-title">Reporte de asistencia</h1>
						</div>
						<div className="col-sm-auto">
							{
								permissionRead && (
									<button type="button" className="btn btn-primary" onClick={showModal}>
										<i className="bi bi-plus-circle" style={{ marginRight: "7px" }}></i>
										Filtros de búsqueda
									</button>
								)
							}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-8">
						{
							attendanceLogs.map((report) =>
								report.attendanceLogs.length > 0 ? (
									<>
										<div class="table-responsive datatable-custom position-relative">
											<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>

												<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
													<thead>
														<tr role="row">
															<th style={{ width: "5%" }}>ID</th>
															<th style={{ width: "35%" }}>Nombre</th>
															<th style={{ width: "20%" }}></th>
															<th style={{ width: "20%" }}></th>
															<th style={{ width: "20%" }}></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																{report.employee.pin}
															</td>
															<td>
																{report.employee.firstname}
															</td>
														</tr>
													</tbody>
												</table>

												<div className="mx-3">
													<table class="table table-sm table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
														<thead>
															<tr role="row" className="bg-dark">
																<th>Fecha</th>
																<th>Descripción</th>
																<th>Ubicación</th>
																<th>Hora</th>
															</tr>
														</thead>
														<tbody>
															{
																report.attendanceLogs.map((attendanceLog) =>
																	<tr>
																		<td>{attendanceLog.log.date}</td>
																		<td>{attendanceLog.device.description}</td>
																		<td>{attendanceLog.device.location}</td>
																		<td>{attendanceLog.log.time}</td>
																	</tr>

																)
															}
														</tbody>
													</table>
												</div>

											</div>
										</div>

										<hr />
									</>
								) : null
							)
						}
					</div>
					<div className="col-md-4">
						<div class="card mb-3 mb-md-5">
							<div class="card-header">
								<h5 class="card-header-title">Detalles de la búsqueda</h5>
							</div>
							<div class="card-body">

								<div class="row">
									<div class="col-md-6 d-flex align-items-center">
										<span className="mx-2 fw-bold">Fecha de búsqueda: </span>
									</div>
									<div class="col-md-3 d-flex align-items-center justify-content-center">
										<span className="mx-2">{filters.start_date && filters.start_date !== "" ? moment(filters.start_date).format("DD/MM/YYYY") : " - "}</span>
									</div>
									<div class="col-md-3 d-flex align-items-center justify-content-center">
										<span className="mx-2">{filters.end_date && filters.end_date !== "" ? moment(filters.end_date).format("DD/MM/YYYY") : " - "}</span>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-md-6 d-flex align-items-center">
										<span className="mx-2 fw-bold">Rango de horas: </span>
									</div>
									<div className="col-md-3 d-flex align-items-center justify-content-center">
										<span className="mx-2">{filters.start_hour && filters.start_hour !== "" ? filters.start_hour : " - "}</span>
									</div>
									<div className="col-md-3 d-flex align-items-center justify-content-center">
										<span className="mx-2">{filters.end_hour && filters.end_hour !== "" ? filters.end_hour : " - "}</span>
									</div>
								</div>

								{
									Object.keys(officeDetails).length > 0
										? <>

											<hr />

											<div>
												<span className="mx-2 fw-bold">Sucursales: </span>
											</div>
											{
												Object.keys(officeDetails).map((office_id) =>
													<div className="row" key={office_id}>
														<div className="col-md-6 d-flex align-items-center">
															<span className="mx-2">{officeDetails[office_id].name}</span>
														</div>
														<div className="col-md-6 d-flex align-items-center justify-content-center">
															<span className="mx-2">{officeDetails[office_id].count}</span>
														</div>
													</div>
												)
											}
											<div className="row">
												<div className="col-md-6 d-flex align-items-center">
													<span className="mx-2 fw-bold">Total: </span>
												</div>
												<div className="col-md-6 d-flex align-items-center justify-content-center">
													<span className="mx-2">
														{
															Object.keys(officeDetails).reduce((total, office_id) => total + officeDetails[office_id].count, 0)
														}
													</span>
												</div>
											</div>

										</>
										: null
								}

							</div>
						</div>
					</div>
				</div>



			</div>

			<Modal
				title={"Filtros de búsqueda"}
				isVisible={filterModal}
				setIsVisible={hideModal}
			>
				<Filters
					hideModal={hideModal}
					filters={filters}
					setFilters={setFilters}
				/>
			</Modal>

		</>
	);
}

export default AttendanceNotRegisteredView;