import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import Loading from "helpers/Loading";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/adminReport/AdminReport.view";
import { fetchPost, useGetCall } from "utils/api";
import { showResponseMessage } from "utils/message";

function AdminReport() {

  const { loading, setLoading } = useContext(LoadingContext);

  const [office, setOffice] = useState(null);
  const [search, setSearch] = useState("");
  const [admins, setAdmins] = useState([]);
  const [offices, setOffices] = useState([]);

  const [callEmployees, reqEmployees] = useGetCall('/employees', {
    onCompleted: (response) => {
      setLoading(false);
      setAdmins(response.data);
    }, onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "employees", "empleados", "read")
    }
  })

  const [callOffices, reqOffices] = useGetCall('/offices', {
    onCompleted: (response) => {
      setLoading(false);
      setOffices(response.data);
    }, onError: (error) => {
      setLoading(false);
      showResponseMessage(error.status, "offices", "sucursales", "read")
    }
  })


  useEffect(() => {

    fetchPost("/permissions/check", { table: "ADMIN_REPORT", permission: "read" }).then((response) => {
      if (response.data) {
        const data = response.data;
        if (data.isAllowed === true) {

          callEmployees({ search, office_id: office, admin_report: true });
          callOffices();

        } else {
          if (!toast.isActive("toast-create-admin_repoort-unauthorized")) {
            toast.error("Error, no tienes los permisos para consultar el reporte de administrador", { toastId: "toast-create-admin_repoort-unauthorized" })
          }
        }
      }
    })

  }, [search, office]);

  const onChangeSearch = (event) => {
    if (event.target.value === '') {
      setSearch(event.target.value);
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  }

  if (loading) return <Loading />

  return (
    <View
      search={search}
      onChangeSearch={onChangeSearch}
      handleKeyUp={handleKeyUp}
      offices={offices}
      admins={admins}
      setOffice={setOffice}
    />
  );

}

export default AdminReport;