import React from "react";

function ListView({ users = [], onDelete, onEdit }) {
	return (
		<div class="table-responsive datatable-custom position-relative" >
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{ maxHeight: "60vh" }}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
							<th>Usuario</th>
							<th>Nombre (s)</th>
							<th>Apellidos</th>
							<th>Correo</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{
							users.map((user) => (
								<tr key={user.id} role="row">
									<td>{user.username}</td>
									<td>{user.firstname}</td>
									<td>{user.lastname}</td>
									<td>{user.email}</td>
									<td>
										<button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(user.id)}>
											<i class="bi bi-pencil" style={{ marginRight: "7px" }}></i>
											Editar
										</button>
										<button className="btn btn-danger" style={{ marginRight: "10px" }} onClick={() => onDelete(user.id)}>
											<i class="bi bi-trash" style={{ marginRight: "7px" }}></i>
											Eliminar
										</button>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default ListView;