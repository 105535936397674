import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { API_URL } from "settings";

const fetchPost = async (endpoint = "", body = {}) => {

	const url = `${API_URL}${endpoint}`;
	const params = {
		method: "POST",
		headers: {
			"content-Type": "application/json",
			"x-auth-token": sessionStorage.getItem("token"),
		},
		body: JSON.stringify(body)
	};

	return fetch(url, params)
		.then((response) => {
			return response.json();
		})
		.catch((err) => {
			console.log(err);
			return null;
		});

}

const useGet = (endpoint, queryArgs = {}, options = {}) =>
	usePoll(endpoint, queryArgs, options);

const useGetCall = (endpoint, options = {}) =>
	useMutate("GET", endpoint, options);

const usePost = (endpoint, options = {}) =>
	useMutate("POST", endpoint, options);

const usePut = (endpoint, options = {}) =>
	useMutate("PUT", endpoint, options);

const useDelete = (endpoint, options = {}) =>
	useMutate("DELETE", endpoint, options);


const query = (params) => {
	let query = "";
	for (let param in params)
		if (params[param] != null && params[param] !== "")
			query += `${param}=${encodeURIComponent(params[param])}&`;
	if (query !== "") query = query.slice(0, -1);
	return query;
};

const options = (method = "GET", body = {}) => {
	let res = {
		method: method,
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json",
			"x-auth-token": `${sessionStorage.getItem("token")}`
		}
	};
	if (method !== "GET")
		res["body"] = JSON.stringify(body);
	return res;
};

const usePoll = (endpoint, params, pollOptions = {}) => {
	const [status, setStatus] = useState({ data: null, isLoading: true });
	useEffect(() => () => setStatus({ data: null, isLoading: true }), []);
	const optionsData = options("GET")
	if (pollOptions.includeAuth === false)
		delete optionsData.headers["x-auth-token"]
	const fetch = useFetch(`${API_URL}${endpoint}/?${query(params)}`, {
		...optionsData,
		formatter: (response) => {
			if (!response.ok) throw response;
			return response.text();
		}
	});

	if ((fetch.data != null || fetch.error != null) && status.isLoading) {
		let data = null;
		if (fetch.error == null) {
			data = {};
			try {
				data = JSON.parse(fetch.data);
			} catch (e) { }
			if (pollOptions.onCompleted != null)
				pollOptions.onCompleted(data);
		} else {
			if (pollOptions.onError != null)
				pollOptions.onError(fetch.error);
		}
		setStatus({ data: data, isLoading: false });
	}

	return { ...fetch, loading: status.isLoading, data: status.data };
};

const useMutate = (method, endpoint, mutOptions = {}) => {

	const [params, setParams] = useState("");
	const [call, setCall] = useState({ body: null, called: false });
	useEffect(() => () => setCall({ body: null, called: false }), []);

	const calling = (body = {}) => {
		const formattedBody = body || {};
		if (method === "GET") {
			setParams(query(formattedBody));
			setCall({ body: {} });
		} else {
			setCall({ body: body });
		}
	}

	const optionsData = options(method, call.body)

	if (mutOptions.includeAuth === false)
		delete optionsData.headers["x-auth-token"]

	console.log(API_URL, endpoint, call.body, call.called, optionsData)

	let formattedEndpoint = "";
	if (method === "GET") formattedEndpoint = `${API_URL}${endpoint}?${params}`;
	else formattedEndpoint = `${API_URL}${endpoint}${call.body && call.body.id ? "/" + call.body.id : ""}/`;

	const fetch = useFetch(formattedEndpoint, {
		...optionsData,
		formatter: (response) => {
			if (!response.ok) throw response;
			return response.text();
		},
		depends: [call.body != null]
	});

	if (call.body != null && !call.called && fetch.isLoading)
		setCall({ ...call, called: true });

	if (call.body != null && call.called && !fetch.isLoading) {

		if (mutOptions.onCompleted != null && fetch.error == null) {
			let json = {};
			try {
				json = JSON.parse(fetch.data);
			} catch (e) { }
			mutOptions.onCompleted(json);
		}

		if (mutOptions.onError != null && fetch.error != null)
			mutOptions.onError(fetch.error);

		setCall({ body: null, called: false });

	}

	return [calling, { ...fetch, loading: fetch.isLoading, called: call.called }];

};

const getUrlencoded = (payload) => {

	let formBody = [];
	for (let property in payload) {
		if (payload[property] != null && payload[property] !== undefined && payload[property] !== '') {
			let encodedKey = encodeURIComponent(property);
			let encodedValue = encodeURIComponent(payload[property]);
			formBody.push(encodedKey + "=" + encodedValue);
		}
	}

	return formBody.join("&");
}

export { useGet, useGetCall, usePost, usePut, useDelete, getUrlencoded, fetchPost }
