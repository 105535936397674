import moment from "moment";

const getLocaleUTC = () => {

    // const dateTime = new Date().toLocaleString();
    // const date = dateTime.split(",")[0];
    // const day = date.split("/")[0];
    // const month = parseInt(date.split("/")[1]) - 1;
    // const year = date.split("/")[2];

    // return new Date(Date.UTC(year, month, day)).toISOString();

    return moment().utc().toISOString();

}

const formatISODate = (date, slash = false, reverse = false) => {

    if(!date) return "";

    date = date.split("T")[0];
    const day = date.split("-")[2];
    const month = date.split("-")[1];
    const year = date.split("-")[0];

    if(reverse) return `${year}${slash ? "/" : "-"}${month}${slash ? "/" : "-"}${day}`;
    else return `${day}${slash ? "/" : "-"}${month}${slash ? "/" : "-"}${year}`;
}

const formatHolidaysHours = (hours) => {
	if(!hours) hours = 0;
	return formatHolidaysDays((hours / 8).toFixed(2));
}

const formatHolidaysDays = (days) => {
	if(!days) days = 0;
	days = parseFloat(days);
	if(days % 1 === 0) return days.toFixed(2).replace(".00", "");
	else return days.toFixed(2);
}

export {
    getLocaleUTC,
    formatISODate,
    formatHolidaysHours,
    formatHolidaysDays
}