import React from "react"

function EmployeeDevicesListView({ 
    employees = [], 
    passwordEdit,
    setPasswordEdit,
    onEdit, 
    onEditPassword,
    onChangePerm,
    theme 
}) {
	return (
		<div class={"table-responsive datatable-custom position-relative"}>
			<div class="dataTables_wrapper no-footer pt-2 pl-3 pr-3" style={{maxHeight: "60vh"}}>
				<table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
					<thead>
						<tr role="row">
                            <th>Sucursal</th>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Contraseña</th>
                            <th>Tipo de usuario</th>
                            <th>Huellas</th>
                            <th>Rostros</th>
                            <th>FaceAI</th>
                            <th>Tarjetas</th>
                            <th>Acciones</th>
						</tr>
					</thead>
					<tbody>
                        {
                            employees.map((employee) => 
                                <tr key={employee.id} role="row">
                                    <td>
                                        {employee.office ? employee.office.key : ""}
                                    </td>
                                    <td>
                                        {employee.key}
                                    </td>
                                    <td>
                                        {employee.firstname} {employee.dad_lastname} {employee.mom_lastname}
                                    </td>
                                    <td>
                                        {
                                            passwordEdit.id === employee.id
                                                ? <div className="d-flex">
                                                    <input 
                                                        type="password" 
                                                        className="form-control form-control-sm" 
                                                        style={{ width: "100px" }}
                                                        value={passwordEdit.password}
                                                        onChange={(e) => {
                                                            if(isNaN(e.target.value) || e.target.value.includes("e")) return;
                                                            if(e.target.value.includes(" ") || e.target.value.includes(".")) return;
                                                            if(e.target.value.length > 6) return;
                                                            setPasswordEdit({ ...passwordEdit, password: e.target.value });
                                                        }}
                                                    />
                                                    <button 
                                                        className="btn btn-primary ms-2"
                                                        onClick={onEditPassword}
                                                    >
                                                        Guardar
                                                    </button>
                                                </div>
                                                : <div>
                                                    ******
                                                    <i 
                                                        class="bi bi-pencil text-primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => setPasswordEdit({ id: employee.id, password: "" })}
                                                    ></i>
                                                </div>
                                        }
                                    </td>
                                    <td>
                                        <select
                                            className="form-select form-select-sm"
                                            style={{ width: "140px" }}
                                            value={employee.perm_level}
                                            onChange={(e) => {
                                                onChangePerm(employee, e.target.value);
                                            }}
                                        >
                                            <option value="ADMIN">Administrador</option>
                                            <option value="USER">Usuario</option>
                                        </select>
                                    </td>
                                    <td>
                                        {employee.fingerprint_count}
                                    </td>
                                    <td>
                                        {employee.face_count}
                                    </td>
                                    <td>
                                        {employee.faceai_count}
                                    </td>
                                    <td>
                                        {employee.card_count}
                                    </td>
                                    <td>
                                        <button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => onEdit(employee.id)}>
                                            <i class="bi bi-pencil" style={{marginRight: "7px"}}></i>
                                            Editar
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default EmployeeDevicesListView